<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      :finish-button-text="lang('t_send')"
      :next-button-text="lang('t_next')"
      :back-button-text="lang('t_previous')"
      class="steps-transparent mb-3"
      @on-complete="send_excel_sms"
    >
      <!-- account detail tab -->
      <tab-content :title="lang('t_operator')" icon="bi bi-broadcast">
        <b-row class="mx-0 mb-1">
          <b-form-radio
            @input="filter_values.selected_operator = ''"
            value="sms"
            v-model="filter_values.type"
            >SMS
          </b-form-radio>
          <b-form-radio
            @input="filter_values.selected_operator = ''"
            class="ml-3"
            value="whatsapp"
            v-model="filter_values.type"
            >Whatsapp
          </b-form-radio>
        </b-row>
        <b-form-group>
          <v-select
            v-if="filter_values.type == 'sms'"
            v-model="filter_values.selected_operator"
            :options="operators"
            :placeholder="lang('t_operator')"
            :reduce="(val) => val"
            label="display_name"
          >
          </v-select>
          <v-select
            v-else
            v-model="filter_values.selected_operator"
            :options="wp_providers.filter((e) => e.status)"
            :placeholder="lang('t_whatsapp')"
            :reduce="(val) => val"
            label="display_name"
          >
          </v-select>
        </b-form-group>
      </tab-content>

      <!-- address -->
      <tab-content :title="lang('t_project')" icon="bi bi-rocket">
        <b-form-group>
          <v-select
            v-model="filter_values.selectedtProject"
            :options="projects"
            label="display_name"
            :reduce="(val) => val"
            :placeholder="lang('t_project')"
          >
          </v-select>
        </b-form-group>
      </tab-content>

      <!-- personal details -->
      <tab-content :title="lang('t_smsTemplate')" icon="bi bi-file-richtext">
        <b-form-group>
          <b-form-file
            @input="read_sms_data"
            v-model="files"
            :placeholder="lang('t_uploadFile')"
            :drop-placeholder="lang('t_uploadFile')"
            no-drop
          />
        </b-form-group>
      </tab-content>

      <!-- social link -->
      <tab-content :title="lang('t_preview')" icon="bi bi-phone">
        <b-row>
          <b-col>
            <center>
              <div style="position: relative">
                <b-img style="height: 50vh" src="phone.png" alt="phone" />
                <div
                  class="d-flex align-items-center"
                  style="
                    position: absolute;
                    top: 25.9%;
                    left: 25%;
                    z-index: 9999;
                    color: black;
                    max-width: 16vw;
                  "
                >
                  <b-img
                    class="mr-50"
                    :src="menu_logo_sq"
                    fluid
                    width="16"
                  ></b-img>
                  <span class="text-dark font-medium-2 font-weight-bolder">
                    {{
                      new Date().toLocaleTimeString("tr-TR", {
                        hour: "numeric",
                        minute: "2-digit",
                      })
                    }}
                  </span>
                </div>

                <div
                  class="d-flex align-items-center justify-content-center"
                  style="
                    position: absolute;
                    top: 60%;
                    left: 45%;
                    transform: translate(-50%, -50%);
                    z-index: 9999;
                    color: white;
                    max-width: 34vw;
                  "
                >
                  <span
                    v-if="this.smsexceldata.length > 0"
                    class="d-block msg_from bg-light-secondary font-small-3 text-left"
                    style="max-height: 22vh; overflow-y: auto"
                  >
                    {{ this.smsexceldata[0].messages }}
                  </span>
                </div>
              </div>
            </center>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>

    <b-card-text v-if="false">
      <b-row>
        <b-col cols="12">
          <!-- Operatör -->
          <b-form-group>
            <v-select
              v-model="filter_values.selected_operator"
              :options="operators"
              :placeholder="lang('t_operator')"
              :reduce="(val) => val"
              label="display_name"
            >
            </v-select>
          </b-form-group>
          <!-- Proje -->
          <b-form-group>
            <v-select
              v-model="filter_values.selectedtProject"
              :options="projects"
              :reduce="(val) => val"
              label="display_name"
              :placeholder="lang('t_project')"
            >
            </v-select>
          </b-form-group>
          <!-- Dosya Yükleme -->
          <b-form-group>
            <b-form-file
              v-model="files"
              :placeholder="lang('t_uploadFile')"
              :drop-placeholder="lang('t_uploadFile')"
              no-drop
            />
          </b-form-group>
          <!-- Gönder Butonu -->
          <b-button
            @click="read_sms_data"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            style="float: right"
          >
            {{ lang("t_send") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardText, BLink, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BButton, BFormFile, BFormRadio, BFormRadioGroup, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { codeIconInfo } from './code'
import "vue-form-wizard/dist/vue-form-wizard.min.css";
export default {
  components: {
    FormWizard,
    TabContent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BLink,
    BBadge,
    BCardText,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    BFormFile,
    BFormRadio,
    BFormRadioGroup,
    BImg,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      menu_logo_sq: globalThis.env.MENU_LOGO_SQ,
      filter_values: {
        type: 'sms',
        selected_operator: [],
        selectedtProject: [],
      },
      wp_providers: [],
      operators: [],
      projects: [],
      files: {},
      data: [],
      smsexceldata: [],
    }
  },
  mounted: function () {

    this.get_project();
    this.get_smssettings();
    this.get_wp_providers();
  },
  methods: {
    get_wp_providers: async function () {
      var response = (
        await this.$http_in.get(`text-channel-wp/v1/WhatsappProvider`)
      ).data;
      console.log("WhatsappProvider", response);
      this.wp_providers = response;
    },
    get_project: async function () {
      // var response = (
      //   await this.$http_in.get(`spv/v1/Project`)
      // ).data;
      this.projects = globalThis.projects;
    },
    get_smssettings: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsSettings`)
      ).data;

      this.operators = response;
    },
    async read_sms_data() {
      this.smsexceldata = [];
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = globalThis.XLSX.read(bstr, { type: 'binary' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = globalThis.XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        this.data = this.xlsxToJson(data);
        console.log("data", this.data); // Kontrol için data'yı yazdırın

        for (const item of this.data) {
          console.log(item); // Her bir öğeyi kontrol edin
          if (item['TELEFON'] && item['MESAJ']) {
            this.smsexceldata.push({
              phones: this.filter_values.selected_operator.prefix ?
                this.filter_values.selected_operator.prefix + item['TELEFON'].substr(-10) :
                '90' + item['TELEFON'].substr(-10),
              messages: item['MESAJ'].trim(),
            });
          }
        }
        console.log("smsexceldata", this.smsexceldata); // Sonuçları kontrol edin
      };
      reader.readAsBinaryString(this.files);
    },


    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },
    send_excel_sms: async function () {
      this.excel_dialog = false;

      this.$swal({
        title: this.lang('t_areYouSure'),
        text: this.smsexceldata.length + " adet mesaj gönderilecek onaylıyor musunuz?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          var response = '';
          for (const row of this.smsexceldata) {
            response = (
              await this.$http_in.post(
                `spv/v1/SendSms`,
                {
                  data: row,
                  sending_info: {
                    sms_settings: this.filter_values.selected_operator,
                    selected_project: {
                      internal_name: this.files.name
                    },
                    selected_sms_template: {
                      internal_name: ''
                    },
                    selected_filter: {
                      internal_name: ''
                    },
                    collection: `${this.filter_values.selectedtProject.internal_name}_customers`,
                  }
                }
              )
            ).data;
          }

          if (response.status == 'success') {
            this.$swal({
              icon: 'success',
              title: this.lang('t_processSuccess'),
              text: this.lang('t_messagesHaveBeenDeliveredSuccessfully'),
              confirmButtonText: this.lang('t_ok'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });

          } else {
            this.$swal({
              icon: 'warning',
              title: this.lang('t_warning'),
              text: this.lang('t_thereWasAProblemDeliveringYourMessages'),
              confirmButtonText: this.lang('t_ok'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          }
          this.files = '';

        }
      })


    },
  }
}
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
</style>
