<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-card no-body>
      <b-card-header class="d-block">
        <b-card-title>
          {{ lang("t_scheduler") }}
        </b-card-title>
        <!-- <b-card-sub-title class="mt-1">
          {{ lang("t_scheduler") }}
        </b-card-sub-title> -->
      </b-card-header>

      <b-card-body
        :style="{ maxHeight: '38vh', overflow: 'auto' }"
        class="px-0"
      >
        <b-card-text class="d-flex mx-1">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>

            <b-form-input
              v-model="searchQuery"
              :placeholder="lang('t_search')"
            ></b-form-input>
          </b-input-group>

          <b-button
            @click="
              isEdit = false;
              modal_record_details = true;
              clear_data();
            "
            size="sm"
            class="px-50 py-50 ml-2"
            variant="flat-secondary"
          >
            <b-icon icon="plus"></b-icon>
          </b-button>
          <!-- <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
            <b-icon icon="funnel"></b-icon>
          </b-button> -->
        </b-card-text>
        <b-table
          class="h-100"
          :filter="searchQuery"
          small
          hover
          :items="items"
          :fields="tableColumns"
          responsive
          show-empty
          :empty-text="lang('t_noRecord')"
          :busy="records.length == 0"
        >
          <template #empty="scope">
            <b-container class="mt-5">
              <b-row>
                <b-col class="text-center text-muted mt-50">
                  <span>{{ scope.emptyText }}</span>
                </b-col>
              </b-row>
            </b-container>
          </template>
          <template #cell(queuename)="data">
            <b-badge variant="light-primary">
              {{
                queues.find((e) => e.internal_name == data.value)
                  ? queues.find((e) => e.internal_name == data.value)
                      .display_name
                  : data.value
              }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              dropleft
              boundary="viewport"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="triggerImmediately(data.item)">
                <feather-icon icon="PlayIcon" />
                <span class="align-middle ml-50">{{
                  lang("t_triggerImmediately")
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="updateSelected(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="confirmText(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card-body>

      <b-card-footer class="py-75 border-0">
        <div class="d-flex justify-content-between d-flex align-items-center">
          <div class="d-flex align-items-center mb-0">
            <!-- <span class="text-nowrap"> Showing 1 to </span> -->
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="['10', '25', '50']"
              class="mr-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap text-muted font-small-3">
              {{
                lang("t_total") +
                " " +
                records.length +
                " " +
                lang("t_row").toLowerCase()
              }}
            </span>
          </div>

          <b-pagination
            size="sm"
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            align="right"
            :total-rows="records.length"
            first-number
            last-number
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="16" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="16" />
            </template>
          </b-pagination>
        </div>
      </b-card-footer>
    </b-card>

    <b-modal
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="setScheduler"
      dialog-class="dClass75"
    >
      <b-row>
        <b-col>
          <b-form-group :label="lang('t_displayName')">
            <b-form-input
              v-model="scheduler_info.display_name"
              :placeholder="lang('t_displayName')"
              trim
            /> </b-form-group
        ></b-col>
        <b-col>
          <b-form-group :label="lang('t_schedulerCategory')">
            <v-select
              :placeholder="lang('t_schedulerCategory')"
              v-model="scheduler_info.selected_scheduler_category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="scheduler_categories"
              :clearable="false"
            /> </b-form-group
        ></b-col>
      </b-row>
      <div v-if="scheduler_info.selected_scheduler_category == 'report'">
        <b-row>
          <b-col md="3" sm="12">
            <b-form-group :label="lang('t_reportCategory')">
              <v-select
                :placeholder="lang('t_reportCategory')"
                v-model="scheduler_info.report_category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="categories"
                @input="clear_report"
                :clearable="false"
              /> </b-form-group
          ></b-col>
          <b-col md="4" sm="12">
            <b-form-group :label="lang('t_report')">
              <div class="d-flex align-items-center w-100">
                <v-select
                  class="w-100"
                  @input="
                    () => {
                      scheduler_info.selected_report.selected_variant = null;
                      scheduler_info.selected_variant = null;
                    }
                  "
                  :placeholder="lang('t_report')"
                  v-model="scheduler_info.selected_report"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="display_name"
                  :options="
                    report_list.filter(
                      (e) => e.category == scheduler_info.report_category
                    )
                  "
                  :clearable="false"
                />
                <b-dropdown
                  v-if="scheduler_info.selected_report"
                  boundary="viewport"
                  variant="flat-secondary"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                  toggle-class="px-50 py-50 ml-50"
                >
                  <template #button-content>
                    <i class="bi bi-funnel font-medium-1"></i>
                  </template>

                  <b-dropdown-form>
                    <div style="max-height: 20rem; overflow-y: auto">
                      <b-form-checkbox
                        v-for="(item, i) in scheduler_info.selected_report
                          .fields"
                        :key="i"
                        v-model="item.visible"
                      >
                        <span class="align-middle ml-1">
                          {{ lang(item.label) }}
                        </span>
                      </b-form-checkbox>
                    </div>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
            </b-form-group></b-col
          >
          <b-col
            v-if="
              scheduler_info.selected_report &&
              scheduler_info.selected_report.variants
            "
            md="3"
            sm="12"
          >
            <b-form-group :label="lang('t_reportGroup')">
              <v-select
                appendToBody
                :placeholder="lang('t_pleaseSelect')"
                v-model="scheduler_info.selected_variant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="scheduler_info.selected_report.variants"
              /> </b-form-group
          ></b-col>

          <b-col md="2" sm="12">
            <div class="d-flex align-items-center">
              <b-form-group
                class="w-100"
                v-if="scheduler_info.date_type"
                :label="lang('t_lastXDays')"
              >
                <div class="d-flex align-items-center w-100">
                  <b-form-input
                    class="w-100"
                    v-model="scheduler_info.filter_values.lastXDays"
                    :placeholder="lang('t_lastXDays')"
                    trim
                  />
                  <b-button
                    class="px-50 py-50 ml-50"
                    @click="changeDateType"
                    variant="flat-secondary"
                  >
                    <i class="bi bi-arrow-left-right font-medium-1"></i>
                  </b-button>
                </div>
              </b-form-group>
              <b-form-group class="w-100" v-else :label="lang('t_period')">
                <div class="d-flex align-items-center w-100">
                  <v-select
                    class="w-100"
                    appendToBody
                    :placeholder="lang('t_pleaseSelect')"
                    v-model="scheduler_info.filter_values.datePeriod"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="display_name"
                    :reduce="(item) => item.internal_name"
                    :options="periods"
                  />

                  <b-button
                    class="px-50 py-50 ml-50"
                    @click="changeDateType"
                    variant="flat-secondary"
                  >
                    <i class="bi bi-arrow-left-right font-medium-1"></i>
                  </b-button>
                </div>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="scheduler_info.selected_report">
          <b-col
            :key="i"
            v-for="(item, i) in scheduler_info.selected_report.filters"
            :cols="scheduler_info.selected_report.filters.length > 4 ? 2 : 4"
          >
            <b-form-group v-if="item.internal_name == 'Project'">
              <v-select
                v-model="scheduler_info.filter_values.selectedProjects"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="projects"
              />
            </b-form-group>

            <b-form-group v-if="item.internal_name == 'Queue'">
              <v-select
                v-model="scheduler_info.filter_values.selectedQueues"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="queues"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'Campaign'">
              <v-select
                v-model="scheduler_info.filter_values.selectedCampaigns"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="campaigns"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'IVR'">
              <v-select
                v-model="scheduler_info.filter_values.selectedIvr"
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="ivr_list"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'Agent'">
              <v-select
                v-model="scheduler_info.filter_values.selectedAgents"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="(item) => item.username"
                :options="agents"
              />
            </b-form-group>

            <b-form-group v-if="item.internal_name == 'RemoteNumber'">
              <b-form-input
                v-model="scheduler_info.filter_values.selectedNumber"
                :placeholder="lang(item.display_name)"
                @update="
                  () => {
                    scheduler_info.filter_values.selectedNumber =
                      scheduler_info.filter_values.selectedNumber
                        .split(' ')
                        .join('');
                  }
                "
                @input="
                  () => {
                    scheduler_info.filter_values.selectedNumber =
                      scheduler_info.filter_values.selectedNumber
                        .split(' ')
                        .join('');
                  }
                "
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'Verdict'">
              <v-select
                :closeOnSelect="false"
                appendToBody
                v-model="scheduler_info.filter_values.selectedVerdicts"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                deselectFromDropdown
                label="finish_code"
                :reduce="(item) => item.finish_code"
                :options="finish_codes"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'TerminateSide'">
              <v-select
                :closeOnSelect="false"
                appendToBody
                v-model="scheduler_info.filter_values.selectedTerminateSides"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                deselectFromDropdown
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="[
                  { internal_name: 'local', display_name: 'local' },
                  { internal_name: 'remote', display_name: 'remote' },
                ]"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'NAQueueFilter'">
              <b-form-checkbox
                v-model="scheduler_info.filter_values.dontGetNoQueueRecords"
                class="custom-control-primary mt-75"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_dontGetNoQueueRecords") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'Direction'">
              <v-select
                :closeOnSelect="false"
                appendToBody
                v-model="scheduler_info.filter_values.selectedDirections"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                deselectFromDropdown
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="[
                  { internal_name: 'inbound', display_name: 'Inbound' },
                  { internal_name: 'outbound', display_name: 'Outbound' },
                  { internal_name: 'dialer', display_name: 'Dialer' },
                  { internal_name: 'text', display_name: 'Text' },
                  { internal_name: 'manual', display_name: 'Manual' },
                ]"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'AgentGroup'">
              <v-select
                :closeOnSelect="false"
                appendToBody
                v-model="scheduler_info.filter_values.selectedAgentGroups"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                deselectFromDropdown
                label="display_name"
                :reduce="(item) => item"
                :options="agent_groups"
                @input="onAgentGroupChange"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'SLDuration'">
              <b-form-input
                type="number"
                v-model="scheduler_info.filter_values.selectedSL"
                :placeholder="lang(item.display_name)"
                @update="
                  () => {
                    scheduler_info.filter_values.selectedSL =
                      scheduler_info.filter_values.selectedSL
                        .split(' ')
                        .join('');
                  }
                "
                @input="
                  () => {
                    scheduler_info.filter_values.selectedSL =
                      scheduler_info.filter_values.selectedSL
                        .split(' ')
                        .join('');
                  }
                "
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'title'">
              <b-form-input
                v-model="scheduler_info.filter_values.title"
                :placeholder="lang(item.display_name)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="scheduler_info.selected_report">
          <b-col>
            <b-form-group
              v-if="
                scheduler_info.selected_report.internal_name == 'CallDetails'
              "
            >
              <v-select
                v-model="scheduler_info.filter_values.selectedCustomerColumns"
                multiple
                :placeholder="'Ekstra Müşteri Bilgisi Ekle...'"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :reduce="(item) => item"
                :options="project_columns"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="
            scheduler_info.selected_report &&
            scheduler_info.selected_report.internal_name ==
              'DataSummaryByDynamicFields'
          "
        >
          <b-col>
            <b-form-group>
              <v-select
                v-model="
                  scheduler_info.filter_values.selectedDynamicCRMColumns.field1
                "
                :placeholder="lang('t_primaryKey')"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                deselectFromDropdown
                label="text"
                :reduce="(item) => item.value"
                :options="project_columns"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <v-select
                v-model="
                  scheduler_info.filter_values.selectedDynamicCRMColumns.field2
                "
                :placeholder="lang('t_secondaryKey')"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :reduce="(item) => item.value"
                :options="project_columns"
              >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="scheduler_info.selected_report">
          <b-col cols="3">
            <b-form-group :label="lang('t_timeFormat')">
              <v-select
                :placeholder="lang('t_timeFormat')"
                v-model="scheduler_info.selected_time_format"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="report_time_types"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="lang('t_sendAsAttachment')">
              <b-form-checkbox v-model="scheduler_info.send_as_attachment">
                <span class="align-middle">
                  {{ lang("t_sendAsAttachment") }}
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="scheduler_info.selected_scheduler_category == 'voice_export'">
        <div class="d-flex align-items-center">
          <b-form-group
            class="w-100"
            v-if="scheduler_info.date_type"
            :label="lang('t_lastXDays')"
          >
            <div class="d-flex align-items-center w-100">
              <b-form-input
                class="w-100"
                v-model="scheduler_info.filter_values.lastXDays"
                :placeholder="lang('t_lastXDays')"
                trim
              />
              <b-button
                class="px-50 py-50 ml-50"
                @click="changeDateType"
                variant="flat-secondary"
              >
                <i class="bi bi-arrow-left-right font-medium-1"></i>
              </b-button>
            </div>
          </b-form-group>
          <b-form-group class="w-100" v-else :label="lang('t_period')">
            <div class="d-flex align-items-center w-100">
              <v-select
                class="w-100"
                appendToBody
                :placeholder="lang('t_pleaseSelect')"
                v-model="scheduler_info.filter_values.datePeriod"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="periods"
              />

              <b-button
                class="px-50 py-50 ml-50"
                @click="changeDateType"
                variant="flat-secondary"
              >
                <i class="bi bi-arrow-left-right font-medium-1"></i>
              </b-button>
            </div>
          </b-form-group>
        </div>
        <voice-export
          :currentData="scheduler_info.selected_voice"
          :filter_values="scheduler_info.filter_values"
        ></voice-export>
      </div>
      <div v-if="scheduler_info.selected_scheduler_category == 'http_request'">
        <b-row>
          <b-col>
            <b-form-group :label="lang('t_type')">
              <v-select
                v-model="scheduler_info.http_info.type"
                :options="['GET', 'POST', 'PUT', 'DELETE']"
                :placeholder="lang('t_type')"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="lang('t_url')">
              <b-form-input
                v-model="scheduler_info.http_info.url"
                trim
                :placeholder="lang('t_url')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="lang('t_headers')">
              <b-form-textarea
                rows="4"
                v-model="scheduler_info.http_info.headers"
                trim
                :placeholder="lang('t_headers')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="lang('t_body')">
              <b-form-textarea
                rows="4"
                v-model="scheduler_info.http_info.body"
                trim
                :placeholder="lang('t_body')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- <b-button v-b-modal.modal-crons block variant="outline-primary mb-1">
        {{ lang("t_seeExampleTimePatterns") }}
      </b-button> -->
      <b-row>
        <b-col cols="12">
          <b-form-group :label="lang(`t_smsTemplate`)">
            <v-select
              :placeholder="lang('t_smsTemplate')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="description"
              :reduce="(item) => item"
              :options="cron_examples"
              v-model="selected_cron_example"
              @input="set_cron_template"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div>
        <b-row>
          <b-col v-for="(item, i) in Object.keys(cron_info)" :key="i">
            <b-form-group :label="lang(`t_${item}`)">
              <b-form-input
                @input="set_cron"
                v-model="cron_info[item]"
                :placeholder="lang(`t_${item}`)"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-row class="mb-1 mt-1">
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-badge
            :variant="isCronInvalid() ? 'light-secondary' : 'light-danger'"
          >
            <span class="font-medium-1 font-weight-bolder">
              {{ scheduler_info.readable_string }}
            </span>
          </b-badge>
        </b-col>
      </b-row>
      <div>
        <b-row>
          <b-col>
            <b-form-group :label="lang(`t_communicationType`)">
              <v-select
                :placeholder="lang('t_communicationType')"
                v-model="scheduler_info.communication_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="communication_types"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="lang(`t_communicationTitle`)">
              <b-form-input
                v-model="scheduler_info.communication_title"
                :placeholder="lang(`t_communicationTitle`)"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="lang(`t_description`)">
              <b-form-textarea
                v-model="scheduler_info.communication_description"
                :placeholder="lang('t_description')"
                rows="3"
                max-rows="6"
                trim
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :description="
                lang('t_youCanAddCommaBetweenEmailAddressesToaddMore')
              "
              :label="lang('t_to')"
            >
              <b-form-input
                v-model="scheduler_info.communication_to"
                :placeholder="lang(`t_to`)"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :description="
                lang('t_youCanAddCommaBetweenEmailAddressesToaddMore')
              "
              :label="lang('t_cc')"
            >
              <b-form-input
                v-model="scheduler_info.communication_cc"
                :placeholder="lang(`t_cc`)"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      size="lg"
      static
      id="modal-crons"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      centered
      ok-only
      :title="lang('t_examples')"
    >
      <b-table
        class="position-relative"
        :items="cron_examples"
        responsive
        :fields="cron_fields"
      >
        <template #cell(action)="data">
          <center>
            <b-button
              @click="set_cron_template(data.item.cron)"
              variant="success"
              >{{ lang("t_apply") }}
            </b-button>
          </center>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BContainer,
  BDropdownForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import cronParser from "cron-parser";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VoiceExport from './Components/VoiceExport/VoiceExport.vue'
globalThis.cronParser = cronParser;
export default {
  components: {
    // UsersListFilters,
    VoiceExport,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
    BContainer,
    BDropdownForm,
  },

  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      modal_record_details: false,
      cronstrue: "",
      perPage: 10,
      totalUsers: 0,
      perPageOptions: "",
      searchQuery: "",
      sortBy: "",
      isSortDirDesc: "",
      currentPage: 1,
      agents: [],
      projects: [],
      queues: [],
      ivr_list: [],
      agent_groups: [],
      finish_codes: [],
      periods: [
        { internal_name: "today", display_name: globalThis._lang("t_today") },
        { internal_name: "yesterday", display_name: globalThis._lang("t_yesterday") },
        { internal_name: "this_week", display_name: globalThis._lang("t_thisWeek") },
        { internal_name: "last_week", display_name: globalThis._lang("t_lastWeek") },
        { internal_name: "this_month", display_name: globalThis._lang("t_thisMonth") },
        { internal_name: "last_month", display_name: globalThis._lang("t_lastMonth") },
      ],
      categories: [
        { internal_name: "queue", display_name: globalThis._lang("t_queue") },
        { internal_name: "agent", display_name: globalThis._lang("t_agent") },
        { internal_name: "crm", display_name: globalThis._lang("t_crm") },
        { internal_name: "quality", display_name: globalThis._lang("t_quality") },
        { internal_name: "other", display_name: globalThis._lang("t_other") },
      ],
      selected_cron_example: {
        cron: "0 * * * *",
        description: globalThis._lang("t_cron2"),
        action: "",
      },
      cron_examples: [
        {
          cron: "* * * * *",
          description: globalThis._lang("t_cron1"),
          action: "",
        },
        {
          cron: "0 * * * *",
          description: globalThis._lang("t_cron2"),
          action: "",
        },
        {
          cron: "0 0 * * *",
          description: globalThis._lang("t_cron3"),
          action: "",
        },
        {
          cron: "0 17 * * *",
          description: globalThis._lang("t_cron4"),
          action: "",
        },
        {
          cron: "0 0 * * 0",
          description: globalThis._lang("t_cron5"),
          action: "",
        },
        {
          cron: "0 0 1 * *",
          description: globalThis._lang("t_cron6"),
          action: "",
        },
        {
          cron: "0 0 1 1 *",
          description: globalThis._lang("t_cron7"),
          action: "",
        },
        {
          cron: "0 0 * * 1-5",
          description: globalThis._lang("t_cron8"),
          action: "",
        },
        {
          cron: "0 0 15 * *",
          description: globalThis._lang("t_cron9"),
          action: "",
        },
        {
          cron: "0 0 1 * 1",
          description: globalThis._lang("t_cron10"),
          action: "",
        },
        {
          cron: "0 0 15 1,6,12 *",
          description: globalThis._lang("t_cron11"),
          action: "",
        },
        {
          cron: "0 0 1 * 1-5",
          description: globalThis._lang("t_cron12"),
          action: "",
        },
        {
          cron: "0 0 * * 6,0",
          description: globalThis._lang("t_cron13"),
          action: "",
        },
      ],
      cron_fields: [
        { key: "cron", label: globalThis._lang("t_cron"), sortable: true },
        {
          key: "description",
          label: globalThis._lang("t_description"),
          sortable: true,
        },
        { key: "action", label: '', sortable: true, thStyle: { width: '5vh' } },
      ],
      report_list: [],
      project_columns: [],
      report_permissions: [],
      column_labels: {
        customer_no: globalThis._lang('t_customerNo'),
        customer_name: globalThis._lang('t_customerName'),
        customer_identifier: globalThis._lang('t_customerIdentifier'),
        queue: globalThis._lang('t_queue'),
        active: globalThis._lang('t_state'),
        status: globalThis._lang('t_status'),
        assigned_agent: globalThis._lang('t_agent'),
        attempts: globalThis._lang('t_attempts'),
        total_attempts: globalThis._lang('t_totalAttempts'),
        campaign: globalThis._lang('t_campaign'),
        finish_code: globalThis._lang('t_finishCode'),
        insert_date: globalThis._lang('t_insertDate'),
        action_date: globalThis._lang('t_actionDate'),
        action_time: globalThis._lang('t_actionTime'),
        create_date: globalThis._lang('t_createDate'),
        create_time: globalThis._lang('t_createTime'),
        ex_agent: globalThis._lang('t_exAgent'),
        assign_date: globalThis._lang('t_assignDate'),
        new_assets: globalThis._lang('t_newAssets'),
        reached: globalThis._lang('t_reached'),
        reached_date: globalThis._lang('t_reachedDate'),
      },
      scheduler_categories: [
        { internal_name: "report", display_name: globalThis._lang("t_report") },
        { internal_name: "voice_export", display_name: globalThis._lang("t_voiceExport") },
        { internal_name: "http_request", display_name: globalThis._lang("t_httpRequest") },
      ],
      cron_info: {
        minute: "0",
        hour: "*",
        day: "*",
        month: "*",
        weekday: "*",
      },
      communication_types: [
        { internal_name: "mail", display_name: globalThis._lang("t_email") },
        {
          internal_name: "mobile_notification",
          display_name: globalThis._lang("t_mobileNotification"),
        },
      ],
      report_time_types: [
        {
          internal_name: "hour",
          display_name: globalThis._lang("t_hour"),
        },
        {
          internal_name: "minute",
          display_name: globalThis._lang("t_minute"),
        },
        {
          internal_name: "second",
          display_name: globalThis._lang("t_second"),
        },
        {
          internal_name: "time",
          display_name: globalThis._lang("t_time"),
        }
      ],
      scheduler_info: {
        selected_scheduler_category: "report",
        report_category: "queue",
        date_type: true,
        display_name: "",
        cron: "* * * * *",
        readable_string: "",
        communication_type: "mail",
        communication_title: "",
        communication_description: "",
        communication_to: "",
        communication_cc: "",
        selected_report: null,
        selected_voice: {
          folder_format: "{{Year}}/{{Month}}/{{Day}}",
          name_format: "{{Direction}}_{{BaseRemoteNumber}}_{{Agent}}_{{StartTime}}_{{InteractionID}}",
          format: "ogg",
          output_file_name: "",
          timestamp: false,
          emails: "",
          put_ftp: false,
          ftp_provider: null,
          ftp_remote_path: ''
        },
        http_info: {
          type: 'GET',
          url: '',
          headers: `{\n\n}`,
          body: `{\n\n}`,
        },
        selected_variant: null,
        selected_time_format: 'second',
        send_as_attachment: false,
        filter_values: {
          lastXDays: 1,
          selectedAgents: [],
          selectedQueues: [],
          selectedProjects: [],
          selectedCampaigns: [],
          selectedVerdicts: [],
          selectedTerminateSides: [],
          selectedDirections: [],
          selectedAgentGroups: [],
          is_inbound: true,
          is_outbound: true,
          is_dialer: true,
          selectedCustomerColumns: [],
          selectedIvr: "",
          title: "",
          selectedNumber: "",
          selectedInteractionIds: "",
          isFileNotNull: false,
          selectedSL: 20,
          selectedDynamicCRMColumns: {
            field1: "",
            field2: "",
          },
          dontGetNoQueueRecords: false,

        },
      },
      isEdit: false,
      records: [],
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "cron",
          label: globalThis._lang("t_schedulerTime"),
          sortable: true,
        },
        {
          key: "readable_string",
          label: globalThis._lang("t_schedulerTimeString"),
          sortable: true,
        },
        {
          key: "next_run_time",
          label: globalThis._lang("t_nextRunTime"),
          sortable: true,
        },
        {
          key: "creator",
          label: globalThis._lang("t_creator"),
          sortable: true,
        },
        {
          key: "create_date",
          label: globalThis._lang("t_date"),
          sortable: true,
        },
        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: {
            width: '5vh'
          },
        }
      ],
    };
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.records.slice(start, end);
    },

  },
  methods: {
    async triggerImmediately(item) {
      let response = (
        await this.$http_in.post(
          `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/sch/v1/TriggerImmediately`,
          item
        )
      ).data;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    onAgentGroupChange() {
      this.scheduler_info.filter_values.selectedAgents = [];
      let _agents = [...new Set(this.scheduler_info.filter_values.selectedAgentGroups.flatMap(e => e.agents.map(j => j.split('@')[0])))];
      console.log("_agents", _agents);
      this.scheduler_info.filter_values.selectedAgents = _agents;
    },
    changeDateType() {
      this.scheduler_info.date_type = !this.scheduler_info.date_type;
      this.scheduler_info.filter_values.datePeriod = null;
    },
    set_cron_template() {
      // console.log(cron_string);

      let cron_parts = this.selected_cron_example.cron.split(" ");
      this.cron_info["minute"] = cron_parts[0];
      this.cron_info["hour"] = cron_parts[1];
      this.cron_info["day"] = cron_parts[2];
      this.cron_info["month"] = cron_parts[3];
      this.cron_info["weekday"] = cron_parts[4];
      this.set_cron();
      this.$bvModal.hide("modal-crons");
    },
    isCronInvalid() {
      try {
        globalThis.cronParser.parseExpression(this.scheduler_info.cron);
        return true;
      } catch (error) {
        return false;
      }
    },
    updateSelected(item) {
      console.log("item", item);
      let cron_parts = item.cron.split(" ");
      this.cron_info["minute"] = cron_parts[0];
      this.cron_info["hour"] = cron_parts[1];
      this.cron_info["day"] = cron_parts[2];
      this.cron_info["month"] = cron_parts[3];
      this.cron_info["weekday"] = cron_parts[4];

      this.scheduler_info = {
        ...item,
        communication_to: item.communication_to.join(","),
        communication_cc: item.communication_cc.join(","),
      };
      this.isEdit = true;
      this.modal_record_details = true;
    },

    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/sch/v1/Scheduler `)).data;

      this.records = response;
      this.clear_data();
      this.is_progress = false;
    },
    set_cron() {
      this.scheduler_info.cron = `${this.cron_info.minute} ${this.cron_info.hour} ${this.cron_info.day} ${this.cron_info.month} ${this.cron_info.weekday}`;
      this.scheduler_info.readable_string = this.cronstrue.toString(
        this.scheduler_info.cron,
        {
          use24HourTimeFormat: true,
          locale: globalThis.selected_lang ?? "tr",
        }
      );
    },
    clear_report() {
      this.fields = [];
      this.items = [];
      this.scheduler_info.filter_values = {
        lastXDays: 1,
        datePeriod: null,
        selectedAgents: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedCampaigns: [],
        selectedVerdicts: [],
        selectedTerminateSides: [],
        selectedDirections: [],
        selectedAgentGroups: [],
        is_inbound: true,
        is_outbound: true,
        is_dialer: true,
        selectedCustomerColumns: [],
        selectedIvr: "",
        title: "",
        selectedNumber: "",
        isFileNotNull: false,
        selectedSL: 20,
        selectedDynamicCRMColumns: {
          field1: "",
          field2: "",
        },
        dontGetNoQueueRecords: false,
      };

      this.scheduler_info.selected_report = "";
    },
    clear_data() {
      this.cron_info = {
        minute: "0",
        hour: "*",
        day: "*",
        month: "*",
        weekday: "*",
      };

      this.scheduler_info = {
        selected_scheduler_category: "report",
        date_type: true,
        report_category: 'queue',
        display_name: "",
        cron: "* * * * *",
        readable_string: "",
        communication_type: "mail",
        communication_title: "",
        communication_description: "",
        communication_to: "",
        communication_cc: "",
        selected_report: null,
        selected_voice: {
          folder_format: "{{Year}}/{{Month}}/{{Day}}",
          name_format: "{{Direction}}_{{BaseRemoteNumber}}_{{Agent}}_{{StartTime}}_{{InteractionID}}",
          format: "ogg",
          output_file_name: "",
          timestamp: false,
          emails: "",
          put_ftp: false,
          ftp_provider: null,
          ftp_remote_path: ''
        },
        selected_time_format: 'second',
        send_as_attachment: false,
        selected_variant: null,
        http_info: {
          type: 'GET',
          url: '',
          headers: `{\n\n}`,
          body: `{\n\n}`,
        },


        filter_values: {
          lastXDays: 1,
          datePeriod: null,
          selectedAgents: [],
          selectedQueues: [],
          selectedProjects: [],
          selectedCampaigns: [],
          selectedVerdicts: [],
          selectedTerminateSides: [],
          selectedDirections: [],
          selectedAgentGroups: [],
          is_inbound: true,
          is_outbound: true,
          is_dialer: true,
          selectedCustomerColumns: [],
          selectedIvr: "",
          title: "",
          selectedNumber: "",
          selectedInteractionIds: "",
          isFileNotNull: false,
          selectedSL: 20,
          selectedDynamicCRMColumns: {
            field1: "",
            field2: "",
          },
          dontGetNoQueueRecords: false
        },
      };

      this.set_cron();
    },
    confirmText(item) {
      this.scheduler_info = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteScheduler();
        }
      });
    },
    setScheduler: async function () {
      try {
        if (this.scheduler_info.scheduler_categories == 'report') {
          this.scheduler_info.selected_report.fields = this.scheduler_info.selected_report.fields.filter(e => e.visible == true);

        }
        if (this.scheduler_info.scheduler_categories == 'voice_export') {
          this.filter_values.selectedNumber = this.filter_values.selectedNumber.split(',');

        }
        this.is_progress = true;
        let response;
        if (!this.isEdit) {
          response = (
            await this.$http_in.post(
              `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/sch/v1/Scheduler`,
              this.scheduler_info
            )
          ).data;
        } else {
          response = (
            await this.$http_in.put(
              `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/sch/v1/Scheduler`,
              this.scheduler_info
            )
          ).data;
        }

        if (response && response.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.get_records();
        this.is_progress = false;
      }
    },
    deleteScheduler: async function () {
      try {
        this.is_progress = true;
        let response = (
          await this.$http_in.delete(
            `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/sch/v1/Scheduler/${this.scheduler_info._id}`
          )
        ).data;

        if (response && response.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.get_records();
        this.is_progress = false;
      }
    },

    get_users: async function () {
      var response = (await this.$http_in.get(`report/v1/User`)).data;

      this.agents = response;
    },

    GetProjects: async function () {
      var response = (await this.$http_in.get(`report/v1/Project`)).data;

      this.projects = response;
    },

    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          // this.selected_queues.push(item.internal_name);
        } else {
          this.queues.filter(e => e.internal_name != item.internal_name)
        }
      }
    },
    GetCampaigns: async function () {
      var response = (await this.$http_in.get(`report/v1/Campaign`)).data;

      this.campaigns = response;
    },
    getCustomerColumns: async function () {
      // //console.log('this.agent.Project.INTERNAL_NAME', this.agent);
      var response = (await this.$http_in.get(`crm/v1/CustomerColumns`)).data;
      this.project_columns = [];
      this.columns = response[0].allkeys.sort();
      for (const item of response[0].allkeys) {
        if (
          [
            "active",
            "_id",
            "notes",
            "phones",
            "products",
            "extra_data",
            "sms",
            "attempts",
            "campaign",
          ].includes(item)
        ) {
          continue;
        }
        this.project_columns.push({
          text:
            this.column_labels[item] == undefined
              ? item
              : this.column_labels[item],
          value: item,
        });
      }
    },
    GetIVR: async function () {
      var response = (await this.$http_in.get(`report/v1/Ivr`)).data;

      this.ivr_list = response;
    },
    GetReports: async function () {
      var response = (await this.$http_in.get(`report/v1/Reports`)).data;
      // response = response.filter(e => e.internal_name != 'CallDetails')
      this.report_list = [];
      for (const item of response) {
        for (const field of item.fields) {
          field.visible = true;
          field.label = globalThis._lang(field.label);
        }
        if (this.report_permissions.includes(item.internal_name)) {
          item.display_name = globalThis._lang(item.display_name);
          if (item.variants) {
            for (const _variant of item.variants) {
              _variant.display_name = globalThis._lang(_variant.display_name);
            }
          }
          this.report_list.push(item);
        }
      }
      console.log("test", response);
    },
    GetAgentGroup: async function () {
      var response = (await this.$http_in.get(`/report/v1/AgentGroup`)).data;

      this.agent_groups = response;
    },

    GetFinishCodes: async function () {
      var response = (await this.$http_in.get(`spv/v1/FinishCodes`)).data;

      this.finish_codes = response;
      this.finish_codes.push({ finish_code: 'Abandoned' });
      this.finish_codes.push({ finish_code: 'HangupInIVR' });
      this.finish_codes.push({ finish_code: 'Success' });
      this.finish_codes.push({ finish_code: 'No Action' });
      this.finish_codes.push({ finish_code: 'AgentConnectionLost' });
      this.finish_codes.push({ finish_code: 'RemoteConnectionLost' });

    },
  },

  mounted: async function () {
    this.is_progress = true;
    this.report_permissions = globalThis.permissions["report"] || [];
    this.cronstrue = globalThis.cronstrue;
    this.get_records();
    this.getCustomerColumns();
    this.GetReports();
    this.get_users();
    this.GetProjects();
    this.GetQueues();
    this.GetCampaigns();
    this.GetIVR();
    this.set_cron();
    this.GetAgentGroup();
    this.GetFinishCodes();

    this.is_progress = false;
    // this.GetInboundRouteTargets();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
