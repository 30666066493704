<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_smsFilter") }}
            </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              {{ lang("t_extensionRoutes") }}
            </b-card-sub-title> -->
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :fields="tableColumns"
              :items="records"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="records.length == 0"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(project)="data">
                {{
                  projects.find((e) => e.internal_name == data.item.project)
                    .display_name
                }}
              </template>
              <template #cell(filters)="data">
                <span v-for="(item, i) in data.item.filters" :key="i">{{
                  `${
                    column_labels[item.column] == undefined
                      ? item.column
                      : column_labels[item.column]
                  }${item.operand}"${item.value}",`
                }}</span>
              </template>

              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="records.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      size="lg"
      scrollable
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          style="min-height: 60vh; overflow-y: auto"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Project -->
          <b-row>
            <b-col cols="5">
              <validation-provider
                #default="validationContext"
                name="Project"
                rules="required"
              >
                <b-form-group :label="lang('t_project')" label-for="project">
                  <v-select
                    v-model="currentData.project"
                    :options="projects"
                    label="display_name"
                    :reduce="(val) => val.internal_name"
                    @input="get_columns"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_displayName')"
                  label-for="display_name"
                >
                  <b-form-input
                    id="display_name"
                    v-model="currentData.display_name"
                    autofocus
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="1">
              <b-button
                block
                variant="outline-primary"
                class="btn-icon mt-2"
                @click="repeateAgain"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_addFilter')"
              >
                <feather-icon class="mr-1" icon="PlusIcon" />
              </b-button>
            </b-col>
          </b-row>

          <div>
            <div>
              <h4>{{ lang("t_filters") }}</h4>

              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in currentData.filters"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <!-- Item Name -->
                <b-col md="4" sm="6">
                  <b-form-group
                    :label="lang('t_columnName')"
                    label-for="profile"
                  >
                    <v-select
                      :reduce="(val) => val.internal_name"
                      label="display_name"
                      :options="columns"
                      v-model="item.column"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>

                <!-- Cost -->
                <b-col md="4" sm="6">
                  <b-form-group
                    :label="lang('t_operator')"
                    label-for="t_operator"
                  >
                    <v-select
                      :options="['=', '!=', '<', '>', '<=', '>=']"
                      v-model="item.operand"
                    />
                  </b-form-group>
                </b-col>

                <!-- Quantity -->
                <b-col md="3" sm="6">
                  <b-form-group :label="lang('t_value')" label-for="t_value">
                    <b-form-input autofocus id="t_value" v-model="item.value" />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col md="1" sm="6">
                  <b-button
                    block
                    variant="outline-danger"
                    class="btn-icon mt-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-col>
                <div>
                  <hr />
                </div>
              </b-row>
            </div>
            <!-- <b-button block variant="primary" @click="repeateAgain">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{ lang("t_addFilter") }}</span>
            </b-button>
            <b-button block variant="danger" @click="currentData.filters = []">
              <feather-icon icon="Trash2Icon" class="mr-25" />
              <span>{{ lang("t_clearFilter") }}</span>
            </b-button> -->
          </div>

          <!-- Form Actions -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BContainer,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useList from './useList'
import storeModule from '../storeModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { heightTransition } from '@core/mixins/ui/transition'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // UsersListFilters,
    BContainer,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
    vSelect,
  },
  mixins: [heightTransition],
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.records.slice(start, end);
    },

  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      isEdit: false,
      currentData: {
        project: '',
        internal_name: '',
        display_name: '',
        filters: [],
      },
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      nextTodoId: 2,
      columns: [],
      records: [],
      trHeight: null,
      projects: [],
      tableColumns: [
        { key: 'display_name', label: globalThis._lang('t_filterName'), sortable: true },
        { key: 'project', label: globalThis._lang('t_projectName'), sortable: true },
        // { key: 'create_date', label: globalThis._lang('t_columnNameCreateDate'), sortable: true },
        { key: 'filters', label: globalThis._lang('t_filter'), sortable: true },
        { key: 'actions', label: '', sortable: false, thStyle: { width: '5vh' } },
      ],
      column_labels: {
        customer_no: globalThis._lang('t_customerNo'),
        customer_name: globalThis._lang('t_customerName'),
        customer_identifier: globalThis._lang('t_customerIdentifier'),
        queue: globalThis._lang('t_queue'),
        active: globalThis._lang('t_state'),
        status: globalThis._lang('t_status'),
        assigned_agent: globalThis._lang('t_agent'),
        attempts: globalThis._lang('t_attempts'),
        total_attempts: globalThis._lang('t_totalAttempts'),
        campaign: globalThis._lang('t_campaign'),
        finish_code: globalThis._lang('t_finishCode'),
        insert_date: globalThis._lang('t_insertDate'),
        action_date: globalThis._lang('t_actionDate'),
        action_time: globalThis._lang('t_actionTime'),
        create_date: globalThis._lang('t_createDate'),
        create_time: globalThis._lang('t_createTime'),
        ex_agent: globalThis._lang('t_exAgent'),
        assign_date: globalThis._lang('t_assignDate'),
        new_assets: globalThis._lang('t_newAssets'),
        reached: globalThis._lang('t_reached'),
        reached_date: globalThis._lang('t_reachedDate'),
      },
    }
  },
  mounted() {
    this.initTrHeight();
    this.get_records();
    this.get_project();
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    trAddHeight(val) {
      // Add height to existing height
      // Usage: Where new element is append or more height is added (e.g. list append)

      /* Assumes:
       - Height is assigned and is `String`
       - Incoming value is valid number in `Number` or `String`
      */
      const heightValue = Number(this.trHeight.substring(0, this.trHeight.length - 2))
      this.trHeight = `${heightValue + Number(val)}px`
    },
    trTrimHeight(val) {
      // Remove height from existing height
      // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

      /* Assumes:
       - Height is assigned and is `String`
       - Incoming value is valid number in `Number` or `String`
      */
      const heightValue = Number(this.trHeight.substring(0, this.trHeight.length - 2))
      this.trHeight = `${heightValue - Number(val)}px`
    },
    trSetHeight(val) {
      // Set height
      // Usage: Mostly for assigning initial value from mounted hook

      /* Assumes:
       - Height is not assigned and what to assign for add/remove operation
       - What to set height at something for odd usage
       - Incoming value is valid number in `Number` or `String`
      */
      if (val === null) this.trHeight = 'auto'
      else this.trHeight = `${Number(val)}px`
    },
    get_records: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsFilter`)
      ).data;
      console.log("SmsFilter", response);
      this.records = response;
    },
    get_columns: async function () {
      this.columns = [];
      console.log("currentData.project", this.currentData.project);
      var response = (
        await this.$http_in.get(`spv/v1/ProjectColumns/${this.currentData.project}_customers`)
      ).data;
      console.log("SmsColumns", response);
      //this.columns = response;
      for (const item of response[0].allkeys) {
        if (['active', '_id', 'cycles', 'customer_identifier_masked', 'notes', 'phones', 'products', 'campaign', 'sms', 'extra_data', 'daily_attempts', 'update_date', 'assigned_agent2'].includes(item)) {
          continue;
        }
        this.columns.push({
          internal_name: item,
          display_name: this.column_labels[item] == undefined ? item : this.column_labels[item]
        })
      }
    },
    get_project: async function () {
      // var response = (
      //   await this.$http_in.get(`spv/v1/Project`)
      // ).data;
      this.projects = globalThis.projects;
    },
    check_data() {
      if (this.currentData.display_name == '' || [null, undefined, ''].includes(this.currentData.project)) {
        return true;
      }
      return false;
    },

    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang('t_warning'),
          text: globalThis._lang('t_pleaseDoNotLeaveAnyBlankFields'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return;
      }
      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(
            `spv/v1/SmsFilter`,
            { FilterInfo: JSON.stringify(this.currentData) }
          )
        ).data;
      } else {
        response = (
          await this.$http_in.put(
            `spv/v1/SmsFilter`,
            {
              FilterInfo: JSON.stringify(this.currentData),
            }
          )
        ).data;
      }

      // if (response != null || response != undefined) {
      //   if (response.command == 'UPDATE') {
      //     this.alert_message = `${this.black_list_number.number} ${globalThis._lang("t_hasBeenUpdatedSuccessfully")}`;
      //     this.alert_type = 'success';
      //     this.is_alert = true;
      //   } else {
      //     this.alert_message = globalThis._lang("t_hasBeenCreatedSuccessfully");
      //     this.alert_type = 'success';
      //     this.is_alert = true;
      //   }
      // }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      var response = (
        await this.$http_in.delete(`spv/v1/SmsFilter/${this.currentData._id}`)
      ).data;


      if (response != null || response != undefined) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        id: '',
        project: '',
        internal_name: '',
        display_name: '',
        filters: [],
      }
    },
    repeateAgain() {
      this.currentData.filters.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.currentData.filters.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
      })
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete_record();
        }
      })
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      contact: '',
    }

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const isAddNewUserSidebarActive = ref(false)

    const projects = [
      { label: 'Garanti', value: 'garanti' },
      { label: 'Akbank', value: 'akbank' },
      { label: 'Turkcell', value: 'turkcell' },
    ]

    const extensOptions = [
      { label: '123', value: '123' },
      { label: '456', value: '456' },
      { label: '9999', value: '9999' },
      { label: '654', value: '654' },
    ]
    const projectOptions = [
      { label: 'Abc', value: '1' },
      { label: 'Proje2', value: '2' },
      { label: 'Proje3', value: '3' },
      { label: 'Proje4', value: '4' },
    ]
    const profileOptions = [
      { label: 'Admin', value: '1' },
      { label: 'Profil2', value: '2' },
      { label: 'Profil3', value: '3' },
      { label: 'Profil4', value: '4' },
    ]


    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      projects,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.vs__dropdown-menu {
  max-height: 200px !important;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
