export default {
  // Hoşgeldiniz
  t_welcome: "Добро пожаловать",
  // Tüm Hakları Saklıdır ©️ 2023 ver3.1.0
  t_copyright: "Все права защищены ©️ 2023 ver3.1.0",
  // Kopyala
  t_copy: "Копировать",
  // Kullanıcı Girişi
  t_userLogin: "Вход пользователя",
  // Kullanıcı Adı
  t_userName: "Имя пользователя",
  // Parola
  t_password: "Пароль",
  // Giriş
  t_login: "Войти",
  // Merhaba
  t_hello: "Привет",
  // Kullanıcı adı veya parola hatalı.
  t_usernameOrPasswordIsInvalid: "Неверное имя пользователя или пароль.",
  // Başarılı bir şekilde giriş yapıldı.
  t_successfullyLoggedIn: "Успешный вход в систему.",
  // Lütfen kuyruk ve sınıflandırma seçiniz
  t_pleaseSelectQueueAndClassification: "Пожалуйста, выберите очередь и классификацию.",
  // Lütfen proje ve kuyruk seçimi yapınız
  t_PleaseSelectProjetAndQueues: "Пожалуйста, выберите проект и очередь.",
  // Lütfen giriş yapınız
  t_pleaseSingIn: "Пожалуйста, войдите в систему.",
  // Beni Hatırla
  t_rememberMe: 'Запомнить меня',
  // Giriş Yap
  t_signIn: 'Войти',
  // Çağrı Sonrası Durum Değiştir
  t_changeStatusAfterTheCall: "Изменить статус после звонка",
  // Kalan Süre
  t_remainingTime: "Оставшееся время",
  // Devam Et
  t_continue: "Продолжить",
  // Beklet
  t_hold: "Удержать",
  // Mikrofon Aç
  t_microphoneOn: "Включить микрофон",
  // Mikrofon Kapa
  t_microphoneOff: "Выключить микрофон",
  // Tuşlama Listesi
  t_inputList: "Список ввода",
  // Tuşlamaları Göster
  t_showInputs: "Показать вводы",
  // Chat Paneli
  t_chatPanel: "Панель чата",
  // Detay Göster
  t_showDetail: "Показать детали",
  // Bağlı
  t_connected: "Подключено",
  // Bağlı Değil
  t_disconnected: "Не подключено",
  // Gündüz Modu
  t_dayMode: "Дневной режим",
  // Gece Modu
  t_nightMode: "Ночной режим",
  // Temsilci Listesi
  t_agentList: "Список агентов",
  // Çıkış
  t_logOut: "Выйти",
  // Hedefleme
  t_collectionTargeting: "Целеполагание",
  // Hedefleme
  t_targeting: "Целеполагание",
  // Pano
  t_dashboard: "Панель",
  // Ara
  t_search: "Поиск",
  // Ekle
  t_add: "Добавить",
  // Hedeflenen Ay
  t_targetedMonth: "Целевой месяц",
  // Tamam
  t_confirm: "ОК",
  // Seçili kaydı silmek istediğinizden emin misiniz?
  t_areYouSureYouWantToDeleteTheSelectedRecord: "Вы уверены, что хотите удалить выбранную запись?",
  // Seçili kayıtları güncellemek istediğinizden emin misiniz?
  t_areYouSureYouWantToUpdateTheSelectedRecords: "Вы уверены, что хотите обновить выбранные записи?",
  // Tümünü temizlemek istediğinizden emin misiniz?
  t_areYouSureYouWantToClearAll: "Вы уверены, что хотите очистить всё?",
  // Temsilci Seçiniz
  t_selectTheAgent: "Выберите агента",
  // Gerçekleşme Tarihi
  t_collectedDate: "Дата выполнения",
  // Gerçekleşme Zamanı
  t_collectedTime: "Время выполнения",
  // Düzenle
  t_edit: "Редактировать",
  // KONFİGÜRASYON
  t_configuration: "КОНФИГУРАЦИЯ",
  // Konfigürasyon
  t_configuration2: "Конфигурация",
  // Tamamlanan
  t_completed: "Завершено",
  // Kalan
  t_remaining: "Осталось",
  // Tamamlama Oranı
  t_compilationRate: "Процент завершения",
  // Dakika
  t_minute: "Минута",
  // Hedef Tamamlama
  t_targetCompletion: "Целевое завершение",
  // Saat
  t_hour: "Час",
  // Gün
  t_day: "День",
  // Hafta
  t_week: "Неделя",
  // Ay
  t_month: "Месяц",
  // Yıl
  t_year: "Год",
  // Çalışma Günü
  t_weekday: "Рабочий день",
  // Son 1 Saat
  t_lastOneHour: "Последний час",
  // Son 3 Saat
  t_lastThreeHours: "Последние 3 часа",
  // Son 2 Gün
  t_twoDaysAgo: "2 дня назад",
  // Son 3 Gün
  t_threeDaysAgo: "3 дня назад",
  // Son 4 Gün
  t_fourDaysAgo: "4 дня назад",
  // Son 5 Gün
  t_fiveDaysAgo: "5 дней назад",
  // Son 6 Gün
  t_sixDaysAgo: "6 дней назад",
  // Periyot
  t_period: "Период",
  // Bu Hafta
  t_thisWeek: "На этой неделе",
  // Geçen Hafta
  t_lastWeek: "На прошлой неделе",
  // Getir
  t_get: "Получить",
  // Filtreleri Kaydet
  t_saveFilters: "Сохранить фильтры",
  // Hedef
  t_target: "Цель",
  // Anonslar
  t_announcements: "Объявления",
  // Anons Adı
  t_announcementName: "Название объявления",
  // Ses Dosyası Seçiniz
  t_selectAudioFile: "Выберите аудиофайл",
  // Anons Listesi
  t_announcementList: "Список объявлений",
  // Emin Misiniz?
  t_areYouSure: "Вы уверены?",
  // Tamam
  t_ok: "ОК",
  // Adet
  t_count: "Количество",
  // Tamamlanan Tutar
  t_CompletedAmount: "Завершенная сумма",
  // Günlük Hedef
  t_dailyTarget: "Ежедневная цель",
  // Aylık Hedef
  t_monthlyTarget: "Ежемесячная цель",
  // Günlük zaman çizelgesi
  t_dailyTimeline: "Ежедневный график",
  // Kara Liste
  t_blackList: "Черный список",
  // Açıklama
  t_descriptions: "Описание",
  // Gelen
  t_inbound: "Входящий",
  // Giden
  t_outbound: "Исходящий",
  // Numara
  t_number: "Номер",
  // Çağrı Geçmişi
  t_callHistory: "История вызовов",
  // Numara Geçmişi
  t_numberHistory: "История номеров",
  // Notlar
  t_notes: "Заметки",
  // Eski Notlar
  t_oldNotes: "Старые заметки",
  // Kampanyalar
  t_campaigns: "Кампании",
  // Kampanya
  t_campaign: "Кампания",
  // Kampanya Adı
  t_campaignName: "Название кампании",
  // Kampanya Listesi
  t_campaignList: "Список кампаний",
  // kampanyası başarılı bir şekilde silindi.
  t_campaignhasBeenDeletedSuccessfully: "Кампания успешно удалена.",
  // kampanyası başarılı bir şekilde güncellendi.
  t_campaignhasBeenUpdatedSuccessfully: "Кампания успешно обновлена.",
  // kampanyası başarılı bir şekilde güncellendi.
  t_campaignhasBeenCreatedSuccessfully: "Кампания успешно создана.",
  // Özel Sekmeler
  t_customTabs: "Пользовательские вкладки",
  // Adres
  t_address: "Адрес",
  // Sabit Prefix
  t_landlinePrefix: "Префикс стационарного телефона",
  // FCT Prefix
  t_fctPrefix: "Префикс FCT",
  // Özel Sekme Listesi
  t_customTabList: "Список пользовательских вкладок",
  // Veri Yönetimi
  t_dataManagement: "Управление данными",
  // Ürün Yönetimi
  t_productManagement: "Управление продуктами",
  // Script Yönetimi
  t_scriptManagement: "Управление скриптами",
  // Örnek Excel
  t_exampleFile: "Пример Excel",
  // Dosya Yükle
  t_uploadFile: "Загрузить файл",
  // Lütfen güncellenecek başlıkları seçiniz
  t_SelectTheTitlesToUpdate: "Пожалуйста, выберите заголовки для обновления",
  // Eşleşmeyen kayıtları pasif yap
  t_setUnmatchedRecordsToPassive: "Сделать несовпадающие записи неактивными",
  // Eşleşen kayıtların telefonlarını ve ürünlerini güncelle
  t_setUnmatchedRecordsToUpdate: "Обновить телефоны и продукты совпадающих записей",
  // Kurum adına ait tüm veriyi pasif yap
  t_setAllPreviousInstitutionDataToPassive: "Сделать все данные учреждения неактивными",
  // Eşleşen kayıtları güncelle
  t_updateMatchedRecords: "Обновить совпадающие записи",
  // Kurum adına ait tüm veriyi pasif yap
  t_setAllRecordsOfProjectNameToPassive: "Сделать все данные проекта неактивными",
  // Yükle
  t_upload: "Загрузить",
  // 0 adet müşteri başarılı bir şekilde yüklendi.
  t_0customersHasBeenUpdatedSuccessfully: "0 клиентов успешно загружены.",
  // adet müşteri başarılı bir şekilde yüklendi.
  t_customersHasBeenUpdatedSuccessfully: "клиентов успешно загружены.",
  // adet müşteri yüklenirken bir sorun oluştu.
  t_customersCouldNotBeUploadedBecauseThereHasBeenAnError: "Возникла ошибка при загрузке клиентов.",
  // Proje seçmek için tıklayınız
  t_pleaseSelectAProject: "Пожалуйста, выберите проект.",
  // Arama Planı
  t_dialPlan: "План вызовов",
  // Sınıflandırmalar
  t_classifications: "Классификации",
  // Simülasyon
  t_simulation: "Симуляция",
  // Sorgula
  t_check: "Проверить",
  // Sıra
  t_order: "Порядок",
  // Arama Deseni
  t_callingPatterns: "Шаблон вызовов",
  // Yönlendirme Deseni
  t_dialPattern: "Шаблон маршрутизации",
  // Çıkma sırası:
  t_simulatedCallCouldBePlacedFrom: "Порядок выхода:",
  // Çağrı çıkmadı!
  t_couldNotPlaceSimulationCall: "Вызов не состоялся!",
  // Toplam
  t_totalAgents: "Всего",
  // Konferans
  t_conference: "Конференция",
  // Bekleyen
  t_callsInQueue: "Ожидающие",
  // Kaçan
  t_abandonedCount: "Пропущенные",
  // Saatlik Kaçan Adetleri
  t_hourlyAbandonedCount: "Количество пропущенных в час",
  // Süre
  t_statusLength: "Длительность",
  // Toplu Durum Değiştirme
  t_massChangeStatuses: "Массовое изменение статусов",
  // Metin Rengi
  t_textColour: "Цвет текста",
  // Ses Dosyası
  t_audioFile: "Аудиофайл",
  // Tür
  t_type: "Тип",
  // Konfeti
  t_confetti: "Конфетти",
  // Şampanya
  t_champagne: "Шампанское",
  // Acil
  t_emergency: "Срочно",
  // Yanıp - Sönme
  t_blink: "Мигание",
  // Wallboard Kontrol
  t_wallboardControl: "Управление стендом",
  // Yazılı Sufle
  t_writtenSouffle: "Письменный суфле",
  // Dinle
  t_listen: "Слушать",
  // Alkış1
  t_clap1: "Аплодисменты1",
  // Alkış2
  t_clap2: "Аплодисменты2",
  // İkaz
  t_warning: "Предупреждение",
  // Toplu Durum Değişimi
  t_changeAllStatuses: "Массовое изменение статусов",
  // Eve Gittileri Göster
  t_showWentHome: "Показать ушедших домой",
  // Eve Gittileri Gizle
  t_hideWentHome: "Скрыть ушедших домой",
  // Geri Arananları Göster
  t_showCalledBack: "Показать перезвоненных",
  // Mola Aşımı
  t_reakTimeFraud: "Превышение перерыва",
  // Kategori
  t_finishCodeCategory: "Категория",
  // Kategori Adı
  t_categoryName: "Название категории",
  // Sonuç Kategorileri
  t_finishCodeCategorys: "Категории результатов",
  // Görünen Ad
  t_finishCodeName: "Отображаемое имя",
  // Sonuç Kodları
  t_finishCodes: "Коды результатов",
  // Tarih Sor
  t_askForActionDate: "Спросить дату действия",
  // Hatırlat
  t_saveToCalendar: "Напомнить",
  // Kontak Aranabilir
  t_contactCallableAfterwards: "Контакт доступен для звонка",
  // Telefon Aranabilir
  t_contactNumberCallableAfterwards: "Телефон доступен для звонка",
  // Temas Var
  t_countAsCallAttempt: "Контакт есть",
  // Son Sonuç Kodu
  t_lastFinishCode: "Последний код результата",
  // Telefonu Sil
  t_deleteContactNumber: "Удалить номер телефона",
  // Atama Yap
  t_assignToAgent: "Назначить агенту",
  // Gelen Yönlendirmeler
  t_inboundRoutes: "Входящие маршруты",
  // Standart Yönlendirme
  t_standardRoute: "Стандартный маршрут",
  // Hat Grubu
  t_lineGroup: "Группа линий",
  // Hat Grubu Adı
  t_lineGroupName: "Название группы линий",
  // Hat Grupları
  t_lineGroups: "Группы линий",
  // Eklenen Hatlar
  t_addedLines: "Добавленные линии",
  // Hat Listesi
  t_lineList: "Список линий",
  // Hatları İçeri Aktar
  t_importLines: "Импортировать линии",
  // Hat Adı
  t_lineName: "Название линии",
  // Çıkış Numarası
  t_outboundCID: "Исходящий номер",
  // Dış Yönlendirme Numarası
  t_transferCID: "Номер внешней маршрутизации",
  // Çevrimdışı Olanları Gizle
  t_hide_ofline_users: "Скрыть офлайн пользователей",
  // IP
  t_ip: "IP",
  // Transport Tipi
  t_transportType: "Тип транспорта",
  // Tipi
  t_type: "Тип",
  // Prefix
  t_prefix: "Префикс",
  // Gelen Çağrı Limiti
  t_inboundCallLimit: "Лимит входящих вызовов",
  // Giden Çağrı Limiti
  t_outboundCallLimit: "Лимит исходящих вызовов",
  // Port
  t_port: "Порт",
  // Codec
  t_codec: "Кодек",
  // Dosya Yükleme
  t_fileUpload: "Загрузка файла",
  // Dosya
  t_file: "Файл",
  // Yükleme yapmak istediğiniz dosyayı seçiniz
  t_selectTheFileYouWantToUpload: "Выберите файл для загрузки",
  // Lütfen hat grubu adı girin.
  t_pleaseEnterTheLineGroupName: "Пожалуйста, введите название группы линий.",
  // Hat grubu oluşturabilmeniz için en az 1 tane hat seçmeniz gerekmektedir.
  t_youNeedToPickALineToBeAbleToCreateLineGroup: "Для создания группы линий необходимо выбрать хотя бы одну линию.",
  // Hat grubu başarılı bir şekilde oluşturuldu.
  t_lineGroupHasBeenCreatedSuccessfully: "Группа линий успешно создана.",
  // Kampanya Yönetimi
  t_campaignManagement: "Управление кампаниями",
  // Arama İşlemleri
  t_campaignControl: "Операции вызовов",
  // Durdur
  t_stop: "Остановить",
  // Recycle
  t_recycle: "Переработать",
  // Çağrı
  t_calls: "Вызовы",
  // Temel
  t_basic: "Основной",
  // Zamanı Gelmeyen
  t_scheduledCalls: "Не наступившие",
  // Arama Listesi
  t_contactList: "Список вызовов",
  // Programlama
  t_programming: "Программирование",
  // Öncelik
  t_priority: "Приоритет",
  // Maksimum Çağrı
  t_maxCall: "Максимальный вызов",
  // Temsilci Başına Çağrı
  t_callsPerAgent: "Вызовов на агента",
  // İstatistik Periyot (dk)
  t_statisticsPeriod: "Период статистики (мин)",
  // Çıkış Methodu
  t_callingMethod: "Метод вызова",
  // Cevapsız Süre Aşımı (sn)
  t_noAnswerTimeout: "Тайм-аут без ответа (сек)",
  // Maksimum Kaçma Oranı (%)
  t_maxAbandonRate: "Максимальный процент пропущенных (%)",
  // Makine Cevaplarını Bağlama
  t_dontConnectMachines: "Не подключать автоответчики",
  // Arama Sıralaması
  t_sortCallList: "Сортировка списка вызовов",
  // Maksimum Döngü
  t_maxCycles: "Максимальный цикл",
  // Sonraki Arama Süresi (dk)
  t_recallDelayMins: "Время до следующего вызова (мин)",
  // Çağrı Sonrası
  t_afterCall: "После вызова",
  // Çağrı Sonrası Statü
  t_afterCallStatus: "Статус после вызова",
  // Max. Temas
  t_maxAttempts: "Макс. контактов",
  // Bugün gerçekleşen çağrı istatistikleri
  t_callstatisticsfortoday: "Статистика вызовов за сегодня",
  // Bugün gerçekleşen durum süreleri
  t_statusdurationfortoday: "Длительность статусов за сегодня",
  // Kuyruktan kaçan çağrıların erişebileceği maks. oran
  t_maximumRateOfCallsThatAreAbandonedFromTheQueue: "Максимальный процент пропущенных вызовов из очереди",
  // Kampanyanın anlık çıkabileceği maksimum çağrı sayısı
  t_maximumAmountOfCallsThisCampaignCanStart: "Максимальное количество вызовов, которые может запустить кампания",
  // Temsilci başına çıkılacak çağrı sayısı
  t_callsPerAvailableAgents: "Количество вызовов на доступного агента",
  // Kampanyaya ait grafiğin yenilenme sıklığı
  t_refreshRateOfThisCampaignsStatisticsGraphs: "Частота обновления графиков статистики кампании",
  // Kampanyanın çağrı çıkacağı hat grubu
  t_whichLineGroupToMakeCallsFrom: "Группа линий для вызовов кампании",
  // Çıkılan çağrının karşı tarafta maksimum çalma süresi
  t_maximumRingingTime: "Максимальное время звонка",
  // Arama listesinde tamamlandığında, temas kurulamayanlara için tekrar başlatılacak döngü sayısı
  t_amountOfRoundsToCallPeopleWhoHaventBeenContactedYet: "Количество циклов для повторного вызова тех, с кем не удалось связаться",
  // Arama yapılan bir numaraya, minumum kaç dakika sonra tekar aranacağı süre
  t_minimumAmountOfMinutesToRecallAPhoneNumber: "Минимальное время до повторного вызова номера",
  // Telefona bir günde yapılacak maksimum çağrı sayısı
  t_maximumCallAmountPerPhoneNumberPerDay: "Максимальное количество вызовов на номер в день",
  // Kontağa bir günde yapılacak maksimum çağrı sayısı
  t_maximumCallAmountPerContactPerDay: "Максимальное количество вызовов на контакт в день",
  // Telefona toplam yapılacak maksimum çağrı sayısı
  t_maximumCallAmountPerPhoneNumber: "Максимальное количество вызовов на номер",
  // Kontağa toplam yapılacak maksimum çağrı sayısı
  t_maximumCallAmountPerContact: "Максимальное количество вызовов на контакт",
  // Cevaplan çağrı makine çağrısı ise temsilciye bağlama
  t_doNotConnectMachineAnsweredCalls: "Не подключать вызовы, ответившие автоответчиком",
  // Günlük Max. Temas Telefon
  t_dailyMaxPhoneTouch: "Максимальное количество контактов с телефоном в день",
  // Günlük Max. Temas Kontak
  t_dailyMaxContactTouch: "Максимальное количество контактов с контактом в день",
  // Toplam Max. Temas Telefon
  t_totalMaxPhoneTouch: "Общее максимальное количество контактов с телефоном",
  // Toplam Max. Temas Kontak
  t_totalMaxContactTouch: "Общее максимальное количество контактов с контактом",
  // Sadece GSM ara
  t_justCallMobilePhone: "Звонить только на мобильные",
  // Kaçan
  t_abandoned: "Пропущенные",
  // Zaman Koşulu
  t_timeCondition: "Условие времени",
  // IVR Aktar
  t_transferIVR: "Передача IVR",
  // Canlı Kontak IVR
  t_liveContacttIVR: "Живой контакт IVR",
  // Canlı Kontak
  t_liveContact: "Живой контакт",
  // Uygun Temsilci Yok
  t_noAvailableAgent: "Нет доступных агентов",
  // Uygun Temsilci Yok IVR
  t_noAvailableAgentIVR: "Нет доступных агентов IVR",
  // Kural
  t_rule: "Правило",
  // Durum
  t_campaignStatus: "Статус кампании",
  // Öncelik
  t_campaignPriority: "Приоритет кампании",
  // Filtre Kriteri
  t_campaignFilterCriteria: "Критерий фильтрации кампании",
  // Sıralama Kriteri
  t_campaignSortingCriteria: "Критерий сортировки кампании",
  // Kalan Döngü
  t_campaignRemainingCycles: "Оставшиеся циклы",
  // Temsilci Başına Çağrı
  t_campaignCallPerAgent: "Вызовов на агента",
  // Toplam Aranacak Müşteri
  t_campaignTotalCustomersToCall: "Всего клиентов для вызова",
  // Toplam Aranacak Telefon
  t_campaignTotalPhonesToCall: "Всего телефонов для вызова",
  // Döngüde Bekleyen
  t_campaignRemainingPhonesInCycle: "Ожидающие в цикле",
  // Toplam Aranan
  t_campaignTotalCalled: "Всего вызвано",
  // Anlık Aranan
  t_campaignTotalPhonesCalled: "Текущие вызовы",
  // Bağlanan Çağrı
  t_campaignConnectedCall: "Подключенный вызов",
  // Bekleyen Çağrı
  t_campaignWaitingInQueue: "Ожидающие вызовы",
  // Kaçma Oranı %
  t_campaignAbandonRate: "Процент пропущенных %",
  // NoAnswer (Cevapsız)
  t_resultNoAnswer: "NoAnswer (Без ответа)",
  // Abandon (Kaçan)
  t_resultAbandon: "Abandon (Пропущенный)",
  // Busy (Meşgul)
  t_resultBusy: "Busy (Занято)",
  // CallRejected (Müşteri Red)
  t_resultCallRejected: "CallRejected (Отклонено клиентом)",
  // FaultyCall (Hatalı Arama)
  t_resultFaultyCall: "FaultyCall (Ошибочный вызов)",
  // NetworkOutOfOrder (Ağ Hatası)
  t_resultNetworkOutOfOrder: "NetworkOutOfOrder (Ошибка сети)",
  // FateUnknown (Bilinmiyor)
  t_resultFateUnknown: "FateUnknown (Неизвестно)",
  // ConnectivityError (Bağlantı Hatası)
  t_resultConnectivityError: "ConnectivityError (Ошибка подключения)",
  // FaultyNumber (Hatalı Numara)
  t_resultFaultyNumber: "FaultyNumber (Ошибочный номер)",
  // Sonuç Kodları Dağılımı
  t_finishCodeStatistics: "Распределение кодов результатов",
  // Durum
  t_customersStatus: "Статус",
  // Agent
  t_assigned_agent: "Агент",
  // Temas
  t_attempts: "Контакты",
  // Arama Planı
  t_dial_plan: "План вызовов",
  // Rehber
  t_phone_book: "Телефонная книга",
  // IVR
  t_ivr: "IVR",
  // Sonuç Kodu
  t_finish_code: "Код результата",
  // Arama T.
  t_insert_date: "Дата вызова",
  // Aranacak T.
  t_action_date: "Дата для вызова",
  // Yüklenme T.
  t_create_date: "Дата загрузки",
  // Ex Agent
  t_ex_agent: "Бывший агент",
  // Atama T.
  t_assign_date: "Дата назначения",
  // Sıralama yapmak için lütfen tek seçim yapınız.
  t_pleaseMakeASelectionForSorting: "Пожалуйста, сделайте выбор для сортировки.",
  // Başlatıldı
  t_started: "Запущено",
  // Durduruldu
  t_stopped: "Остановлено",
  // Yenileniyor
  t_recycling: "Перерабатывается",
  // Tamamlandı
  t_isCompleted: "Завершено",
  // Ayarlar başarılı bir şekilde kaydedildi.
  t_settingsHasBeenSavedSuccessfully: "Настройки успешно сохранены.",
  // Başlıklar
  t_columns: "Заголовки",
  // Satır
  t_row: "Строка",
  // Sıralama
  t_sort: "Сортировка",
  // Algoritmik Otomatik Arama
  t_algorithmicDialer: "Алгоритмический автоматический вызов",
  // Temel Otomatik Arama
  t_basicDialer: "Базовый автоматический вызов",
  // Filtre Yönetimi
  t_filterManagement: "Управление фильтрами",
  // Kampanyaya ait filtre bulunamadı.
  t_noFiltersForThisCampaign: "Для кампании не найдено фильтров.",
  // Koşul
  t_condition: "Условие",
  // Eşittir
  t_equal: "Равно",
  // Eşit Değildir
  t_notEqual: "Не равно",
  // Küçüktür
  t_lessThan: "Меньше",
  // Büyüktür
  t_greaterThan: "Больше",
  // Küçük Eşittir
  t_lessThanOrEqual: "Меньше или равно",
  // Büyük Eşittir
  t_greaterThanOrEqual: "Больше или равно",
  // Başlık
  t_columnName: "Заголовок",
  // Durum
  t_columnNameStatus: "Статус",
  // Agent
  t_columnNameAssignedAgent: "Агент",
  // Temas
  t_columnNameAttempts: "Контакты",
  // Kampanya
  t_columnNameCampaign: "Кампания",
  // Son Sonuç Kodu
  t_columnNameFinishCode: "Последний код результата",
  // Arama T.
  t_columnNameInsertDate: "Дата вызова",
  // Aranacak T.
  t_columnNameActionDate: "Дата для вызова",
  // Yüklenme T.
  t_columnNameCreateDate: "Дата загрузки",
  // Ex Agent
  t_columnNameExAgent: "Бывший агент",
  // Filtre başarılı bir şekilde oluşturuldu.
  t_filterHasBeenCreatedSuccessfully: "Фильтр успешно создан.",
  // IVR
  t_IVR: "IVR",
  // IVR Adı
  t_IVRName: "Название IVR",
  // IVR Listesi
  t_IVRList: "Список IVR",
  // Tuşlama
  t_DTMFdigit: "Нажатие клавиши",
  // Anons Dosyası
  t_announcementFile: "Файл объявления",
  // Tuşlama Kabul Et
  t_DTMFAccept: "Принять нажатие клавиши",
  // Dahili
  t_extension: "Внутренний",
  // Transfer
  t_transfer: "Передача",
  // Dil
  t_language: "Язык",
  // Zaman Aşım Süresi (Sn)
  t_timeOutLengthSec: "Тайм-аут (сек)",
  // Zaman Aşım Adedi
  t_timeOutCount: "Количество тайм-аутов",
  // Yanlış Tuşlama Adedi
  t_maxInvalidInputCount: "Количество неправильных нажатий",
  // Dahili Tuşlama Kabul Et
  t_acceptDTMFExtension: "Принять внутреннее нажатие клавиши",
  // Türkçe
  t_turkish: "Турецкий",
  // İngilizce
  t_english: "Английский",
  // Arapça
  t_arabic: "Арабский",
  // Rusça
  t_russian: "Русский",
  // Sonlandır
  t_hangup: "Завершить",
  // Reddet
  t_hangupCall: "Отклонить",
  // Gelen Arama
  t_incomingCall: "Входящий вызов",
  // Cevapla
  t_answer: "Ответить",
  // Menü
  t_menu: "Меню",
  // Eşleşen:
  t_matched: "Совпадающие:",
  // Eşleşmeyen:
  t_unmatched: "Не совпадающие:",
  // Başarılı bir şekilde silindi.
  t_hasBeenDeletedSuccessfully: "Успешно удалено.",
  // Lütfen boş alan bırakmayınız.
  t_pleaseDoNotLeaveAnyBlankFields: "Пожалуйста, не оставляйте пустые поля.",
  // Başarılı bir şekilde güncellendi.
  t_hasBeenUpdatedSuccessfully: "Успешно обновлено.",
  // İşlem başarılı bir şekilde gerçekleşti.
  t_hasBeenCreatedSuccessfully: "Операция успешно выполнена.",
  // Lütfen engelleme yapmak istediğiniz yönü seçin.
  t_pleaseSelectTheBlackListDirection: "Пожалуйста, выберите направление блокировки.",
  // Rehber
  t_phoneBook: "Телефонная книга",
  // Açıklama
  t_description: "Описание",
  // Profiller
  t_profiles: "Профили",
  // Profil Adı
  t_profileName: "Название профиля",
  // Etkilenen Nesneler
  t_affectedObjects: "Затронутые объекты",
  // Yetkilendirmeler
  t_permissions: "Разрешения",
  // Kullanıcı
  t_user: "Пользователь",
  // Sıfırla
  t_reset: "Сбросить",
  // Hiçbir yetki seçilmedi.
  t_noPermissionsSelected: "Не выбрано ни одного разрешения.",
  // Profil Listesi
  t_profileList: "Список профилей",
  // Mesajlaşma
  t_chat: "Чат",
  // Sufle
  t_souffle: "Суфле",
  // profili başarılı bir şekilde silindi.
  t_profileHasBeenDeletedSuccessfully: "Профиль успешно удален.",
  // profili başarılı bir şekilde güncellendi.
  t_profileHasBeenUpdatedSuccessfully: "Профиль успешно обновлен.",
  // Profil başarılı bir şekilde oluşturuldu.
  t_profileHasBeenCreatedSuccessfully: "Профиль успешно создан.",
  // Listede Göster
  t_is_visible_in_live_dasboard: "Показать в списке",
  // Proje Adı
  t_projectName: "Название проекта",
  // Çağrı Sonrası Süresi (sn)
  t_afterCallWork: "Время после вызова (сек)",
  // Kamera Kaydı Al
  t_cameraRecord: "Запись с камеры",
  // Kullanılabilir Mola Süresi (dk)
  t_useableBreakTime: "Доступное время перерыва (мин)",
  // Mola Süresi
  t_breakTime: "Время перерыва",
  // Soyad
  t_surname: "Фамилия",
  // Adı Soyadı
  t_nameSurname: "Имя Фамилия",
  // Proje Listesi
  t_projectList: "Список проектов",
  // projesi başarılı bir şekilde silindi.
  t_projectHasBeenDeletedSuccessfully: "Проект успешно удален.",
  // projesi başarılı bir şekilde güncellendi.
  t_projectHasBeenUpdatedSuccessfully: "Проект успешно обновлен.",
  // Proje başarılı bir şekilde oluşturuldu.
  t_projectHasBeenCreatedSuccessfully: "Проект успешно создан.",
  // Kuyruk Numarası
  t_queueNumber: "Номер очереди",
  // Temsilci Zaman Aşımı (sn)
  t_queueAgentTimeout: "Тайм-аут агента (сек)",
  // Çağrının, temsilcide bekleyeceği maksimum süre
  t_queueAgentTimeoutPlaceholder: "Максимальное время ожидания вызова агентом",
  // Anons Sıklığı (sn)
  t_queueAnnounceFrequency: "Частота объявлений (сек)",
  // Anonslar arası geçen süre
  t_timeBetweenAnnouncements: "Время между объявлениями",
  // Pozisyon Anonsu
  t_queueAnnouncePosition: "Объявление позиции",
  // Maksimum Uzunluk
  t_queueMaxlen: "Максимальная длина",
  // Penaltı Üye Limiti
  t_queuePenaltyMembersLimit: "Лимит участников с штрафом",
  // Yeniden Deneme
  t_queueRetry: "Повторная попытка",
  // Bekleme Müziği
  t_queueMusicOnHold: "Музыка на удержании",
  // Kuyruk Adı
  t_queueName: "Название очереди",
  // Arama Modu
  t_queueCallingMode: "Режим вызова",
  // Kuyruk Stratejisi
  t_queueStrategy: "Стратегия очереди",
  // Çağrıların, temsilciler arasında dağıtım stratejisi
  t_queueStrategyPlaceholder: "Стратегия распределения вызовов между агентами",
  // Çağrı Sonrası Süre (sn)
  t_queueWrapupTime: "Время после вызова (сек)",
  // Çağrıdan sonra, temsilcilerin çağrısız kalabileceği minimum süre
  t_queueWrapupTimePlaceholder: "Минимальное время без вызовов после вызова",
  // Bekleme Süresi Anonsu
  t_queueAnnounceHoldTime: "Объявление времени ожидания",
  // Otomatik Bekleme Süresi
  t_queueQueueHoldTime: "Автоматическое время ожидания",
  // Üye Gecikmesi
  t_queueMemberDelay: "Задержка участника",
  // Periyodik Anons Frekansı
  t_queuePeriodicAnnounceFrequency: "Периодическая частота объявлений",
  // Servis Seviyesi
  t_queueServiceLevel: "Уровень обслуживания",
  // Ağırlık
  t_queueWeight: "Вес",
  // Kuyruk Listesi
  t_queueList: "Список очередей",
  // Sıralı (Önerilen)
  t_queueRrmemory: "Последовательный (рекомендуется)",
  // Herkes Aynı Anda
  t_queueRingall: "Все одновременно",
  // En Erken Aranan Önce
  t_queueLeastrecent: "Самый ранний вызов первым",
  // Temsilci Önceliği
  t_queueAgentWeight: "Приоритет агента",
  // En Az Çağrı Almış Önce
  t_queueFewestcalls: "С наименьшим количеством вызовов первым",
  // Rastgele
  t_queueRandom: "Случайный",
  // Manuel
  t_queueManual: "Ручной",
  // Dialer
  t_queueDialer: "Автоматический вызов",
  // Servis Yönetimi
  t_serviceManagement: "Управление сервисами",
  // Oto. Arama Servisi
  t_dialerService: "Сервис автоматического вызова",
  // Yeniden Başlat
  t_restart: "Перезапустить",
  // Çağrı Yönetim Servisi
  t_callManager: "Сервис управления вызовами",
  // Mesajlaşma Servisi
  t_chatService: "Сервис чата",
  // SMS Gönderim
  t_smsSending: "Отправка SMS",
  // Şablon
  t_smsTemplate: "Шаблон",
  // Filtre
  t_smsFilter: "Фильтр",
  // Filtre Adı
  t_filterName: "Название фильтра",
  // Herhangi bir filtre seçilmedi.
  t_noFilterHasBeenSelected: "Не выбрано ни одного фильтра.",
  // Filtre Listesi
  t_filterList: "Список фильтров",
  // Sayfa Adı
  t_smsPageTitle: "Название страницы",
  // Sistemden Gönderim
  t_sendFromVoyceCRM: "Отправка из системы",
  // Dış Kaynaktan Gönderim
  t_sendFromExternalCRM: "Отправка из внешнего источника",
  // Özel Gönderim
  t_sendFromCustomSms: "Пользовательская отправка",
  // Mesaj Şablonu
  t_smsPattern: "Шаблон сообщения",
  // Önizleme
  t_preview: "Предпросмотр",
  // Örnek Mesaj Metni
  t_exampleText: "Пример текста сообщения",
  // Telefon Numaraları
  t_phoneNumbers: "Номера телефонов",
  // Telefon numaralarını virgül ile ayırarak giriniz.
  t_enterPhoneNumbersSeparatedByCommas: "Введите номера телефонов, разделенные запятыми.",
  // Mail adreslerini virgül ile ayırarak giriniz.
  t_enterEmailsSeparatedByCommas: "Введите адреса электронной почты, разделенные запятыми.",
  // Lütfen en az bir telefon numarası giriniz.
  t_pleaseEnterAtLeastOnePhoneNumber: "Пожалуйста, введите хотя бы один номер телефона.",
  // Lütfen bir mesaj giriniz.
  t_pleaseEnterAMessage: "Пожалуйста, введите сообщение.",
  // Mesajlar başarılı bir şekilde iletilmiştir.
  t_messagesHaveBeenDeliveredSuccessfully: "Сообщения успешно доставлены.",
  // Mesajlarınız iletilirken bir sorun oluştu.
  t_thereWasAProblemDeliveringYourMessages: "Возникла проблема при доставке ваших сообщений.",
  // Görünen Ad
  t_displayName: "Отображаемое имя",
  // Gönderici Numarası
  t_senderNumber: "Номер отправителя",
  // Hesap Kodu
  t_accountCode: "Код счета",
  // API Anahtarı
  t_apiKey: "API ключ",
  // SMS Ayar Listesi
  t_smsSettingList: "Список настроек SMS",
  // Şablon
  t_pattern: "Шаблон",
  // Şablon Adı
  t_patternName: "Название шаблона",
  // Mesaj Metni
  t_smsText: "Текст сообщения",
  // Şablon Listesi
  t_patternList: "Список шаблонов",
  // Çağrı Merkezi
  t_callCenter: "Колл-центр",
  // İstatistikler
  t_statistics: "Статистика",
  // Kaçan
  t_missedCall: "Пропущенный вызов",
  // Yemek
  t_lunch: "Обед",
  // Mola
  t_break: "Перерыв",
  // Gelen Ortalama
  t_AVGInboundLengt: "Средняя длина входящего",
  // Giden Ortalama
  t_AVGOutboundLengt: "Средняя длина исходящего",
  // Gelen Toplam
  t_totalInbound: "Всего входящих",
  // Giden Toplam
  t_totalOutbound: "Всего исходящих",
  // Kalan Mola
  t_remainingBreakTime: "Оставшееся время перерыва",
  // Durum Süreleri
  t_statusTime: "Время статусов",
  // Aksiyon Adetleri
  t_totalActionCounts: "Количество действий",
  // Kuyrukta Bekleyenler
  t_waitingInQueue: "Ожидающие в очереди",
  // Anlık kuyrukta bekleyen sayısı
  t_numberofwaitingininstantqueue: "Количество ожидающих в текущей очереди",
  // Bugün gerçekleşen tüm çağrı kayıtları
  t_allcallrecordsfortoday: "Все записи вызовов за сегодня",
  // Hedef
  t_collectionTarget: "Цель",
  // Gerçekleşen
  t_collectedMade: "Выполнено",
  // Çalışma Günü
  t_workingDay: "Рабочий день",
  // Devamlılık
  t_present: "Присутствие",
  // Devamsızlık
  t_absent: "Отсутствие",
  // Aksiyon
  t_actionDate: "Дата действия",
  // Çağrıda (Giden)
  t_inCallOutbound: "В вызове (исходящий)",
  // Çağrıda (Gelen)
  t_inCallInbound: "В вызове (входящий)",
  // Çalıyor
  t_ringing: "Звонок",
  // Cevapsız
  t_noAnswer: "Без ответа",
  // Ödeme Sözü
  t_promiseToPay: "Обещание оплаты",
  // Ödeme Red
  t_refuseToPay: "Отказ от оплаты",
  // Not Bırakıldı
  t_messageLeft: "Оставлено сообщение",
  // Meşgul
  t_busy: "Занято",
  // Durum Kategorisi
  t_statusCategory: "Категория статуса",
  // Durum Kategorileri
  t_statusCategorys: "Категории статусов",
  // Durum Adı
  t_statusName: "Название статуса",
  // Kategori Anahtarı
  t_categoryKey: "Ключ категории",
  // Kategoriler
  t_categories: "Категории",
  // Değiştirilebilir
  t_changeable: "Изменяемый",
  // Supervisor Değiştirebilir
  t_onlySPVCanChange: "Только супервизор может изменить",
  // Aktif
  t_active: "Активный",
  // Durum Listesi
  t_statusList: "Список статусов",
  // Çevrimiçi
  t_online: "Онлайн",
  // Çevrimdışı
  t_offline: "Оффлайн",
  // Diğer
  t_other: "Другое",
  // Önemli
  t_important: "Важно",
  // Randevu
  t_apointment: "Назначение",
  // Hatırlatma
  t_reminder: "Напоминание",
  // Kişisel
  t_personal: "Личный",
  // Kişisel
  t_personaly: "Личный",
  // Zaman Koşulu Adı
  t_timeConditionName: "Название условия времени",
  // Başlangıç Günü
  t_startDay: "День начала",
  // Başlangıç Zamanı
  t_startTime: "Время начала",
  // Ayın Başlangıç Günü
  t_startingDayOfMonth: "День начала месяца",
  // Ay Başlangıcı
  t_monthStart: "Начало месяца",
  // Ay Başlangıç Günü
  t_monthStartDay: "День начала месяца",
  // Ay Bitiş Günü
  t_monthEndDay: "День окончания месяца",
  // Ay Bitişi
  t_monthEnd: "Окончание месяца",
  // Bitiş Günü
  t_endDay: "День окончания",
  // Bitiş Zamanı
  t_endTime: "Время окончания",
  // Ayın Bitiş Günü
  t_endingDayOfMonth: "День окончания месяца",
  // Zaman Koşulları
  t_timeConditions: "Условия времени",
  // Pazartesi
  t_monday: "Понедельник",
  // Salı
  t_tuesday: "Вторник",
  // Çarşamba
  t_wednesday: "Среда",
  // Perşembe
  t_thursday: "Четверг",
  // Cuma
  t_friday: "Пятница",
  // Cumartesi
  t_saturday: "Суббота",
  // Pazar
  t_sunday: "Воскресенье",
  // Ocak
  t_january: "Январь",
  // Şubat
  t_february: "Февраль",
  // Mart
  t_march: "Март",
  // Nisan
  t_april: "Апрель",
  // Mayıs
  t_may: "Май",
  // Haziran
  t_june: "Июнь",
  // Temmuz
  t_july: "Июль",
  // Ağustos
  t_august: "Август",
  // Eylül
  t_september: "Сентябрь",
  // Ekim
  t_october: "Октябрь",
  // Kasım
  t_novenber: "Ноябрь",
  // Aralık
  t_december: "Декабрь",
  // Başlangıç koşulu, bitiş koşulundan büyük olamaz.
  t_theBeginingConditionCanNotBeGraterThanEndingCondition: "Начальное условие не может быть больше конечного.",
  // Kişisel Bilgiler
  t_userInformations: "Личная информация",
  // İçeri Aktar
  t_import: "Импорт",
  // İçeri Aktar
  t_importUsers: "Импорт пользователей",
  // İçeri Aktar
  t_importRecords: "Импорт записей",
  // Kullanıcı Listesi
  t_userList: "Список пользователей",
  // Tenant Adı
  t_tenantName: "Название арендатора",
  // Ses Export
  t_recordingExport: "Экспорт записи",
  // Ses Silme
  t_recordingRemove: "Удаление записи",
  // Filtreler
  t_filters: "Фильтры",
  // Etiketler
  t_tags: "Теги",
  // İsim Formatı
  t_nameType: "Формат имени",
  // E-Posta adresleri arasına virgül koyarak birden fazla e-posta adresi yazabilirsiniz
  t_youCanAddCommaBetweenEmailAddressesToAddMore: "Вы можете добавить несколько адресов электронной почты, разделенных запятыми.",
  // Telfonlar arasına virgül koyarak birden fazla telefon numarası yazabilirsiniz
  t_youCanAddCommaBetweenPhoneToAddMore: "Вы можете добавить несколько номеров телефонов, разделенных запятыми.",
  // Başlangıç Tarihi
  t_startDate: "Дата начала",
  // Bitiş Tarihi
  t_endDate: "Дата окончания",
  // İsim Formatı
  t_namePattern: "Формат имени",
  // Hedef E-Mail
  t_targetMail: "Целевой email",
  // Dosyalar hazırlandığında mail adresinize otomatik olarak gönderilecektir.
  t_whenTheFilesAreReadyYouWillbeSentAnEMail: "Когда файлы будут готовы, они будут автоматически отправлены на ваш email.",
  // Dosya Formatı
  t_fileType: "Формат файла",
  // İşlem başlatılmıştır, tamamlandığında mail olarak iletilecektir.
  t_theProcessHasBeenStartedItWillBeSentByEmailWhenItIsCompleted: "Процесс запущен, по завершении будет отправлено письмо.",
  // İşlem başlatılırken bir sorun oluştu.
  t_thereWasAProblemStartingTheProcess: "Возникла проблема при запуске процесса.",
  // İşlem
  t_process: "Процесс",
  // Müşteri Kapama
  t_deactiveCustomer: "Деактивация клиента",
  // Müşteri Açma
  t_activeCustomer: "Активация клиента",
  // Yönetici Atama
  t_assigneManager: "Назначение менеджера",
  // Hitam Etme
  t_conclude: "Завершение",
  // Not Ekleme
  t_addingNote: "Добавление заметки",
  // Ses Kaydı Silme
  t_deleteCallRecord: "Удаление записи звонка",
  // Eşleştirilecek Sütun
  t_matchingColumn: "Сопоставляемый столбец",
  // Lütfen bilgileri eksiksiz giriniz.
  t_pleaseFillTheAllNecessaryAreas: "Пожалуйста, заполните все необходимые поля.",
  // Atama yapılırken bir sorun oluştu.
  t_thereWasAProblemWhileAssigning: "Возникла проблема при назначении.",
  // ile eşleşen kayıt yok.
  t_doesNotMatchAnyCustomers: "Нет совпадающих записей.",
  // Eşleştirelecek sütun ismi, excel dosyasındaki sütun ismi ile aynı olmalıdır.
  t_matchingColumnMustBeTheSameInTheExcelColumn: "Имя сопоставляемого столбца должно совпадать с именем столбца в Excel.",
  // İşlem yapılırken bir sorun oluştu.
  t_AProblemOccurredwhileProcessing: "Возникла проблема при обработке.",
  // adet kayıt başarılı bir şekilde güncellendi.
  t_customersHaveBeenSuccessfullyUpdated: "записей успешно обновлено.",
  // Kayıt başarılı bir şekilde silindi.
  t_customersHaveBeenSuccessfullyDeleted: "Запись успешно удалена.",
  // İşlem Başarılı
  t_processSuccess: "Процесс успешен",
  // Atama Yap
  t_assigne: "Назначить",
  // Yönetici
  t_manager: "Менеджер",
  // Yönetici atama yapılırken bir sorun oluştu.
  t_thereWasAProblemWhileAssigningManager: "Возникла проблема при назначении менеджера.",
  // Yönetici atama başarılı bir şekilde yapıldı.
  t_managerHaveSuccessfullyBeenAssigned: "Менеджер успешно назначен.",
  // adet kapama başarılı bir şekilde yapıldı.
  t_deactiveCustomersHaveSuccessfullyBeenDone: "деактиваций успешно выполнено.",
  // Kapama Yap
  t_VSPRCloseCustomer: "Выполнить деактивацию",
  // Data Aç
  t_VSPROpenCustomer: "Активировать данные",
  // Hitam etme yapılırken bir sorun oluştu.
  t_aProblemOccurredWhileConcluding: "Возникла проблема при завершении.",
  // adet hitam etme başarılı bir şekilde yapıldı.
  t_customersHaveSuccessfullyBeenConcluded: "завершений успешно выполнено.",
  // Data açma yapılırken bir sorun oluştu.
  t_thereWasAProblemActivatingCustomers: "Возникла проблема при активации данных.",
  // adet data başarılı bir şekilde açıldı.
  t_customersHaveBeenSuccessfullyActivated: "данных успешно активировано.",
  // Ses Kayıtlarını Sil
  t_deleteCallRecordings: "Удалить записи звонков",
  // Dosya başarılı bir şekilde yüklendi.
  t_fileHasBeenImportedSuccessfully: "Файл успешно загружен.",
  // Anons Yap
  t_makeAnAnnouncement: "Сделать объявление",
  // Sufle Gönder
  t_sendASouffle: "Отправить суфле",
  // Konuşma Başlat
  t_startChat: "Начать чат",
  // Aç
  t_open: "Открыть",
  // Konuşmalar
  t_conversations: "Разговоры",
  // Anons Geçmişi
  t_announcementHistory: "История объявлений",
  // Top. Konuşma Süresi
  t_totalCallTime: "Общее время разговора",
  // Ort. Konuşma Süresi
  t_avgCallTime: "Среднее время разговора",
  // Çağrı Karşılama Hızı
  t_avgAnswerTime: "Среднее время ответа",
  // Ort. Hold Süresi
  t_avgHoldTime: "Среднее время удержания",
  // Ort. ACW Süresi
  t_avgAcwTime: "Среднее время ACW",
  // KPI
  t_totalCallTimeDescrition: "KPI",
  // KPI
  t_avgCallTimeDescrition: "KPI",
  // KPI
  t_avgAnswerTimeDescrition: "KPI",
  // KPI
  t_avgHoldTimeDescrition: "KPI",
  // KPI
  t_avgAcwTimeDescrition: "KPI",
  // Kuyrukta Bekleyen Çağrı Bulunmamaktadır
  t_noRecordOnQueue: "Нет ожидающих вызовов в очереди",
  // Çağrı Kayıtları
  t_callRecords: "Записи вызовов",
  // İkinci Hat
  t_secondLine: "Вторая линия",
  // Görünümü Değiştir
  t_changeMode: "Изменить вид",
  // Bağlı Olmayanları Göster
  t_showNotConnected: "Показать неподключенных",
  // Tam Ekran
  t_fullScreen: "Полный экран",
  // Anons Paneli
  t_announcementPanel: "Панель объявлений",
  // Mesajınız
  t_yourMessage: "Ваше сообщение",
  // Konuşma Başlat
  t_startAconversation: "Начать разговор",
  // size özel bir mesaj gönderdi.
  t_youGotAPrivateMessage: "вам отправили личное сообщение.",
  // Yeni Konuşma Başlatın
  t_startANewConversation: "Начать новый разговор",
  // Şifre Güncelleme
  t_updatePassword: "Обновление пароля",
  // Şifreniz en az 6 karakter uzunluğunda olup, 1 harf ve 1 rakam içermelidir.
  t_PasswordCondition: "Ваш пароль должен содержать не менее 6 символов, включая 1 букву и 1 цифру.",
  // Son Hesap Etkinliği
  t_lastAccountEvent: 'Последняя активность аккаунта',
  // Hareketler
  t_events: "Действия",
  // Şifre Değişikliği
  t_changePassword: 'Смена пароля',
  // Tekrar
  t_again: "Снова",
  // Mevcut Şifreniz
  t_currentPassword: "Текущий пароль",
  // Yeni Şifreniz
  t_newPassword: "Новый пароль",
  // Yeni Şifreniz (Tekrar)
  t_confirmPassword: "Новый пароль (повторите)",
  // Lütfen sonuç kodu seçiniz
  t_pleaseSelectfinishCode: "Пожалуйста, выберите код результата",
  // Anket
  t_survey: "Опрос",
  // Şifre en az 1 harf ve 1 rakam içermelidir.
  t_passwordmustContainAtLeast1LetterAnd1Number: "Пароль должен содержать не менее 1 буквы и 1 цифры.",
  // Girilen şifreler eşleşmiyor.
  t_enteredpasswordsDoNotMatch: "Введенные пароли не совпадают.",
  // Şifre en az 6 karakter uzunluğunda olmalıdır.
  t_passwordMustBeAtLeast6CharactersLong: "Пароль должен быть не менее 6 символов.",
  // Şifreniz, eski şifreniz ile aynı olamaz.
  t_YourPasswordCannotBeTheSameAsYourOldPassword: "Ваш пароль не может совпадать с предыдущим.",
  // Şifre başarılı bir şekilde değiştirildi.
  t_PasswordHasBeenchangedSuccessfully: "Пароль успешно изменен.",
  // Servise tekrar bağlandınız.
  t_youHaveReconnectedToTheService: "Вы снова подключены к сервису.",
  // Servis bağlantınız kesildi.
  t_yourServiceConnectionHasBeenLost: "Ваше соединение с сервисом потеряно.",
  // İşlem Yapılmadı
  t_ACWNothingSelected: "Действие не выполнено",
  // ACW Süre Aşımı
  t_ACWTimeOut: "Тайм-аут ACW",
  // Otomatik Arama Bağlanan Çağrı
  t_rdialerconnectedtoagent: "Автоматический вызов подключен к агенту",
  // Otomatik Arama Kaçan Çağrı
  t_rdialerabandon: "Автоматический вызов пропущен",
  // Renk
  t_color: "Цвет",
  // Görünüm
  t_view: "Вид",
  // Bütün Günler
  t_allDays: "Все дни",
  // Etkinlik Ekle
  t_addEvent: "Добавить событие",
  // Etkinlik Adı
  t_eventName: "Название события",
  // Etkinlik Notu
  t_eventNote: "Заметка события",
  // Etkinlik Tarihi
  t_eventDate: "Дата события",
  // Etkinlik Zamanı
  t_eventTime: "Время события",
  // CRM'de Görüntüle
  t_displayOnCRM: "Отображать в CRM",
  // Yıllık
  t_yearly: "Ежегодно",
  // Aylık
  t_monthly: "Ежемесячно",
  // Haftalık
  t_weekly: "Еженедельно",
  // Günlük
  t_daily: "Ежедневно",
  // Saatlik
  t_hourly: "Ежечасно",
  // Pzt
  t_mon: "Пн",
  // Sal
  t_tue: "Вт",
  // Çar
  t_wed: "Ср",
  // Per
  t_thu: "Чт",
  // Cum
  t_fri: "Пт",
  // Cmt
  t_sat: "Сб",
  // Pzr
  t_sun: "Вс",
  // Pzt - Cmt
  t_monSat: "Пн - Сб",
  // Pzr - Cmt
  t_sunSat: "Вс - Сб",
  // Pzt - Cm
  t_monFri: "Пн - Пт",
  // Pzt, Çrş, Cm
  t_monWedFri: "Пн, Ср, Пт",
  // Etkinlik başarılı bir şekilde oluşturuldu.
  t_eventhasBeenCreatedSuccessfully: "Событие успешно создано.",
  // Ortalama Kalite Puanı
  t_avaragequalitypoint: "Средний балл качества",
  // Canlı İzleme
  t_liveDashboard: "Живая панель",
  // Tetikleyiciler
  t_mAlerts: 'Триггеры',
  // Sistem Yönetimi
  t_systemManagement: "Управление системой",
  // Operasyon Yönetimi
  t_operationManagement: "Управление операциями",
  // Entegrasyonlar
  t_integrations: "Интеграции",
  // E-Mail
  t_eMail: "Электронная почта",
  // Sesli Mail
  t_qVoiceMail: "Голосовая почта",
  // Son Giriş
  t_lastLogin: "Последний вход",
  // Konuşma Kuralı
  t_speechRule: 'Правило речи',
  // Konuşma Kuralı Ekle
  t_addSpeechRule: 'Добавить правило речи',
  // Hatlar
  t_lines: "Линии",
  // Yönlendirmeler
  t_routes: "Маршруты",
  // Değişkenli IVR
  t_vIVR: "Переменный IVR",
  // Yedekleme
  t_backup: "Резервное копирование",
  // Sağlayıcılar
  t_providers: "Провайдеры",
  // Ses
  t_mVoice: "Голос",
  // Ulaşılan Arama
  t_reachedCalls: "Достигнутые вызовы",
  // Ulaşılamayan Arama
  t_unreachedCalls: "Недостигнутые вызовы",
  // Kaçan Çağrı
  t_missedCalls: "Пропущенные вызовы",
  // Giden Çağrı
  t_outcomingCall: "Исходящий вызов",
  // Gelen Çağrı
  t_inboundCall: "Входящий вызов",
  // Gelen Çağrı Ortalama
  t_incomingCallAvg: "Среднее входящих вызовов",
  // Giden Çağrı Ortalama
  t_outgoingCallAvg: "Среднее исходящих вызовов",
  // Göster
  t_show: "Показать",
  // kayıt
  t_entries: "записи",
  // Kayıt Ekle
  t_addRecord: "Добавить запись",
    t_showing: "Показано", // Gösteriliyor
  t_title: "Заголовок", // Ünvan
  t_clear: "Очистить", // Temizle
  t_perPage: "На страницу", // Sayfa başına
  t_uploadFileOnClick: "Нажмите, чтобы загрузить файл", // Dosya yüklemek için tıklayınız
  t_delete: "Удалить", // Sil
  t_noEditedRecord: "Нет записей для редактирования", // Düzenlenecek kayıt bulunamadı.
  t_noRecord: "Нет записей для отображения", // Gösterilecek kayıt yok
  t_errorFetchData: "Ошибка при получении данных", // Kayıt getirilirken bir sorun oluştu.
  t_backToList: "Вернуться к списку", // Listeye Geri Dön
  t_deleteRecord: 'Удалить запись', // Kaydı Sil
  t_updateRecord: 'Обновить запись', // Kaydı Güncelle
  t_titles: 'Заголовки', // Başlıklar
  t_searchPlaceHolder: 'Поиск по имени, фамилии, номеру клиента, номеру продукта, ТКН или телефону', // Ad Soyad, Müşteri No, Ürün No, TC veya Telefon ile arama yapın
  t_customerId: 'ID клиента', // Müşteri ID
  t_breakStatus: 'Статус перерыва', // Mola Durumu
  t_targetStatus: 'Целевой статус', // Hedef
  t_mrAgentAssign: 'Назначение агента', // Temsilci Karıştırma
  t_dataUpload: 'Загрузка данных', // Veri Yükleme
  t_customerList: 'Список клиентов', // Müşteri Listesi
  t_customerListByPhone: 'Список клиентов по телефону', // Müşteri Listesi Telefon
  t_customerListByProduct: 'Список клиентов по продукту', // Müşteri Listesi Ürün
  t_customerListByCSV: 'Список клиентов CSV', // Müşteri Listesi CSV
  t_vehicle: 'Транспортное средство', // Araç
  t_landRegistry: 'Земельный реестр', // Tapu
  t_SSK: 'ССК', // SSK
  t_mernis: 'Мернис', // Mernis
  t_gsmQuery: 'GSM Запрос', // GSM Sorgu
  t_negotiation: 'Переговоры', // Müzakere
  t_products: 'Продукты', // Ürünler
  t_phones: 'Телефоны', // Telefonlar
  t_hourlyGraphic: 'Почасовой график', // Saatlik Grafik
  t_deleteFilter: 'Удалить фильтр', // Filtreyi Sil
  t_addFilter: 'Добавить фильтр', // Filtre Ekle
  t_clearFilter: 'Очистить фильтр', // Filtreyi Temizle
  t_addNewRecord: 'Создать новую запись', // Yeni Kayıt Oluştur
  t_selectableLines: "Выбираемые линии", // Seçilebilir Hatlar
  t_selectedLines: "Используемые линии", // Kullanılan Hatlar
  t_restartService: 'Вы уверены, что хотите перезапустить службу?', // Servisi yeniden başlatmak istediğinizden emin misiniz?
  t_restartServiceSuccessfully: 'Служба успешно перезапущена', // Servis başarılı bir şekilde yeniden başlatıldı.
  t_id: 'ID', // Sıra
  t_goal: 'Цель', // Hedef
  t_goalManagementDescription: "Графическое представление", // Grafik görünümü
  t_smscount: ' сообщений будет отправлено. Вы подтверждаете?', // adet mesaj gönderilecektir. Onaylıyor musunuz?
  t_call: 'Позвонить', // Ara
  t_clearFilters: "Очистить фильтры",  // Filtereleri Temizle
  t_management: "Управление", // Yönetim
  t_customerInformation: "Информация о клиенте", // Müşteri Bilgileri
  t_customerUpdate: "Обновление информации о клиенте", // Müşteri Bilgilerini Güncelle
  t_extraInformation: "Дополнительная информация", // Extra Bilgiler
  t_hide: "Скрыть", // Gizle
  t_addAction: "Добавить действие", // Aksiyon Ekle
  t_addPhone: "Добавить телефон", // Telefon Ekle
  t_addNote: "Добавить заметку", // Not Ekle
  t_landLine: "Стационарный телефон", // Sabit
  t_addFinishCode: "Добавить код завершения", // Sonuç Kodu Ekle
  t_addProductAction: "Добавить действие по продукту", // Ürün Aksiyonu Ekle
  t_actionDate2: "Дата действия", // Aksiyon T.
  t_installmentCount: "Количество рассрочек", // Taksit Adedi
  t_installmentAmount: "Сумма рассрочки", // Taksit Tutarı
  t_relationTo: "Владелец", // Sahibi
  t_isApproved: "Одобрено", // Onaylı
  t_addData: "Добавить данные", // Veri Ekle
  t_sendSMS: "Отправить SMS", // SMS Gönder
  t_sendEmail: "Отправить Email", // Email Gönder
  t_filterTheResults: "Фильтровать результаты", // Sonuçları Filtreleyin
  t_filterBy: "Фильтровать по", // Filtrele
  t_pleaseWriteSomethingToSeeYourOptions: "Напишите что-нибудь, чтобы увидеть варианты.", // Seçeneklerini görmek için birşeyler yazın.
  t_columnVisibilityOptions: "Настройки видимости столбцов", // Sütun Gösterim Ayarları
  t_showHide: "(Показать/Скрыть)", // (Göster/Gizle)
  t_agentAssign: 'Назначение агента', // Temsilci Atama
  t_assigmnentType: "Тип назначения", // Atama Tipi
  t_contactPool: 'Пул контактов', // Havuz
  t_sender: "Отправитель", // Gönderen
  t_shuffle: "Перемешать", // Karıştırma
  t_assignForProductAgent: 'Назначить для агента по продукту', // Ürünler İçin Atama Yap
  t_progresingDate: "Дата обработки", // İşlem T.
  t_allUnassignedCasesWillBeAssignedToSelectedAgentsAreYouSure: "Все незакрепленные дела будут назначены выбранным агентам. Вы уверены?", // Boşta olan tüm dosyalar, seçilmiş olan temsilcilere atanacaktır. Onaylıyor musunuz?
  t_amountOfCasesWillBeUpdatedAreYouSure: "количество случаев будет обновлено. Вы уверены?", // kişi üzerinde değişiklik yapılacak. Onaylıyor musunuz?
  t_casesCouldNotBeAssigned: "количество случаев не удалось назначить.", // adet atama yapılırken bir sorun oluştu.
  t_customersHaveSuccessfullyBeenAssigned: "количество случаев успешно назначено.", // adet atama başarılı bir şekilde yapıldı.
  t_error: "Ошибка", // Hata
  t_failed: "Произошла ошибка", // Hata Oluştu
  t_reportDetails: 'Детали отчета', // Rapor Detayları
  t_summary: "Сводка", // Özet
  t_today: "Сегодня", // Bugün
  t_yesterday: "Вчера", // Dün
  t_last7Days: "Последние 7 дней", // Son 7 Gün
  t_last30Days: "Последние 30 дней", // Son 30 Gün
  t_betweenDates: "Между датами", // Tarih Aralığı
  t_reports: 'Отчеты', // Raporlar
  t_reportType: 'Тип отчета', // Rapor Türü
  t_callDetails: "Детали звонка", // Çağrı Detayları
  t_mrInboundCallDetails: "Детали входящих звонков", // Gelen Çağrı Detayları
  t_mrSL30Daily: "SL30 Ежедневно", // SL30 Günlük
  t_mrSL30Monthly: "SL30 Ежемесячно", // SL30 Aylık
  t_mrSL30Hourly: "SL30 Почасово", // SL30 Saatlik
  t_mrAbandonedCalls: "Пропущенные звонки", // Kaçan Çağrılar
  t_mrAfterWorkInboundCalls: "Входящие звонки вне рабочего времени", // Mesai Dışı Gelen Çağrılar
  t_mrTotalCalls: "Общее количество звонков", // Çağrı Adetleri
  t_extraTime: "Дополнительное время", // Ekstra Süre
  t_giveExtraTime: "Дать дополнительное время", // Ekstra Süre
  t_time: "Время (00:00:00)", // Süre (00:00:00)
  t_mrCallDurationDetails: "Детали продолжительности звонка", // Çağrı Süre Detayları
  t_mrCallDurationSummary: "Сводка по продолжительности звонка", // Çağrı Süre Özeti
  t_mrInboundCallDaily: "Ежедневное распределение входящих звонков", // Günlük Çağrı Dağılımı
  t_mrInboundCallHourly: "Почасовое распределение входящих звонков", // Saatlik Çağrı Dağılımı
  t_surveyDetails: "Детали опроса", // Anket Detayları
  t_mrAgent: "Агент", // Temsilci
  t_mrAgentActivity: "Сводка агента", // Temsilci Özet
  t_mrAgentCallCountsDaily: "Ежедневные звонки агента", // Temsilci Günbazlı
  t_mrAgentCallCountsByDate: "Звонки агента по дате", // Tarama Temsilci Bazlı
  t_mrAgentWork: "Работа агента", // Temsilci Çalışma
  t_mrAgentCalls: "Звонки агента", // Temsilci Arama
  t_mrAgentDaily: "Ежедневное распределение звонков агента", // Günlük Çağrı Dağılımı
  t_mrAgentHourly: "Почасовое распределение звонков агента", // Saatlik Çağrı Dağılımı
  t_mrAgentCalendar: "Детали календаря", // Takvim Detay
  t_mrIvnDialerCallAnalyze: "Анализ кампании", // Kampanya Analiz
  t_mrIvnDialerCallDetails: "Детали звонков кампании", // Kampanya Çağrı Detayları
  t_called: "Набрано", // Taranan
  t_closed: "Закрыто", // Kapanan
  t_backedupFiles: "Резервные файлы", // Yedeklenen Dosyalar
  t_pleaseSelectTheFileToUpload: "Пожалуйста, выберите файл для загрузки в базу данных", // Veri tabanına yüklenecek olan dosyayı seçiniz
  t_createBackup: "Создать резервную копию", // Yedek Oluştur
  t_exportToDatabase: "Экспорт в базу данных", // Veri Tabanına Aktar
  t_filter: "Фильтр", // Filtre
  t_settings: 'Настройки', // Ayarlar
  t_action: "Действие", // Aksiyon
  t_note: "Заметка", // Not
  t_column: "Столбец", // Başlık
  t_value: "Значение", // Değer
  t_operator: "Оператор", // Operatör
  t_users: "Пользователи", // Kullanıcılar
  t_agents: "Агенты", // Temsilciler
  t_date: "Дата", // Tarih
  t_agent: "Агент", // Temsilci
  t_finishCode: "Код завершения", // Sonuç Kodu
  t_direction: "Направление", // Yön
  t_defaultRoute: "Стандартный маршрут", // Standart Yönlendirme
  t_editSelected: "Редактировать выбранное", // Seçilenleri Güncelle
  t_crm: 'CRM', // CRM
  t_calendar: 'Календарь', // Takvim
  t_dialer: "Автодозвон", // Otomatik Arama
  t_goalManagement: 'Управление целями', // Hedef Yönetimi
  t_line: 'Линия', // Hat
  t_dialPlans: 'План звонков', // Arama Planı
  t_queues: 'Очереди', // Kuyruklar
  t_projects: 'Проекты', // Projeler
  t_statuses: 'Статусы', // Durumlar
  t_announce: 'Объявление', // Duyuru
  t_createScript: 'Создать сценарий', // Konuşma metni oluştur
  t_queueTimeOut: 'Тайм-аут очереди', // Kuyruk Zaman Aşımı
  t_detinationType: 'Тип назначения', // Hedef Tipi
  t_detinationTarget: 'Цель назначения', // Hedef
  t_showInDashboard: 'Показать на панели', // Panoda Göster
  t_reject: 'Отклонить', // Reddet
  t_internalCall: 'Внутренний звонок', // Dahili
  t_isProductFinishCode: 'Код завершения продукта', // Ürün Sonuç Kodu
  t_queueGroup: 'Группа очередей', // Kuyruk Grubu
  t_agentGroup: 'Группа агентов', // Temsilci Grubu
  t_selectableQueues: "Выбираемые очереди", // Seçilebilir Kuyruklar
  t_selectedQueues: "Используемые очереди", // Kullanılan Kuyruklar
  t_isHoldSound: 'Музыка на удержании', // Bekleme Müziği
  t_waitTime: 'Время ожидания', // Bekleme Süresi
  t_isReached: 'Телефон отвечен', // Telefon Açıldı
  t_addCustomer: 'Добавить клиента', // Müşteri Ekle
  t_phonesUpdate: "Обновить телефоны", // Telefon Düzenle
  t_phonesinfoUpdate: "Обновить информацию о телефонах", // Telefon Bilgilerini Güncelle
  t_isAutoEmail: 'Автоматическая отправка Email', // E-Mail Gönder
  t_emailTemplate: 'Шаблон Email', // E-Mail Şablonu
  t_account: 'Аккаунт', // Hesap
  t_security: 'Безопасность', // Güvenlik
  t_performance: 'Производительность', // Performans
  t_emailDelayAmount: 'Минуты', // Dakika
  t_isAutoSMS: 'Автоматическая отправка SMS', // SMS Gönder
  t_smsDelayAmount: 'Минуты', // Dakika
  t_autoAnswer: 'Автоответ', // Otomatik Yanıtla
  t_isUnassign: 'Снять назначение', // Atamayı Sil
  t_isPassive: 'Перевести в пассив', // Pasife Çek
  t_setAttemptsByPhone: 'Установить попытки по истории телефона', // Telefon Geçmişine Göre Temas Belirle
  t_recordingFile: 'Запись', // Kayıt
  t_callStrategy: 'Стратегия звонка', // Arama Stratejisi
  t_sortCriteria: 'Критерии сортировки', // Sıralama Kriteri
  t_internationalCall: 'Международный звонок', // Uluslararası Arama
  t_dialerPrecall: 'Предварительный звонок', // Dialer Precall
  t_variable: 'Переменная', // Değişken
  t_variableLength: 'Длина переменной', // Değişken Uzunluğу
  t_reportFiles: 'Файлы отчетов', // Rapor Dosyaları
  t_schedule: 'Запланировать', // Planla
  t_scheduler: 'Планировщик', // Planlayıcı
  t_schedulerTasks: 'Запланированные задачи', // Planlananlar
  t_schedulerCategory: 'Категория планирования', // Planlama Kategorisi
  t_reportCategory: 'Категория отчета', // Rapor Kategorisi
  t_lastXDays: 'Последние X дней', // Son X Gün
  t_communicationType: 'Тип коммуникации', // İletişim Türü
  t_communicationSrc: 'Источник коммуникации', // İletişim Kaynağı
  t_communicationTitle: 'Заголовок', // Başlıк
  t_to: 'Кому (To)', // Alıcı (To)
  t_cc: 'Копия (CC)', // Karbon Kopya (CC)
  t_youCanAddCommaBetweenEmailAddressesToaddMore: "Вы можете добавить несколько адресов электронной почты, разделяя их запятыми", // E-Posta adresleri arasına virgül koyarak birden fazla e-posta adresi yazabilirsiniz
  t_email: 'Электронная почта', // E-Posta
  t_mobileNotification: 'Мобильное уведомление', // Mobil Bildirim
  t_callPlaced: 'Звонок установлен', // Aranıyor
  t_callWentToAgent: 'На связи', // Görüşmede
  t_callJoinedQueue: 'В очереди', // Kuyrukta
  t_callPickedUp: 'Отвечен', // Cevaplandı
  t_callRejected: 'Отклонен', // Reddedildi
  t_schedulerTime: 'Время планирования', // Planlama Zamanı
  t_schedulerTimeString: 'Описание времени планирования', // Planlama Zamanı Açıklaması
  t_creator: 'Создатель', // Oluşturan
  t_nextRunTime: 'Следующее время выполнения', // Sonraki Çalışma Zamanı
  t_externalChat: 'Текстовый канал', // Yazılı Kanal
  t_start: "Начать", // Başlat
  t_selectAll: "Выбрать все", // Tümünü Seç
  t_clearAll: "Очистить все", // Tümünü Temizle
  t_queue: "Очередь", // Kuyruk
  t_announcement: "Объявление", // Anons
  t_duration: "Продолжительность", // Süre
  t_productivity: "Производительность", // Verimlilik
  t_status: "Статус", // Durum
  t_usedBreakTime: "Перерыв", // Mola
  t_answered: "Отвечено",  // Cevaplanan
  t_successfullCall: "Успешный звонок", // Ulaşılan
  t_unsuccessfullCall: "Неуспешный звонок", // Ulaşılamayan
  t_export: "Экспорт", // Dışarı Aktar
  t_total: "Всего", // Toplam
  t_rate: "Процент (%)", // Oran (%)
  t_send: "Отправить", // Gönder
  t_next: 'Далее', // İleri
  t_previous: 'Назад', // Geri
  t_classification: "Классификация", // Sınıflandırma
  t_available: "Доступно", // Uygun
  t_inCall: "На звонке", // Çağrıda
  t_paused: "На паузе",  // Molada
  t_profile: "Профиль", // Profil
  t_no: "Нет", // Hayır
  t_yes: "Да", // Evet
  t_report: 'Отчет', // Rapor
  t_actions: "Действия", // Aksiyonlar
  t_cancel: "Отмена", // İptal
  t_save: "Сохранить", // Kaydet
  t_category: "Категория", // Kategori
  t_project: "Проект", // Proje
  t_close: "Закрыть", // Kapat
  t_name: "Имя", // İsim
  t_sms: 'SMS', // SMS
  t_general: 'Общее', // Genel
  t_loginPageImages: 'Изображения страницы входа', // Giriş Ekranı Resimleri
  t_browse: 'Выбрать', // Seç
  t_thisMonth: 'Этот месяц', // Bu Ay
  t_lastMonth: 'Прошлый месяц', // Geçen Ay
  t_yourReportAddToReportQueue: 'Ваш отчет добавлен в очередь отчетов', // Raporunuz Rapor Kuyruğuna Eklendi
  t_notification: 'Уведомление', // Bildirim
  t_notifications: 'Уведомления', // Bildirimler
  t_pleaseSelectReport: 'Пожалуйста, выберите отчет', // Lütfen Rapor Seçiniz
  t_host: 'Хост', // Sunucu
  t_subject: 'Тема', // Konu
  t_isSendMailToCustomer: 'Отправить письмо клиенту', // Müşteriye Mail Gönder
  t_surveyCancel: 'Отменить опрос', // Anketi İptal Et
  t_surveyApprove: 'Подтвердить опрос', // Anketi Onayla
  t_sendType: 'Тип отправки', // Gönderim Türü
  t_info: 'Информация', // Bilgi
  t_pleaseSelectTimeCondition: 'Пожалуйста, выберите условие времени', // Lütfen Zaman Koşulu Seçiniz
  t_crmWhiteColorInfo: 'Не тронуто', // Dokunulmamış
  t_crmInfoColorInfo: 'Тронуто и доступно для звонка', // Dokunulmuş ve Aranabilir
  t_crmWarningColorInfo: 'Тронуто и недоступно для звонка', // Dokunulmuş ve Aranamaz
  t_crmSecondaryColorInfo: 'Тронуто и закрыто', // Dokunulmuş ve Kapatılan
  t_crmDangerColorInfo: 'Не тронуто и закрыто', // Dokunulmamış ve Kapatılan
  t_nextCustomer: 'Следующий клиент', // Sonraki Müşteri
  t_lineChangeInfo: 'Пожалуйста, повторно зарегистрируйте свои кампании, использующие соответствующую группу линий.', // Lütfen ilgili hat grubunu kullanan kampanyalarınız tekrar kayıt edin.
  t_deleteData: 'Удалить данные', // Data Silme
  t_closedByDailyAttempts: 'Закрыто по ежедневным попыткам', // Günlük Temasla Kapanan
  t_closedByTotalAttempts: 'Закрыто по общим попыткам', // Toplam Temasla Kapanan
  t_resetScheduledCalls: 'Сбросить запланированные звонки', // Zamanı Gelmeyen Çağrıları Sıfırla
  t_resetDailyAttempts: 'Открыть закрытые по ежедневным попыткам', // Günlük Temastan Kapananları Aç
  t_resetTotalAttempts: 'Открыть закрытые по общим попыткам', // Toplam Temastan Kapananları Aç
  t_detail: "Детали", // Detay
  t_spokenCall: 'Разговорный звонок', // Görüşülen
  t_spokenCallRate: 'Процент разговоров', // Görüşme%
  t_nonSpokenCall: 'Неразговорный звонок', // Görüşülemeyen
  t_nAttemptsCall: 'Без контакта', // Temassız
  t_attemptsRate: 'Процент контактов', // Temas%
  t_reachRate: 'Процент достижения', // Ulaşım%
  t_totalCallInfo: 'Общее количество сделанных звонков', // Arama yapılan toplam çağrı sayısı
  t_spokenCallInfo: 'Количество звонков, на которых говорил агент', // Temsilcinin konuştuğu çağrı sayısı
  t_spokenRateInfo: 'Процент звонков с контактом, которые были разговорными. Формула: Разговорные / (Общее - Без контакта)', // Temas olan çağrıların görüşme sayısına oranı. Formül : Görüşülen / Toplam-Temassız
  t_noSpokenCallInfo: 'Количество звонков с контактом, но без разговора. Пропущенные и занятые звонки', // Temas edilip görüşme olmayan çağrıların sayısı. Cevapsız ve Meşgul çağrılar
  t_noAttemptsCallInfo: 'Количество звонков, переданных системой оператору, но завершенных без контакта с вызываемым номером', // Sistem tarafından operatöre iletilen fakat operatörün aranılan numaraya temas etmeden sonlanan çağrı adedi
  t_attemptsRateInfo: 'Процент звонков, переданных системой оператору, которые достигли контакта с вызываемым номером. Формула: (Общее - Без контакта) / Общее', // Sistem tarafından operatöre iletilen çağrıların aranılan numaraya temas etme oranı. Formül: Toplam - Temassız / Toplam
  t_reachRateInfo: 'Процент звонков, которые попали в очередь. Формула: (Разговорные + Пропущенные) / Общее', // Aranan numaraların kuyruğa bağlanma oranı. Formül : Görüşülen + Kaçan / Toplam
  t_username: 'Имя пользователя', // Kullanıcı Adı
  t_examples: 'Примеры', // Örnekler
  t_cron: 'Планировщик', // Zamanlayıcı
  t_cron1: "Каждую минуту", // Her dakika
  t_cron2: "Каждый час", // Her saat başı
  t_cron3: "Каждый день в полночь", // Her gün gece yarısı
  t_cron4: "Каждый день в 17:00", // Her gün saat 17:00
  t_cron5: "Каждое воскресенье в полночь", // Her hafta Pazar günü gece yarısı
  t_cron6: "Каждый первый день месяца в полночь", // Her ayın ilk günü gece yarısı
  t_cron7: "Каждый первый день года в полночь", // Yılın ilk günü gece yarısı
  t_cron8: "Каждый рабочий день в полночь", // Haftanın her iş günü gece yarısı
  t_cron9: "Каждый 15-й день месяца в полночь", // Her ayın 15. günü gece yarısı
  t_cron10: "Каждый первый понедельник года в полночь", // Her yılın ilk Pazartesi günü gece yarısı
  t_cron11: "Каждый 15-й день января, июня и декабря в полночь", // Her yılın Ocak, Haziran ve Aralık aylarının 15. günü gece yarısı
  t_cron12: "Каждый первый рабочий день месяца в полночь", // Her ayın ilk iş günü gece yarısı
  t_cron13: "Каждые выходные в полночь", // Hafta sonları gece yarısı
  t_alert: 'Оповещение', // Alarm
  t_lessThan: 'Меньше чем', // Küçüktür
  t_lessThanOrEqual: 'Меньше или равно', // Küçük Eşittir
  t_moreThanOrEqual: 'Больше или равно', // Büyük Eşittir
  // Report Fields
  t_rConferenceEvent: 'Конференция', // Konferans
  t_rConferenceParticipants: 'Участники конференции', // Konferans Katılımcıları
  t_rQualityFormNote: 'Заметка формы качества', // Form Notu
  t_rInboundFailSL3059: 'Пропущенные звонки в пределах 30/59 секунд', // 30/59sn İçinde Kaçan Çağrı A.
  t_rInboundFailSL60119: 'Пропущенные звонки в пределах 60/119 секунд', // 60/119sn İçinde Kaçan Çağrı A.
  t_rInboundFailSL120179: 'Пропущенные звонки в пределах 120/179 секунд', // 120/179sn İçinde Kaçan Çağrı A.
  t_rInboundFailSL180: 'Пропущенные звонки более 180 секунд', // 180sn Aşarak Kaçan Çağrı A.
  t_rIvrWaitLength: 'Время ожидания IVR', // IVR Bekleme Süresi
  t_rExtra: 'Дополнительная информация', // Ekstra Bilgiler
  t_rSender: 'Отправитель', // Gönderici
  t_rMessage: 'Сообщение', // Mesaj
  t_rEvaluateEvent: 'Оценка события', // Değerlendirme
  t_rEvaluateTime: 'Время оценки', // Değerlendirme T.
  t_rQualityFormName: 'Название формы качества', // Form Adı
  t_callAnalyze: 'Анализ звонка', // Çağrı Analizi
  t_rObjectionEvent: 'Возражение', // İtiraz
  t_rPunishEvent: 'Наказание', // Ceza
  t_rQualityPoint: 'Балл качества', // Kalite Puanı
  t_rMailSender: "Отправитель", // Gönderici
  t_rSubject: "Тема", // Konu
  t_rBody: "Описание", // Açıklama
  t_rTo: 'Кому (To)', // Alıcı (To)
  t_rCC: 'Копия (Cc)', // Karbon Kopya (Cc)
  t_rState: "Состояние", // Durum
  t_rStartTime: "Время начала", // Başlangıç T.
  t_rEndTime: "Время окончания", // Bitiş T.
  t_rRemoteNumber: "Телефон", // Telefon
  t_rInboundFailSuccessCallBack: "Перезвон успешен", // Geri Aranan
  t_rInboundFailDistinct: "Уникальный неперезвон", // Tekil Geri Aranmayan
  t_rProductivity: "Производительность", // Verimlilik
  t_rSurveyCount: "Количество опросов", // Anket Sayısı
  t_rAgent: "Агент", // Temsilci
  t_rDirection: "Направление", // Yön
  t_rQueue: "Очередь", // Kuyruk
  t_rVerdict: "Код завершения", // Sonuç Kodu
  t_rActionDate: "Дата действия", // Aksiyon T.
  t_rNote: "Заметка", // Not
  t_rConnectedLength: "Продолжительность разговора", // Görüşme Süresi
  t_rQueueWaitLength: "Время ожидания в очереди", // Kuyrukta Bekleme S.
  t_rAlertLength: "Время звонка", // Çalma Süresi
  t_rHoldLength: "Время удержания", // Bekletme Süresi
  t_rAcwLength: "Время ACW", // Acw Süresi
  t_rRecordingFile: "Имя файла", // Dosya Adı
  t_rTerminateSide: "Сторона завершения", // Sonlandıran
  t_rRecordingFileName: "Запись", // Kayıt
  t_rLocalNumber: "Линия", // Hat
  t_rLineName: "Название линии", // Hat Adı
  t_rClassification: "Классификация", // Sınıflandırma
  t_rCampaign: "Кампания", // Kampanya
  t_rInteractionID: "ID звонка", // Çağrı ID
  t_rMüşteriNo: "Номер клиента", // Müşteri No
  t_rAdıSoyadı: "Имя Фамилия", // Adı Soyadı
  t_rIsCalledBack: "Был ли перезвон", // Geri Arandı Mı
  t_rCalledBackedStartTime: "Время перезвона", // Geri Aranma T.
  t_rCalledBackedQueue: "Очередь перезвона", // Geri Arandığı Kuyruk
  t_rUsername: "Агент", // Temsilci
  t_rInboundSuccess: "Отвеченные", // Cevaplanan
  t_rInboundTotal: "Всего входящих", // Gelen Toplam
  t_rTotal: "Общий итог", // Genel Toplam
  t_rLogin: "Вход", // Giriş
  t_rLogout: "Выход", // Çıkış
  t_rAvgCallTime: "Среднее время звонка", // Ort.Konuşma Süresi
  t_rOnlineTime: "Время входа", // Login Süresi
  t_rInCallTime: "Общее время разговора", // Toplam Konuşma Süresi
  t_rInboundSuccessSL: "SL отвеченные", // SL Cevaplama
  t_rInboundFail: "Пропущенные", // Kaçan
  t_rOutboundSuccess: "Успешные исходящие", // Giden Başarılı
  t_rOutboundFail: "Неуспешные исходящие", // Giden Başarısız
  t_rTotalWaitTime: "Общее время ожидания", // Toplam Bekleme Süresi
  t_rAvgWaitTime: "Среднее время ожидания", // Ortalama Bekleme Süresi
  t_rMaxWaitTime: "Максимальное время ожидания", // Max Bekleme Süresi
  t_rAgentMissed: "Пропущенные агентом", // Temsilci Kaçan
  t_rInitMoment: "Дата", // Tarih
  t_rCount: "Количество", // Adet
  t_rDate: "Дата", // Tarih
  t_rCustomerID: "ID клиента", // Müşteri No
  t_rProductID: "ID продукта", // Ürün No
  t_rInboundSuccessRate: "Процент отвеченных", // Cevaplama Oranı
  t_rDialerSuccess: "Успешные автозвонки", // Oto.Arama Başarılı
  t_rDialerFail: "Неуспешные автозвонки", // Oto.Arama Başarısız
  t_rDialerTotal: "Всего автозвонков", // Oto.Arama Toplam
  t_rDialerTotalTalkTime: "Общее время разговора автозвонков", // Oto.Arama Top.Kon.Süresi
  t_rDialerTotalIVRTime: "Общее время IVR автозвонков", // Oto.Arama Top.Ivr Süresi
  t_rDialerAvgIVRTime: "Среднее время IVR автозвонков", // Oto.Arama Ort.Ivr Süresi
  t_rDialerAvgTalkTime: "Среднее время разговора автозвонков", // Oto.Arama Ort.Kon.Süresi
  t_rTotalAvgCallTime: "Среднее время звонка", // Ortalama Çağrı Süresi
  t_rTotalCallTime: "Общее время звонка", // Top. Çağrı Süresi
  t_rTotalSuccessCallCount: "Общее количество успешных звонков", // Başarılı Çağrı Adedi
  t_rTotalCallCount: "Общее количество звонков", // Top. Çağrı Adedi
  t_rDialerTotalCallTime: "Общее время звонков автозвонков", // Oto. Arama Çağrı Süresi
  t_rDialerAvgCallTime: "Среднее время звонков автозвонков", // Oto. Arama Ort. Çağrı Süresi
  t_rTotalSuccess: "Всего успешных", // Top.Görüşülen
  t_rTotalTalkTime: "Общее время разговора", // Top.Kon.Süresi
  t_rTotalAvgTalkTime: "Среднее время разговора", // Ort.Kon.Süresi
  t_rTotalCustomer: "Всего клиентов", // Toplam Müşteri
  t_rCallable: "Доступен для звонка", // Aranabilir
  t_rUnCallable: "Завершен", // Tamamlanmış
  t_rZeroAttempts: "Никогда не звонили", // Hiç Aranmamış
  t_rStartDate: "Дата начала", // Tarih
  t_rAgentCount: "Количество агентов", // Temsilci Sayısı
  t_rIvrMissed: "Пропущенные IVR", // IVR Sonlanan Çağrı A.
  t_rInboundAgentTotal: "Всего входящих звонков агента", // Temsilciye Gelen Çağrı A.
  t_rInboundSuccessSLA30: "Отвеченные за 30 секунд", // 30 Aşarak Karşılanan Çağrı A.
  t_rInboundSuccessSL120: "Отвеченные за 120 секунд", // 120sn İçinde Karşılanan Çağrı A.
  t_rInboundSuccessSLA120: "Отвеченные за 120+ секунд", // 120 Aşarak Karşılanan Çağrı A.
  t_rInboundFailSL30: "Пропущенные за 30 секунд", // 30sn İçinde Kaçan Çağrı A.
  t_rInboundFailSLA30: "Пропущенные за 30+ секунд", // 30sn Aşarak Kaçan Çağrı A.
  t_rInboundSuccessSLA30AvgWaitTime: "Среднее время ожидания отвеченных за 30+ секунд", // 30sn Aşarak Karşılanan Çağrı Ort.Bek.S.
  t_rInboundSuccessSLA120AvgWaitTime: "Среднее время ожидания отвеченных за 120+ секунд", // 120sn Aşarak Karşılanan Çağrı Ort.Bek.S.
  t_rAvgWaitTimeSLA30: "Среднее время ожидания пропущенных за 30+ секунд", // 30sn Aşarak Kaçan Çağrı Ort.Bek.S.
  t_rSuccessRate: "Процент успешных", // Karşılama Oranı
  t_rServiceLevel: "Уровень обслуживания", // Hizmet Seviyesi
  t_rTotalConnectedLength: "Общее время разговора", // Toplam Görüşme Süresi
  t_rAvgConnectedLength: "Среднее время разговора", // Ortalama Görüşme Süresi
  t_rTotalSuccessCount: "Общее количество успешных", // Toplam Görüşme Adedi
  t_rBaseRemoteNumber: "Телефон", // Telefon
  t_rTotalCall: "Всего звонков", // Toplam Çağrı
  t_rSuccessCall: "Успешные звонки", // Ulaşılan
  t_rFailCall: "Неуспешные/Пропущенные звонки", // Ulaşılmayan/ Cevapsız
  t_rAMD: "Автоответчик", // Telesekreter
  t_rTotalCallLength: "Общее время звонка", // Toplam Görüşme Süresi
  t_rFirstCallTime: "Время первого звонка", // İlk Arama Tarihi
  t_rLastCallTime: "Время последнего звонка", // Son Arama Tarihi
  t_rAbandon: "Пропущенные", // Kaçan
  t_rSaleCall: "Была ли продажа", // Satış Var Mı
  t_rEventTime: "Время события", // Tarih
  t_rEvent: "Событие", // Olay
  t_rPrevStatus: "Предыдущий статус", // Durum
  t_rPrevReason: "Предыдущий результат", // Sonuç
  t_rPrevStatusLength: "Продолжительность (сек)", // Süre(sn)
  t_rInboundSL30: "SL30", // SL30
  t_rInboundAnswerRate: "Процент отвеченных", // Cevaplama Oranı
  t_rInboundAvgCallTime: "Среднее время входящего звонка", // IB ACHT
  t_rOutboundAvgCallTime: "Среднее время исходящего звонка", // OB ACHT
  t_rInterval: "Интервал", // Saat
  t_rInboundTotalCallTime: "Общее время входящих звонков", // IB Toplam Görüşme Süresi
  t_rOutboundTotalCallTime: "Общее время исходящих звонков", // OB Toplam Görüşme Süresi
  t_rDayName: "День", // Gün
  t_rInboundSuccessSL30: "Отвеченные за первые 30 секунд", // İlk 30sn Cevaplanan
  t_rInboundSuccessSL30Rate: "Процент отвеченных за первые 30 секунд", // İlk 30sn Cevaplama Oranı
  t_rInboundFailSL10: "Пропущенные за первые 10 секунд", // İlk 10sn Kaçan
  t_rInboundSuccessRateSL30: "Процент отвеченных SL30", // SL30 Cevaplama Oranı
  t_rInboundFailRate: "Процент пропущенных", // Kaçan Çağrı Oranı
  t_rInboundAvgTalkTime: "Среднее время разговора входящих", // Gelen Ort.Konuşma Süresi
  t_rInboundTotalTalkTime: "Общее время разговора входящих", // Gelen Top.Konuşma Süresi
  t_rInboundAvgAnswerTime: "Среднее время ответа", // Ort.Cevaplama Süresi
  t_rOutboundTotal: "Всего исходящих", // Toplam Dış Arama
  t_rOutboundAvgTalkTime: "Среднее время разговора исходящих", // Giden Ort.Konuşma Süresi
  t_rOutboundTotalTalkTime: "Общее время разговора исходящих", // Giden Top.Konuşma Süresi
  t_rCreateDate: "Дата создания", // Yüklenme Tarihi
  t_rAssignedAgent: "Назначенный агент", // Temsilci
  t_rLine: "Линия", // Hat
  t_rSuccessCalls: "Успешные звонки", // Ulaşılan
  t_rFailCalls: "Неуспешные звонки", // Ulaşılamayan
  t_rHour: "Час", // Saat
  t_rAgentSentimentPositiveSegmentCount: "Количество положительных сегментов агента", // Temsilci Pozitif Segment Sayısı
  t_rAgentSentimentPositiveScore: "Положительный балл агента", // Temsilci Pozitif Skor
  t_rAgentSentimentPositiveTokenCount: "Количество положительных токенов агента", // Temsilci Pozitif Token Sayısı
  t_rAgentSentimentNegativeSegmentCount: "Количество отрицательных сегментов агента", // Temsilci Negatif Segment Sayısı
  t_rAgentSentimentNegativeScore: "Отрицательный балл агента", // Temsilci Negatif Skor
  t_rAgentSentimentNegativeTokenCount: "Количество отрицательных токенов агента", // Temsilci Negatif Token Sayısı
  t_rAgentSentimentTotalScore: "Общий балл агента", // Temsilci Toplam Skor
  t_rAgentSentimentPositiveSegmentRate: "Процент положительных сегментов агента (%)", // Temsilci Pozitif Segment Oranı(%)
  t_rAgentSentimentNegativeSegmentRate: "Процент отрицательных сегментов агента (%)", // Temsilci Negatif Segment Oranı(%)
  t_rAgentSentimentNotrSegmentRate: "Процент нейтральных сегментов агента (%)", // Temsilci Nötr Segment Oranı(%)
  t_rCustomerSentimentPositiveSegmentCount: "Количество положительных сегментов клиента", // Müşteri Pozitif Segment Sayısı
  t_rCustomerSentimentPositiveScore: "Положительный балл клиента", // Müşteri Pozitif Skor
  t_rCustomerSentimentPositiveTokenCount: "Количество положительных токенов клиента", // Müşteri Pozitif Token Sayısı
  t_rCustomerSentimentNegativeSegmentCount: "Количество отрицательных сегментов клиента", // Müşteri Negatif Segment Sayısı
  t_rCustomerSentimentNegativeScore: "Отрицательный балл клиента", // Müşteri Negatif Skor
  t_rCustomerSentimentNegativeTokenCount: "Количество отрицательных токенов клиента", // Müşteri Negatif Token Sayısı
  t_rCustomerSentimentTotalScore: "Общий балл клиента", // Müşteri Toplam Skor
  t_rCustomerSentimentPositiveSegmentRate: "Процент положительных сегментов клиента (%)", // Müşteri Pozitif Segment Oranı(%)
  t_rCustomerSentimentNegativeSegmentRate: "Процент отрицательных сегментов клиента (%)", // Müşteri Negatif Segment Oranı(%)
  t_rCustomerSentimentNotrSegmentRate: "Процент нейтральных сегментов клиента (%)", // Müşteri Nötr Segment Oranı(%)
  t_rTotalDuration: "Общее время разговора", // Toplam Konuşma Süresi
  t_rOverlappedSpeechRatio: "Процент перекрывающейся речи", // Üstüste Konuşma Oranı
  t_rOverlappedSpeechDuration: "Продолжительность перекрывающейся речи (сек)", // Üstüste Konuşma Süresi(sn)
  t_rOverlappedSilenceRatio: "Процент тишины", // Sessizlik Oranı
  t_rOverlappedSilenceDuration: "Продолжительность тишины (сек)", // Sessizlik Süresi(sn)
  t_rAgentSpeechRatio: "Процент речи агента", // Temsilci Konuşma Oranı
  t_rAgentSpeechDuration: "Продолжительность речи агента (сек)", // Temsilci Konuşma Süresi(sn)
  t_rAgentSpeakingRate: "Скорость речи агента (слов/минуту)", // Temsilci Konuşma Hızı(Kelime / DaKıKa)
  t_rAgentInterruptCount: "Количество прерываний агента", // Temsilci Söz Kesme Sayısı
  t_rCustomerSpeechRatio: "Процент речи клиента", // Müşteri Konuşma Oranı
  t_rCustomerSpeechDuration: "Продолжительность речи клиента (сек)", // Müşteri Konuşma Süresi(sn)
  t_rCustomerSpeakingRate: "Скорость речи клиента (слов/минуту)", // Müşteri Konuşma Hızı(Kelime / Dakika)
  t_rCustomerInterruptCount: "Количество прерываний клиента", // Müşteri Söz Kesme Sayısı
  t_rListens: "Прослушано", // Dinlenen
  t_rTotalEvaluations: "Всего оценок", // Değerlendirilen
  t_rAutoEvaluations: "Автоматические оценки", // Otomatik Değerlendirilen
  t_rEvaluations: "Ручные оценки", // Manuel Değerlendirilen
  t_rAvgAutoEvaluationsPoint: "Средний балл автоматических оценок", // Otomatik Değerlendirilen Ort.Puan
  t_rAvgEvaluationsPoint: "Средний балл ручных оценок", // Manuel Değerlendirilen Ort.Puan
  t_rTotalCalls: "Всего звонков", // Toplam Çağrı
  t_rOverlappedSilence: "Количество двусторонней тишины", // Çift Yönlü Sessizlik Adet
  t_rOverlappedSilenceRateByTotalCalls: "Двусторонняя тишина / Общее количество звонков (%)", // Çift Yönlü Sessizlik / Total Çağrı Adedi(%)
  t_rOverlappedSilenceRateBySuccessCalls: "Двусторонняя тишина / Количество успешных звонков (%)", // Çift Yönlü Sessizlik / Ulaşılan Çağrı Adedi(%)
  t_rClosedByAgentCount: "Завершено агентом", // MT Tarafından Sonlanan
  t_rClosedByCustomerCount: "Завершено клиентом", // Müşteri Tarafından Sonlanan
  t_rClosedByAgentRateByTotalCalls: "Завершено агентом (%)", // MT Tarafından Sonlanan(%)
  t_rClosedByCustomerRateByTotalCalls: "Завершено клиентом (%)", // Müşteri Tarafından Sonlanан(%)
  t_rForbiddenWords: "Использование запрещенных слов", // Yasaklı Kelime Kullanımı
  t_rForbiddenWordsRateByTotalCalls: "Использование запрещенных слов / Общее количество звонков (%)", // Yasaklı Kelime Kullanımı / Total Çağrı Adedi(%)
  t_rForbiddenWordsRateBySuccessCalls: "Использование запрещенных слов / Количество успешных звонков (%)", // Yasaklı Kelime Kullanımı / Ulaşılan Çağrı(%)
  t_rAgentNoSpeechCount: "Количество звонков, где клиент говорил, а агент нет", // Müşterinin Konuşup MT nın Konuşmadığı Çağrı Adedi
  t_rAgentNoSpeechRateByTotalCalls: "Количество звонков, где клиент говорил, а агент нет / Общее количество звонков (%)", // Müşterinin Konuşup MT nın Konuşmadığı Çağrı Adedi / Total Çağrı Adedi(%)
  t_rAgentNoSpeechRateBySuccessCalls: "Количество звонков, где клиент говорил, а агент нет / Количество успешных звонков (%)", // Müşterinin Konuşup MT nın Konuşmadığı Çağrı Adedi / Ulaşılan Çağrı Adedi(%)
  t_rAvgAHT: "Среднее время обработки звонка", // Ortalama AHT
  t_rMaxAHT: "Максимальное среднее время обработки звонка", // En Yüksek AHT ORT
  t_rMinAHT: "Минимальное среднее время обработки звонка", // En Düşük AHT ORT
  t_rDuration: "Продолжительность звонка", // Çağrı Süresi
  t_rListeningDuration: "Продолжительность прослушивания", // Dinleme Süresi
  t_rPlayCount: "Количество воспроизведений", // Başlatma Adet
  t_rPauseCount: "Количество пауз", // Durdurma Adet
  t_rIsMuted: "Звук отключен?", // Ses Kapandı Mı ?
  t_rPlayBackChangeCount: "Количество изменений скорости воспроизведения", // Hız Değiştirme Adet
  t_rPlayBackStartSpeed: "Начальная скорость воспроизведения", // Başlangıç Hızı
  t_rPlayBackEndSpeed: "Конечная скорость воспроизведения", // Bitiş Hızı
  t_rManualForm: "Ручная форма", // Manuel Form
  t_rManualFormPoint: "Балл ручной формы", // Manuel Form Puan
  t_rAutoForm: "Автоматическая форма", // Otomatik Form
  t_rAutoFormPoint: "Балл автоматической формы", // Otomatik Form Puan
  t_rQualityNote: "Заметка о качестве", // Kalite Notu
  t_abandonedCampaign: "Кампания пропущенных звонков", // Kaçan Çağrı Kampanyası
  t_autoRecycle: "Автоматический цикл", // Otomatik Döngü
  t_goalListByAgents: "Список целей по агентам", // Temsilci Hedef Listesi
  t_addGoal: "Добавить цель", // Hedef Ekle
  t_addCollection: "Добавить движение", // Hareket Ekle
  t_collections: "Движения", // Hareketler
  t_archivedMessages: "Архивированные сообщения", // Arşivlenmiş Mesajlar
  t_archivetedMessages: "Архивированные сообщения", // Arşivlenmiş Mesajlar
  // Report Names 
  t_rQualityDetails: "Детали качества", // Kalite Detayı
  t_rInboundCallDetails: "Сводка входящих звонков", // Gelen Çağrı Özeti
  t_rCallTimeAndCounts: "Время и количество звонков", // Çağrı Süresi ve Adetleri
  t_rCallDetails: "Детали звонка", // Çağrı Detayı
  t_rClassificationDetails: "Детали классификации", // Sınıflandırma Detayı
  t_rAgentPerformanceSummary: "Анализ производительности агента", // Temsilci Performans Analizi
  t_rAgentPerformanceSummaryByShift: "Производительность агента по сменам", // Temsilci Vardiya Performansı
  t_rVerdictDetails: "Детали кода завершения", // Sonuç Kodu Detayı
  t_rSmsHistory: 'История SMS', // SMS Geçmişi
  t_rMailHistory: 'История почты', // Mail Geçmişi
  t_rSecondLineDetails: 'Детали второй линии / внутренние', // İkinci Hat / Dahili Detayı
  t_rAbandonCalls: 'Детали пропущенных звонков', // Kaçan Çağrı Detayı
  t_rMissedCalls: 'Детали неотвеченных звонков', // Cevaplanmayan Çağrı Detayı
  t_rAgentSummary: 'Сводка агента', // Temsilci Özet
  t_rQueueSL: 'SL / Очередь', // SL / Kuyрук
  t_rOutOfWork: 'Вне рабочего времени', // Mesai Dışı Gelenler
  t_rQueueDaily: 'Количество входящих звонков / День', // Gelen Çağrı Adetleri / Gün
  t_rAgentDaily: 'Количество звонков агента / День', // Gelen Çağrı Adetleri / Gün
  t_rQueueHourly: 'Количество входящих звонков / Час', // Gelen Çağrı Adetleri / Saat
  t_rAgentHourly: 'Количество звонков агента / Час', // Gelen Çağrı Adetleri / Saat
  t_rVerdictByDate: 'Количество кодов завершения по дате', // Sonuç Kodu Adetleri
  t_rVerdictByDaily: 'Код завершения / День', // Sonuç Kodu / Gün
  t_rVerdictByQueue: 'Код завершения / Очередь', // Sonuç Kodu / Kuyрук
  t_rVerdictByAgent: 'Код завершения / Агент', // Sonuç Kodu / Agent
  t_rClassificationByDate: 'Количество классификаций по дате', // Sınıflandırma Adetleri
  t_rClassificationByDaily: 'Классификация / День', // Sınıflandırма / Gün
  t_rClassificationByQueue: 'Классификация / Очередь', // Sınıflandırма / Kuyрук
  t_rClassificationByAgent: 'Классификация / Агент', // Sınıflandırма / Agent
  t_rCallCountByDate: 'Количество звонков / День', // Çağrı Adetleri / Gün
  t_rDataSummaryByQueue: 'Сводка данных / Очередь', // Veri Özeti / Kuyрук
  t_rProductActionDetails: "Детали действий по продукту", // Ürün Aksiyon Detayları
  t_rLoginLogout: 'Вход / Выход', // Giriş / Çıkış
  t_rLocalNumberSL: 'SL / Номер', // SL / Numara
  t_rCallTimeAndCountByDirection: 'Время и количество звонков / Направление', // Çağrı Süresi ve Adetleri / Yön
  t_rPozitifCallDetails: 'Детали звонков / Пул', // Çağrı Detayları / Havuz
  t_rActivity: 'Активность агента', // Temsilci Aktivite
  t_rDailyCallValues: 'Значения звонков / День', // Çağrı Değerleri / Gün
  t_callrecords: "Записи звонков", // Çağrı Kayıtları
  t_rHourlyCallValues: 'Значения звонков / Час', // Çağrı Değerleri / Saat
  t_rAgentPerformanceDaily: 'Производительность агента / День', // Temsilci Performans / Gün
  t_rAgentPerformanceMonthly: 'Производительность агента / Месяц', // Temsilci Performans / Ay
  t_rAgentPerformanceHourly: 'Производительность агента / Час', // Temsilci Performans / Saat
  t_rMonthlyCallValues: 'Значения звонков / Месяц', // Çağrı Değerleri / Ay
  t_rCallPerformanceByDate: 'Производительность звонков / День', // Çağrı Performans / Gün
  t_rDataSummaryByCreateDate: 'Сводка данных / Дата создания', // Veri Özeti / Yüklenme Tarihi
  t_rDataSummaryByAssignedAgent: 'Сводка данных / Назначенный агент', // Veri Özeti / Atama
  t_rDataSummaryByDynamicFields: 'Сводка данных / Динамические поля', // Veri Özeti / Dinamik Kırılıм
  t_rLinePerformance: 'Производительность линии', // Hat Performans
  t_rInboundCallValuesByHourly: 'Значения входящих звонков / Час', // Gelen Çağrı Değerleri / Saat
  t_rSurveyResult: 'Результаты опроса', // Anket Sonuçları
  t_rEmotionAnalysis: 'Анализ эмоций / День', // Duygu Analizi / Gün
  t_rSpeechStatistics: 'Статистика речи / День', // Konuşma İstatistikleri / Gün
  t_analysis: "Анализ", // Analiz
  t_rQualityByDate: 'Качество / День', // Kalite / Gün
  t_rQualityByAgent: 'Качество / Агент', // Kalite / Agent
  t_rCallStatisticsByDate: 'Статистика звонков / День', // Çağrı İstatistikleri / Gün
  t_rListeningDetails: 'Детали прослушивания', // Dinleme Detayları
  t_rProject: 'Проект', // Proje
  t_rIVR: 'IVR', // IVR
  t_rInQueue: "В очереди", // Kuyrukta
  t_rInProgress: "В процессе", // İşlemde
  t_rShiftStart: 'Начало смены', // Vardiya Başlangıç
  t_rShiftEnd: 'Конец смены', // Vardiya Bitiş
  t_rShiftDuration: 'Продолжительность смены', // Vardiya Süresi
  t_rMissingTime: 'Потерянное время', // Kayıp Zaman
  t_rCalculatedShiftDuration: 'Рассчитанная продолжительность смены', // Hesaplanan Vardiya Süresi
  t_rCalculatedBreak: 'Рассчитанное время перерыва', // Hesaplanan Mola Süresi
  t_rTotalWorkDuration: 'Общее время работы', // Toplam Çalışma Süresi
  t_rLeave: 'Отпуск', // İzin
  t_rBreakTime: 'Референсное время перерыва', // Referans Mola Süresi
  t_rCalculatedWorkTime: 'Рассчитанное время работы', // Hesaplanan Çalışma Süresi
  t_rWorkDuration: 'Продолжительность работы', // Çalışма Süresi
  t_rTotalWorkDifference: 'Разница в рабочем времени', // Çalışма Süresi Farkı
  t_reportName: "Название отчета", // Rapor Adı
  t_dataCount: "Количество данных", // Veri Adedi
  t_expand: "Развернуть", // Genişlet
  t_shrink: "Свернуть", // Daralt
  t_darkMode: "Ночной режим", // Gece Modu
  t_lightMode: "Дневной режим", // Gündüz Modu
  t_alertCategory: "Категория оповещения", // Uyarı Kategorisi
  t_targetCategory: "Категория цели", // Hedef Kategorisi
  t_targetType: "Тип цели", // Hedef Tipi
  t_none: "Нет", // Yok
  t_seeExampleTimePatterns: "См. примеры временных шаблонов", // Örnek Zamanlamaları Gör
  t_apply: "Применить", // Uygula
  t_callList: 'Список назначений', // Atama Listesi
  t_pool: 'Пул', // Havuz
  t_all: 'Все', // Tümü
  t_logs: 'Логи', // Loglar
  t_totalAttempts: 'Общее количество попыток', // Toplam Temas
  t_insertDate: 'Последний звонок', // Son Arama
  t_actionTime: 'Время действия', // Aranacak Z
  t_assignDate: 'Дата назначения', // Atama T
  t_createDate: 'Дата создания', // Yüklenme T
  t_createTime: 'Время создания', // Yüklenme Z
  t_exAgent: 'Бывший агент', // Eski Temsilci
  t_addExtraCustomerInfo: 'Добавить дополнительную информацию о клиенте', // Ekstra Müşteri Bilgisi Ekle
  t_primaryKey: 'Первичный ключ', // Birincil Kırılıм
  t_secondaryKey: 'Вторичный ключ', // İkincil Kırılıм
  t_qVoice: 'Голосовой', // Sesli
  t_qVideo: 'Видео', // Görüntülü
  t_qText: 'Текстовый', // Yazılı
  t_interactionWeight: 'Вес канала', // Kanal Ağırlığı
  t_records: 'Записи', // Kayıtlar
  t_qualityForm: 'Форма качества', // Kalite Formu
  t_keyWords: 'Ключевые слова', // Anahtar Kelimeler
  t_criterions: 'Критерии', // Kriterler
  t_required: "Обязательно", // Zorunlu
  t_isChatFinishCode: 'Код завершения текстового канала', // Yazılı Kanal Sonuç Kodu
  t_minWaitInQueue: 'Мин. время ожидания в очереди (сек)', // Min. Kuyrukta Bekleme Süresi (sn)
  t_maxWaitInQueue: 'Макс. время ожидания в очереди (сек)', // Max. Kuyrukta Bekleme Süresi (sn)
  t_minDuration: 'Мин. продолжительность разговора (сек)', // Min. Görüşme Süresi (sn)
  t_maxDuration: 'Макс. продолжительность разговора (сек)', // Max. Görüşme Süresi (sn)
  t_rIsEvaluated: 'Оценено', // Değerlendirme
  t_rEvaluator: 'Оценщик', // Değerlendiren
  t_rEvaluatedStartTime: 'Время начала оценки', // Değerlendirme Başlangıç T.
  t_rEvaluatedEndTime: 'Время окончания оценки', // Değerlendirme Bitiş T.
  t_rMinQualityPoint: 'Мин. балл качества', // Min. Kalite Puanı
  t_rMaxQualityPoint: 'Макс. балл качества', // Max. Kalite Puanı
  t_rQualityForm: 'Форма качества', // Kalite Formu
  t_rIsObjection: 'Возражение', // İtiraz
  t_multiple: 'Множественный выбор', // Çoklu Seçim
  t_question: 'Вопрос', // Soru
  t_minValue: 'Мин. значение', // Min. Değer
  t_maxValue: 'Макс. значение', // Max. Değer
  t_minTrueCount: 'Мин. количество истинных', // Min. Doğru Sayısı
  t_ordered: 'Упорядоченный', // Sıralı
  t_minPoint: 'Мин. балл', // Min. Puan
  t_maxPoint: 'Макс. балл', // Max. Puan
  t_point: 'Балл', // Puan
  t_selected: 'Выбранный', // Seçili
  t_punish: 'Наказать', // Cezalandır
  t_pointRange: 'Диапазон баллов', // Puan Aralığı
  t_answer2: 'Ответ', // Cevap
  t_openInNewWindow: 'Открыть в новом окне', // Yeni Pencerede Aç
  t_playbackRate: 'Скорость воспроизведения', // Oynatma Hızı
  t_isMailSendToAgent: 'Отправить письмо агенту', // Temsilciye Mail At
  t_httpRequest: 'HTTP запрос', // HTTP İsteği
  t_tts: 'TTS', // TTS
  t_stt: 'STT', // STT
  t_beepTone: "Тон бипа", // Beep Ton
  t_model: 'Модель', // Model
  t_whatsapp: 'Whatsapp', // Whatsapp
  t_readQR: 'Сканировать QR', // QR Okut
  t_url: 'Адрес ссылки (URL)', // Bağlantı Adresi (URL)
  t_headers: 'Заголовки (JSON)', // Headers (JSON)
  t_body: 'Тело (JSON)', // Body (JSON)
  t_text: 'Текст', // Metin
  t_useableVariables: 'Используемые переменные', // Kullanılabilir Değişkenler
  t_remoteNumber: 'Номер телефона', // Telefon Numarası
  t_hideVariableFromAgent: 'Скрыть переменную от агента', // Değişkeni Temsilciden Gizle
  t_provider: 'Провайдер', // Sağlayıcı
  t_speaker: 'Спикер', // Konuşmacı
  t_isInList: 'В списке', // Listede Olan
  t_isNotInList: 'Не в списке', // Listede Olmayan
  t_isContains: 'Содержит', // İçeren
  t_isNotContains: 'Не содержит', // İçermeyen
  t_IsConvergent: 'Есть сходство', // Ortaklık Var
  t_IsNotConvrgent: 'Нет сходства', // Ortaklık Yok
  t_changeAllQueues: 'Массовое изменение очередей', // Toplu Kuyрук Değişimi
  t_remove: 'Удалить', // Çıkar
  t_answerToObject: 'Ответить на возражение', // İtirazı Cevapla
  t_objectToForm: 'Возразить на форму', // Forma İtiraz Et
  t_objections: 'Возражения', // İtirazlar
  t_accept: 'Принять', // Kabul Et
  t_rLastObjectingUser: 'Последний возражающий пользователь', // Son İtiraz Eden
  t_evaluatedDaily: "Оценено сегодня", // Bügün Değerlendilen
  t_avgPointDaily: "Средний балл сегодня", // Bügün Ort. Puan
  t_evaluatedWeekly: "Оценено на этой неделе", // Bu Hafta Değerlendilen
  t_avgPointWeekly: "Средний балл на этой неделе", // Bu Hafta Ort. Puan
  t_evaluatedMonthly: "Оценено в этом месяце", // Bu Ay Değerlendilen
  t_avgPointMonthly: "Средний балл в этом месяце", // Bu Ay Ort. Puan
  t_standart: 'Стандартный', // Standart
  t_advanced: 'Расширенный', // Gelişmiş
  t_quality: 'Качество', // Kalite
  t_removeEvaluation: 'Удалить оценку', // Değerlendirmeyi Sil
  t_openOnNewWindow: 'Открыть в новой вкладке', // Yeni Sekmede Aç
  t_undefined: 'Не определено', // Tanımsız
  t_callTime: 'Время звонка', // Çağrı Süresi
  t_serviceLevel: 'Уровень обслуживания', // Servis Seviyesi
  t_abandonRate: 'Процент пропущенных звонков', // Kaçan Çağrı Oranı
  t_callAnswerTime: 'Время ответа на звонок', // Çağrı Cevaplama Hızı
  t_noRouteToDestination: 'Нет маршрута до назначения', // Hedef Yok
  t_passwordSentToMailAdress: 'Ваш пароль отправлен на адрес электронной почты.', // Şifreniz mail adresinize gönderildi.
  t_noEmailAdress: 'У вашего аккаунта нет адреса электронной почты. Пожалуйста, свяжитесь с вашим администратором.', // Hesabınızın e-posta adresi bulunmamaktadır. Lütfen yöneticinizle iletişime geçin.
  t_forgotPassword: 'Забыли пароль', // Şifremi Unuttum
  t_saveRecord: 'Сохранить запись', // Ses Kaydı Al
  t_isHttps: 'HTTPS', // HTTPS
  t_callStatistics: 'Статистика звонков', // Çağrı İstatistikleri
  t_addtCategory: 'Добавить новую категорию', // Yeni Kategori Ekle
  t_create: 'Создать', // Oluştur
  t_questionCount: 'Количество вопросов', // Soru Adet
  t_minPts: 'Мин. балл', // Min Puan
  t_maxPts: 'Макс. балл', // Max Puan
  t_questionsAndAnswers: 'Вопросы и ответы', // Sorular & Cevaplar
  t_extensionRoutes: 'Маршруты', // Rotalar
  t_evaluatedRecords: 'Оцененные записи', // Değerlendirilmiş Kayıtlar
  t_alerts: 'Оповещения', // Uyarılar
  t_audioEncoding: "Аудио кодирование", // Audio Encoding
  t_gender: "Пол голоса", // Ses Cisiyeti
  t_token: "Токен", // Token
  t_showTCKN: "Показать TCKN", // TCKN Göster
  t_webChat: "Веб-чат", // Web Chat
  t_whatsapp: "Whatsapp", // Whatsapp
  t_instagram: "Instagram", // Instagram
  t_messenger: "Messenger", // Messenger
  t_informAgent: 'Информировать агента', // Temsilciyi Bilgilendir
  t_autoCustomerCard: 'Автоматическое открытие карточки клиента', // Otomatik Kart Açma
  t_downloadCallRecordings: 'Скачать записи звонков', // Ses Kaydı İndirme
  t_outboundCallApproval: 'Подтверждение исходящего звонка', // Çağrı Onaylama
  t_callHistoryDownloadRecording: 'Скачать запись истории звонков', // Çağrı Geçmişi Ses Kaydı İndirme
  t_callHistoryListenRecording: 'Прослушать запись истории звонков', // Çağrı Geçmişi Ses Kaydı Dinleme
  t_dialerReopenTotalAttempts: 'Общее количество попыток дозвона', // Dialer Toplam Temas
  t_dialerReopenDailyAttempts: 'Ежедневное количество попыток дозвона', // Dialer Günlük Temas
  t_dialerClearCallSchedules: 'Очистить запланированные звонки', // Dialer Zamanı Gelmeyenler
  t_showAllFinishCodes: 'Показать все коды завершения', // Tüm Sonuç Kodları Göster
  t_finishcodesofcallsfortoday: "Коды завершения звонков за сегодня", // Bugün gerçekleşen çağrıların sonuç kodları
  t_updateLastFinishCode: 'Обновить последний код завершения', // Son Sonuç Kodu Güncelleme
  t_showIVRInputs: 'Показать вводы IVR', // IVR Tuşlamalarını Görüntüle
  t_hidePhoneNumbers: 'Скрыть номера телефонов', // Telefon Numaralarını Gizle
  t_addExtraData: 'Добавить дополнительные данные', // Ekstra Data Ekleme
  t_script: 'Сценарий', // Script
  t_crmCall: 'Звонок', // Arama
  t_crmAddCustomer: 'Добавить клиента', // Müşteri Ekleme
  t_displayAllRecords: 'Показать все записи', // Tüm Dosyaları Görüntüleme
  t_editCustomer: 'Редактировать клиента', // Müşteri Güncelleme
  t_crmlistenRecording: 'Прослушать запись в CRM', // CRM Ses Kaydı Dinleme
  t_showNoteDetails: 'Показать детали заметки', // Not Detayını Göster
  t_excelCustomer: 'Клиент Excel', // Excel Müşteri
  t_excelCustomerByPhone: 'Клиент Excel по телефону', // Excel Telefon
  t_excelCustomerByProduct: 'Клиент Excel по продукту', // Excel Ürün
  t_showPhoneDetails: 'Показать детали телефона', // Telefon Detayını Göster
  t_justShowOwnCustomers: 'Показывать только своих клиентов', // Sadece Kendi Müşterileri Göster
  t_dontSendCustomMessage: 'Не изменять текст сообщения', // Mesaj Metni Değiştiremesin
  t_keywordCategories: "Категории ключевых слов", // Anahtar Kelime Kategorisi
  t_keyWord: "Ключевое слово", // Anahtar Kelime
  t_minOccurrence: "Минимальное количество вхождений", // En Az Bulunma
  t_maxOccurrence: "Максимальное количество вхождений", // En Çok Bulunma
  t_inFirstSeconds: "В первые секунды", // İlk Saniyelerде
  t_inLastSeconds: "В последние секунды", // Son Saniyelerде
  t_logicCondition: "Логическое условие", // Mantıksal Koşul
  t_reverse: "Обратное", // Tersi
  t_and: "И", // Ve
  t_or: "Или", // Veya
  t_customer: "Клиент", // Müşteri
  t_oneOfBoth: "Один из двух", // İkisinden Biri
  t_bothOfThem: "Оба", // İkiside
  t_liveDashboards: 'ЖИВЫЕ ЭКРАНЫ', // CANLI EKRANLAR
  t_tools: 'ИНСТРУМЕНТЫ', // ARAÇLAR
  t_tools2: 'Инструменты', // Araçlar
  t_admin: 'АДМИНИСТРАТОР', // YÖNETİCİ
  t_useDatesForEvaluateDate: 'Использовать фильтр даты как дату оценки', // Tarih filtresini, değerlendirme tarihi olarak kullan
  t_menuLineGroups: 'Меню - Группы линий', // Menü - Hat Grubu
  t_menuDialPlans: 'Меню - Планы звонков', // Menü - Arama Planı
  t_menuIVR: 'Меню - IVR', // Menü - IVR
  t_menuAnnouncements: 'Меню - Объявления', // Menü - Anonslar
  t_menuRoutes: 'Меню - Маршруты', // Menü - Yönlendirmeler
  t_menuTimeCondition: 'Меню - Условие времени', // Menü - Zaman Koşulu
  t_menuUsers: 'Меню - Пользователи', // Menü - Kullanıcılar
  t_menutExtensionRoutes: 'Меню - Маршруты', // Menü - Rotalar
  t_menuQueues: 'Меню - Очереди', // Menü - Kuyруklar
  t_menuProjects: 'Меню - Проекты', // Menü - Projeler
  t_menutStatuses: 'Меню - Статусы', // Menü - Durumlar
  t_menutFinishCodes: 'Меню - Коды завершения', // Menü - Sonuç Kodları
  t_menuBlackList: 'Меню - Черный список', // Menü - Kara Liste
  t_menuShifts: 'Меню - Смены', // Menü - Vardiyalar
  t_menuProfiles: 'Меню - Профили', // Menü - Profiller
  t_menuRecordingExport: 'Меню - Экспорт записей', // Menü - Ses Export
  t_menuSms: 'Меню - SMS', // Menü - SMS
  t_menuReports: 'Меню - Отчеты', // Menü - Raporlar
  t_menuGoalManagement: 'Меню - Управление целями', // Menü - Hedef Yönetimi
  t_menuDialer: 'Меню - Автодозвон', // Menü - Otomatik Arama
  t_menuLiveDashboard: 'Меню - Живая панель', // Menü - Canlı İzleme
  t_menuPhoneBook: 'Меню - Телефонная книга', // Menü - Rehber
  t_menuCalendar: 'Меню - Календарь', // Menü - Takvim
  t_menuCrm: 'Меню - CRM', // Menü - CRM
  t_menuDashboard: 'Меню - Панель', // Menü - Pano
  t_menuEvaluatedRecords: 'Меню - Оцененные записи', // Menü - Değerlendirilmiş Kayıtlar
  t_menuSystemManagement: 'Меню - Управление системой', // Menü - Sistem Yönetimi
  t_menuOperationManagement: 'Меню - Управление операциями', // Menü - Operasyon Yönetimi
  t_menuIntegrations: 'Меню - Интеграции', // Menü - Entegrasyonlar
  t_menuSettings: 'Меню - Настройки', // Menü - Ayarlar
  t_menuWallboard: 'Меню - Wallboard', // Menü - Wallboard
  t_menuDashboard: 'Меню - Панель', // Menü - Dashboard
  t_menuQueueGroup: 'Меню - Группа очередей', // Menü - Kuyрук Grubu
  t_menuvIVR: 'Меню - Переменный IVR', // Menü - Değişkenli IVR
  t_menuScheduler: 'Меню - Планировщик', // Menü - Planlayıcı
  t_menuExternalChat: 'Меню - Текстовый канал', // Menü - Yazılı Kanal
  t_menuSurvey: 'Меню - Опрос', // Menü - Anket
  t_menuEMail: 'Меню - Электронная почта', // Menü - E-Mail
  t_menuAlerts: 'Меню - Оповещения', // Menü - Uyarılar
  t_menuQuality: 'Меню - Качество', // Menü - Kalite
  t_otherAgentList: 'Другое - Список агентов', // Diğer - Temsilci Listesi
  t_othertAutoCustomerCard: 'Другое - Автоматическое открытие карточки клиента', // Diğer - Otomatik Kart Açma
  t_otherDownloadCallRecordings: 'Другое - Скачать записи звонков', // Diğer - Ses Kaydı İndirme
  t_otherOutboundCallApproval: 'Другое - Подтверждение исходящего звонка', // Diğер - Çağrı Onaylama
  t_otherCallHistoryDownloadRecording: 'Другое - Скачать запись истории звонков', // Diğер - Çağrı Geçmişi Ses Kaydı İndirme
  t_otherCallHistoryListenRecording: 'Другое - Прослушать запись истории звонков', // Diğер - Çağrı Geçmişi Ses Kaydı Dinleme
  t_otherDialerReopenTotalAttempts: 'Другое - Открыть закрытые по общим попыткам', // Diğер - Dialer Toplam Temastan Kapananları Açma
  t_otherDialerReopenDailyAttempts: 'Другое - Открыть закрытые по ежедневным попыткам', // Diğер - Dialer Günlük Temastan Kapananları Açma
  t_otherDialerClearCallSchedules: 'Другое - Открыть запланированные звонки', // Diğер - Dialer Zamanı Gelmeyenleri Açma
  t_otherShowAllFinishCodes: 'Другое - Показать все коды завершения', // Diğер - Tüm Sonuç Kodları Göster
  t_otherUpdateLastFinishCode: 'Другое - Обновить последний код завершения', // Diğер - Son Sonuç Kodu Güncelleme
  t_otherShowIVRInputs: 'Другое - Показать вводы IVR', // Diğер - IVR Tuşlamalarını Görüntüle
  t_otherHidePhoneNumbers: 'Другое - Скрыть номера телефонов', // Diğер - Telefon Numaralarını Gizle
  t_ChatSouffle: 'Чат - Суфле', // Chat - Sufle
  t_chatChat: 'Чат - Чат', // Chat - Mesajlaşma
  t_chatAnnounce: 'Чат - Объявление', // Chat - Duyuru
  t_crmFilters: 'CRM - Фильтры', // Crm - Filtreler
  t_crmColumns: 'CRM - Столбцы', // Crm - Başlıklar
  t_crmManagement: 'CRM - Управление', // Crm - Yönetim
  t_crmAddExtraData: 'CRM - Добавить дополнительные данные', // Crm - Ekstra Data Ekleme
  t_crmScript: 'CRM - Сценарий', // Crm - Script
  t_crmProducts: 'CRM - Продукты', // Crm - Ürünler
  t_crmPhones: 'CRM - Телефоны', // Crm - Telefonlar
  t_crmNotes: 'CRM - Заметки', // Crm - Notlar
  t_crmSms: 'CRM - SMS', // Crm - SMS
  t_crmSmsSending: 'CRM - Отправка SMS', // Crm - SMS Gönderim
  t_crmCrmCall: 'CRM - Звонок', // Crm - Arama
  t_crmCrmAddCustomer: 'CRM - Добавить клиента', // Crm - Müşteri Ekleme
  t_crmDisplayAllRecords: 'CRM - Показать все записи', // Crm - Tüm Dosyaları Görüntüleme
  t_crmEditCustomer: 'CRM - Редактировать клиента', // Crm - Müşteri Güncelleme
  t_crmCrmlistenRecording: 'CRM - Прослушать запись в CRM', // CRM - CRM Ses Kaydı Dinleme
  t_crmShowNoteDetails: 'CRM - Показать детали заметки', // Crm - Not Detayını Göster
  t_crmExcelCustomer: 'CRM - Клиент Excel', // Crm - Excel Müşteri Bazlı
  t_crmExcelCustomerByPhone: 'CRM - Клиент Excel по телефону', // Crm - Excel Telefon Bazlı
  t_crmShowPhoneDetails: 'CRM - Показать детали телефона', // Crm - Telefon Detayını Göster
  t_rTransferType: "Тип перевода", // Transfer Tipi
  t_rTransferTarget: "Цель перевода", // Transfer Hedefi
  t_acw: "Время ACW", // ACW Süresi
  t_rDialerConnectedToAgent: "Автодозвон подключен к агенту", // Oto. Arama Bağlanan
  t_rDialerAbandon: "Автодозвон пропущен", // Oto. Arama Kaçan
  t_rACWLength: "Время ACW", // ACW Süresi
  t_callAnalize: "Анализ звонка", // Çağrı Analizi
  t_callFinishCode: "Звонок", // Çağrı
  t_writtenChannelFinishCode: "Текстовый канал", // Yazılı Kanal
  t_productFinishCode: "Продукт", // Ürün
  t_finishCodeType: "Тип кода завершения", // Sonuç Kodu Tipi
  t_speechText: "Текст речи", // Konuşma Metni
  t_reportGroup: "Группировка", // Gruplama
  t_pleaseSelect: "Пожалуйста, выберите", // Seçim Yapınız
  t_awaitingInteractionsCount: "Ожидающие", // Bekleyen
  t_descawaitingInteractionsCount: "Общее количество ожидающих звонков в выбранных очередях", // Seçili kuyruklarda anlık bekleyen çağrı toplamı
  t_answeredCount: "Отвеченные", // Cevaplanan
  t_descansweredCount: "Общее количество отвеченных звонков в выбранных очередях", // Seçili kuyruklarda toplam cevaplanan çağrı sayısı
  t_abandonedCount: "Пропущенные", // Kaçan
  t_descabandonedCount: "Общее количество пропущенных звонков в выбранных очередях", // Seçili kuyruklarda toplam kaçan çağrı sayısı
  t_activeInteractionsCount: "Текущее количество звонков", // Anlık Çağrı Sayısı
  t_descactiveInteractionsCount: "Общее количество текущих звонков в выбранных очередях", // Seçili kuyruklarda toplam anlıк çağrı sayısı
  t_averageWaitTime: "Среднее время ожидания", // Ortalama Bekletme Süresi
  t_descaverageWaitTime: "Среднее время ожидания входящих звонков в выбранных очередях", // Seçili kuyruklarda gelen çağrıların kuyrukta bekeme sürelerinin ortalaması
  t_callBackSuccess: "Успешный перезвон", // Başarılı Geri Arama
  t_desccallBackSuccess: "Количество перезвонов на пропущенные звонки", // Kaçan çağrılara yapılan geri dönüşlerin sayısı
  t_firstCallResolutionCount: "Решение при первом звонке", // İlk Çağrıda Çözüm
  t_descfirstCallResolutionCount: "Количество звонков, которые были завершены с успешным кодом завершения после одного разговора", // Sadece bir kere görüşülmüş olup, başarılı kategorisinde oluşturulmuş sonuç kodları ile kapanan çağrıların sayısı
  t_serviceLevel: "Уровень обслуживания", // Servis Seviyesi
  t_descserviceLevel: "Процент отвеченных звонков в пределах установленного уровня обслуживания", // Kuyрук ayarlarında belirlenen servis seviyeleri içerisinde, cevaplanan çağrı sayılarının tüm gelen çağrılara oranı
  t_sRLevel: "Уровень успеха", // Başarı Oranı
  t_descsRLevel: "Процент звонков, завершенных с успешным кодом завершения", // Başarılı olan sonuç kodları ile tamamlanan çağrıların tüm çağrılara oranı
  t_averageConnectedTime: "Среднее время звонка", // Ortalama Çağrı Süresi
  t_descaverageConnectedTime: "Среднее время всех разговорных звонков", // Tüm görüşülen çağrıların ortalama süresi
  t_abandonRate: "Процент пропущенных звонков", // Kaçan Çağrı Oranı
  t_descabandonRate: "Процент пропущенных звонков в выбранных очередях", // Seçili kuyruklarda gelen ve cevaplanmayan çağrıların tüm çağrılara oranı
  t_transferQueue: "Перевод очереди", // Kuyрук Aktar
  t_rAvgQueueLength: "Среднее время ожидания в очереди", // Ort. Kuyrukta Bekleme Süresi
  t_crmlistenRecordingJustOwn: "Прослушивать только свои звонки", // Sadece Kendi Çağrılarını Dinle
  t_deleteQualityForm: "Удалить форму качества", // Kalite Formunu Sil
  t_currentPasswordDoesNotMatch: "Текущий пароль неверен", // Mevcut Parola Yanlış
  t_confirmPasswordDoesNotMatch: "Подтвержденный пароль не совпадает", // Onaylanan Parola Eşleşmiyor
  t_rRinging: "Звонок", // Çalıyor
  t_rEnterIVR: "Перенаправлено в IVR", // IVR Yönlendirildi
  t_ranswer: "IVR ответил", // IVR Cevapladı
  t_rStartIvr: "IVR начался", // IVR Başladı
  t_rTimeConditionFail: "Условие времени не выполнено", // Zaman Koşulu Sağlanmadı
  t_rTimeConditionSuccess: "Условие времени выполнено", // Zaman Koşulu Sağlandı
  t_rStartIvrMenu: "Меню", // Menü
  t_rMenuAttempt: "Попытка", // Temas
  t_rMenuAnnouncement: "Объявление", // Anons
  t_rDtmfResult: "Результат набора", // Tuşlama Sonucu
  t_rTaskmenu: "Меню", // Menü
  t_rTasksurvey: "Опрос", // Anket İşlemi
  t_rStartPreSurveyTask: "Начало опроса", // Anket Başlangıcı
  t_rPreSurveyAttempt: "Попытка", // Temas
  t_rPreSurveyAnnouncement: "Объявление опроса", // Anket Anonsu
  t_rPreSurveyResults: "Результаты опроса", // Anket Sonucu
  t_rTaskcondition: "Условие", // Koşul
  t_rConditionFail: "Условие не выполнено", // Koşul Sağlanmadı
  t_rTaskvariable: "Переменная", // Değişken
  t_rStartVariableTask: " ", // 
  t_rTaskannounce: "Объявление", // Anons
  t_rVariableAttempt: "Попытка", // Temas
  t_rVariableAnnouncement: "Объявление переменной", // Değişken Anonsu
  t_rTaskhttp: "HTTP запрос выполнен", // HTTP İsteği Yapıldı
  t_rVariableFailed: "Ошибка переменной", // Değişken Hatası
  t_rFailedTaskvariable: "Ошибка обработки переменной", // Hatalı Değişken İşlemi
  t_rMenuFailed: "Ошибка меню", // Menü Hatası
  t_rFailedTaskmenu: "Ошибка обработки меню", // Hatalı Menü İşlemi
  t_rConditionError: "Ошибка условия", // Koşul Hatası
  t_rFailedTaskcondition: "Ошибка обработки условия", // Hatalı Koşul İşlemi
  t_rTimeConditionError: "Ошибка условия времени", // Zaman Koşulu Hatası
  t_rIvrFailed: "IVR завершен", // IVR Sonlandırıldı
  t_rFailedInboundCall: "Звонок завершен", // Arama Sonlandırıldı
  t_rFinalized: "Завершено", // Tamamlandı
  t_rJoinedQueue: "В очереди", // Kuyruğa Girdi
  t_rAssigned: "Назначено", // Atama Yapıldı
  t_rAccepted: "Принято", // Kabul Edildi
  t_rPickedUp: "Отвечено", // Açıldı
  t_rRemoteFailed: "Ошибка номера", // Karşı Numara Hatalı
  t_rConditionSuccess: "Условие выполнено", // Koşul Sağlandı
  t_rTaskannounce: "Объявление", // Anons
  t_rTaskqueue: "Очередь", // Kuyрук
  t_roriginate: "Звонок", // Arama
  t_rcall_target: "Цель звонка", // Çağrı Hedefi
  t_rnew_bridge: "Новый канал", // Yeni Kanal
  t_rbridge_created: "Канал создан", // Kanal Oluşturuldu
  t_rcalls_bridged: "Каналы соединены", // Kanallar Bağlandı
  t_rcall_transferred: "Перевод", // Transfer
  t_rUnassigned: "Назначение отменено", // Atama Geri Alındı
  t_rAbandoned: "Пропущено", // Kaçan
  t_rRejected: "Отклонено", // Reddedildi
  t_rNoAnswer: "Не отвечено", // Cevaplanmadı
  t_rFailedTaskannounce: " ", // 
  t_rTransferAssigned: "Перевод назначен", // Transfer için Atandı
  t_rTransferAccepted: "Перевод принят", // Transfer Kabul Edildi
  t_rnull: " ", // 
  t_rTaskivr: "IVR", // IVR
  t_rTasktransfer: "Перевод", // Transfer
  t_rEndCall: "Звонок завершен", // Çağrı Kapatıldı
  t_rFailedTaskhttp: "Ошибка HTTP запроса", // Http İşlem Hatası
  t_rTotalLocal: "Общее локальное завершение", // Toplam Lokal Kapatma
  t_rInboundLocal: "Локальное завершение входящих", // Gelen Aramalar Lokal Kapatma
  t_rOutboundLocal: "Локальное завершение исходящих", // Giden Aramalar Lokal Kapatма
  t_rAvgDialingTime: "Среднее время звонка", // Ortalama Çaldırma Süresi
  t_rTotalInCallTime: "Общее время на звонке", // Toplam Çağrıda Süresi
  t_rTotalStatusTime: "Общее время статуса", // Toplam Durum Süresi
  t_rTotalHoldCount: "Общее количество удержаний", // Toplam Bekletme Sayısı
  t_rTotalHoldTime: "Общее время удержания", // Toplam Bekletme Süresi
  t_rAvgHandleTime: "Среднее время обработки", // Ortalama Handle Süresi
  t_rLogoutCount: "Количество выходов", // Logout Sayısı
  t_rProductivityTime: "Время производительности", // Verimlilik Süresi
  t_rBreakCategoryTime: "Использованное время перерыва", // Kullanılan Mola Süresi
  t_rAvgAlertTime: "Среднее время звонка", // Ortalama Çalma Süresi
  t_addFile: "Добавить файл", // Dosya Ekle
  t_appointment: "Назначение", // Randevu
  t_appointments: "Назначения", // Randevular
  t_second: "Секунда", // Saniye
  t_notAnsweringCallDesc2: "Вы находитесь в статусе Not Answering уже 1 минуту, пожалуйста, перейдите в статус Available для продолжения.", // 1 dakikadır Not Ansering durumundasınız, lütfen devam etmek için Available durumuna geçiniz.
  t_notAnsweringCallDesc: "Вы не ответили на звонок, пожалуйста, перейдите в статус Available для продолжения.", // Çağrıya cevap vermediniz, devam etmek için Available durumuna geçiniz.
  t_notAnsweringCall: "Неотвеченный звонок", // Cevap Verilmeyen Çağrı
  t_timeFormat: "Формат времени", // Zaman Formatı
  t_callGroup: "Группа звонков", // Arama Grubu
  t_pickupGroup: "Группа ответа", // Cevaplama Grubu
  t_fodlerType: "Тип папки", // Kalsörleme Formatı
  t_conferenceCallAccepted: "Звонок подключен", // Çağrı Bağlandı
  t_conferenceCallHangup: "Звонок завершен", // Çağrı Sonlandırıldı
  t_conferenceCallDeclined: "Звонок не подключен", // Çağrı Bağlanamadı
  t_conferenceCalling: "Звонок выполняется", // Arama Yapılıyor
  t_agentListDetails: "Детали списка агентов", // Temsilci Listesi Detay
  t_rAgentGroup: "Группа агентов", // Temsilci Grubu
  t_new: "Новый", // Yeni
  t_recordsActivityLog: "Лог активности записей", // Kayıt Dinleme Log
  t_isAutoForm: "Автоматическая форма", // Otomatik Form
  t_timestamp: 'Метка времени', // Zaman Damgası
  t_verificationCode: "Код подтверждения", // Doğrulama Kodu
  t_verificationCodeDesc: "Пожалуйста, введите 6-значный код подтверждения", // Lütfen 6 haneli doğrulama kodunu giriniz
  t_verify: "Подтвердить", // Onayla
  t_phone: "Телефон", // Telefon
  t_downloadVoyceVoiceConverter: "Скачать конвертер голоса Voyce", // Voyce Ses Dönüştürücü İndir
  t_position: "Позиция", // Pozisyon
  t_ivrBreakOut: "Выход из очереди", // Kuyрук Çıkışı
  t_positionAndIvrBreakOut: "Позиция и выход из очереди", // Pozisyon ve Kuyрук Çıkışı
  t_ivrBreakOutTarget: "Цель выхода из очереди", // Kuyрук Çıkış Hedefi
  t_displayAllCalendar: "Показать все календари", // Tüm Tavimleri Görüntüle
  t_appointmentAssignmentDesc: "Вам назначены новые встречи. Пожалуйста, проверьте календарь.", // Adınıza yeni randevular atanmıştır. Lütfen takvim kontrolü yapınız.
  t_appointmentAssignment: "Назначение встречи", // Randevu Ataması
  t_displayCalls: 'Отображение звонков', // Çağrı İzleme
  t_showUsedAreas: "Показать используемые области", // Kullanılan Yerler
  t_ftp: "FTP", // FTP
  t_voiceExport: "Экспорт голоса", // Ses Export
  t_putFtp: "Отправить FTP", // Ftp Gönder
  t_remotePath: "Удаленный путь", // Uzak Dosya Yolu
  t_passive: 'Пассивный', // Pasif
  t_passives: 'Пассивные', // Pasifler
  t_state: 'Состояние', // Durum
  t_frequentlyAskedQuestions: 'Часто задаваемые вопросы', // Sıkça Sorulan Sorular
  t_helpCenter: 'Центр помощи', // Yardım Merkezi
  t_contactUs: 'Свяжитесь с нами', // Bize Ulaşın
  t_isSecure: 'Безопасное соединение', // Güvenli Bağlantı
  t_rejectUnauthorized: 'Отклонить неавторизованные', // Yetkisiz Olanı Reddet
  t_customMetaData: 'Настройка метаданных', // Meta Data Özelleştir
  t_metaDataEndpoint: 'Конечная точка метаданных', // Meta Data Endpoint
  t_metaDataRemotePath: 'Удаленный путь метаданных', // Meta Data Uzak Dosya Yolu
  t_putAsFolder: 'Отправить как папку', // Klasör Olarak Gönder
  t_isDefault: 'По умолчанию', // Varsayılan
  t_rSLDuration: 'Продолжительность SL', // SL Süresi
  t_twoFactorAuthentication: "Двухфакторная аутентификация", // İki Aşamalı Doğrulama
  t_sentToPhoneNumber: "отправлено на номер телефона", // telefon numarasına gönderilen
  t_sentToEmailAdress: "отправлено на адрес электронной почты", // mail adresine gönderilen
  t_verifyYourIdentity: "введите код подтверждения для проверки вашей личности", // doğrulama kodunu girerek, kimliğinizi doğrulayın
  t_remainingTime: "Оставшееся время", // Kalan Süre
  t_sec: "сек", // sn
  t_donthaveAccess: "У вас нет доступа к этому аккаунту связи?", // Bu iletişim hesabına erişiminiz yok mu?
  t_verificationCode: "Код подтверждения", // Doğrulama kodunu
  t_sendAsMail: "отправить по почте.", // mail ile gönder.
  t_sendAsSms: "отправить по SMS", // sms ile gönder
  t_verify: "Подтвердить", // Doğrula
  t_closeProduct: "Закрыть продукт", // Ürün Kapama
  t_setPassiveWhenNoProduct: 'Перевести в пассив, если нет продукта', // Ürünü olmayan kayıtları, pasife çek
  t_sendHttpRequest: "HTTP запрос", // Http
  t_month2: "Месяц (без нуля)", // Ay (Sıfırsız)
  t_day2: "День (без нуля)", // Gün (Sıfırsız)
  t_shifts: "Смены", // Vardiyalar
  t_shift: "Смена", // Vardiya
  t_missingTime: 'Потерянное время', // Kayıp Zaman
  t_isAnnualLeave: 'Годовой отпуск', // Yıllık İzin
  t_paidLeave: 'Оплачиваемый отпуск', // Ücretli İzin
  t_unpaidLeave: 'Неоплачиваемый отпуск', // Ücretsiz İzin
  t_isOwner: 'Собственник', // Kendisi
  t_owner: 'Владелец', // Sahibi
  t_thirdParty: 'Третья сторона', // Üçüncü Şahıs
  t_breakTimeFraud: 'Превышение времени перерыва', // Mola Aşımı
  t_dontControlLastTenDigit: 'Не проверять последние 10 цифр', // Sağdan 10 hane kontrolü yapma
  t_bank: 'Банк', // Banka
  t_dontChangeSurvey: 'Не изменять статус опроса', // Anket Durumunu Değiştirme
  t_finishCodeBreakdown: 'Разбивка кода завершения', // Sonuç Kodu Kırılıмı
  t_displayPassiveRecords: 'Показать пассивные записи', // Pasif Kayıtları Gör
  t_newAssets: "Новые активы", // Yeni Varlık
  t_reached: "Достигнуто", // Kendisi
  t_reachedDate: "Дата достижения", // Kendisi Tarihi
  t_dontGetNoQueueRecords: "Не получать записи без очереди", // Kuyruğu olmayan kayıtları getirme
  t_agentHaveStaticQueueButDontHaveProjectPermission: 'У агента есть статическая очередь, но нет разрешения на проект.', // Temsilcinin statik kuyruğu var ama proje izni yok.
  t_autoRecall: 'Автоперезвон', // Tekrar Ara
  t_autoRecallDay: 'Дней спустя', // Gün Sonra
  t_rDateName: "День", // Gün
  t_rWeekOfMonth: "Неделя месяца", // Ayın Haftası
  t_rGivenBreakTime: "Время перерыва", // Mola Hakkı
  t_rOfflineTime: "Время оффлайн", // Offline Süresi
  t_rMonth: "Месяц", // Ay
  t_rBreakTimeDifference: "Разница во времени перерыва", // Mola Farkı
  t_copyFromTheEditor: "Копировать из редактора", // Editörden Kopyala
  t_pasteIntoTheEditor: "Вставить в редактор", // Editöre Yapıştır
  t_duplicate: "Дублировать", // Çoğalt
  t_identifier: "Идентификатор", // TCKN
  t_undeafen: 'Включить звук', // Sesi Kapat
  t_deafen: 'Отключить звук', // Sesi Aç
  t_canTransfer: 'Можно перевести', // Transfer Edilebilir
  t_wordDistance: 'Расстояние между словами', // Kelime Uzaklığı
  t_segmentDistance: 'Расстояние между сегментами', // Segment Uzaklığı
  t_maxWordGroupCount: 'Макс. количество групп слов', // Max. Kelime Grubu
  t_trendAnalyze: 'Анализ тренда', // Trend Analizi
  t_realTimeTranscript: 'Транскрипция в реальном времени', // Gerçek Zamanlı Transkript
  t_snapshots: "Снимки", // Snapshotlar
  t_snapshotDate: "Дата снимка", // Snapshot Tarihi
  t_firstCallDate: "Дата первого звонка", // İlk Çağrı Tarihi
  t_lastCallDate: "Дата последнего звонка", // Son Çağrı Tarihi
  t_callCount: "Количество звонков", // Çağrı Sayısı
  t_density: "Плотность", // Yoğunluk
  t_avarageDensity: "Средняя плотность", // Ortalama Yoğunluk
  t_displayDensityStatistics: 'Показать график плотности', // Yoğunluk Grafiğini Göster
  t_hideFromAgent: 'Скрыть от агента', // Temsilciden Gizle
  t_customerNo: "Номер клиента", // Müşteri No
  t_customerName: "Имя Фамилия", // Adı Soyadı
  t_customerIdentifier: "Идентификатор клиента", // TCKN
  t_reached: "Достигнуто", // Ulaşım
  t_reachedDate: "Дата достижения", // Ulaşım Tarihi
  t_productNo: "Номер продукта", // Ürün No
  t_artificialIntelligence: "Искусственный интеллект", // Yapay Zeka
  t_speakers: "Спикеры", // Konuşmacı
  t_oneTime: "Однократно", // Tek Sefer
  t_scheduled: "Запланировано", // Planlı
  t_automatic: "Автоматически", // Otomatik
  t_periodTime: "Время периода", // Periyot Zamanı
  t_prompt: "Подсказка", // Prompt
  t_aiCallResult: "Результат звонка AI", // AI Çağrı Sonucu
  t_exceptions: "Исключения", // İstisnalar
  t_rSendingResult: "Результат отправки", // Gönderim Sonucu
  t_rReachRate: "Процент достижения", // Ulaşım Oranı
  t_rReachedCustomerCount: "Количество достигнутых клиентов", // Görüşülen Müşтерi
  t_rProcessedRate: "Процент обработки", // Tarama Oranı
  t_rCalledCustomerCount: "Количество вызванных клиентов", // Aranan Müşтерi
  t_rCustomerCount: "Общее количество клиентов", // Toplam Müşтерi
  t_rCompany: "Компания", // Firma
  t_rCustomerDataAnalysis: "Анализ данных клиента", // Müşteri Tarama Analizi
  t_crmDisplayAllLogs: "Показать все логи", // Tüm Logları Görüntüle
  t_aiCallAnalize: "Анализ звонков AI", // AI Çağrı Analizi
  t_aiCallEvaluation: "Оценка звонков AI", // AI Çağrı Değerlendirme
  t_AIEvaluations: "AI", // AI
  t_redirectTheCallToSelf: "Перенаправить звонок на себя", // Çağrıyı Kendine Yönlendir
  t_hangupTheCall: "Завершить звонок", // Çağrıyı Sonlandır
  t_holdOrUnholdTheCall: "Удержать/Продолжить звонок", // Çağrıyı Beklet/Sürdür
  t_limit: "Лимит", // Limit
  t_playSound: "Воспроизвести звук", // Ses Çal
  t_lastFailedLogin: "Последний неудачный вход", // Son Hatalı Giriş
  t_displayQueueAbandonDetails: "Показать детали пропущенных звонков", // Kaçan Çağrı Detayları
  t_triggerImmediately: "Немедленно активировать", // Hemen Tetikle
  t_mode: "Режим", // Mod
  t_triggerOnCrmCardOpen: "Активировать при открытии карточки CRM", // CRM Kartı Açıldığında Tetikle
  t_activities: "Действия", // Hareketler
  t_sendAsAttachment: "Отправить как вложение", // Ek Olarak Gönder
  t_outputFileName: "Имя выходного файла", // Çıkış Dosya Adı
  t_viewSingleCustomerCard: 'Просмотреть карточку одного клиента' // Tek Müşteri Kartı Görüntüle
}