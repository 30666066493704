export default {
  t_welcome: "Welcome", //Login
  t_copyright: "All Rights Reserved ©️ 2023 ver3.1.0",
  t_copy: "Copy",
  t_userLogin: "User Login",
  t_userName: "Username",
  t_password: "Password",
  t_login: "Login",
  t_hello: "Hello",
  t_usernameOrPasswordIsInvalid: "Username or password is incorrect.",
  t_successfullyLoggedIn: "Successfully logged in.",
  t_pleaseSelectQueueAndClassification: "Please select queue and classification",
  t_PleaseSelectProjetAndQueues: "Please select project and queue",
  t_pleaseSingIn: "Please sign in",
  t_rememberMe: "Remember Me",
  t_signIn: "Sign In",
  t_changeStatusAfterTheCall: "Change status after the call", // LoyoutContentRedererDefault.vue
  t_remainingTime: "Remaining Time",
  t_continue: "Continue",
  t_hold: "Hold",
  t_microphoneOn: "Microphone On",
  t_microphoneOff: "Microphone Off",
  t_inputList: "Input List",
  t_showInputs: "Show Inputs",
  t_chatPanel: "Chat Panel",
  t_showDetail: "Show Detail",
  t_connected: "Connected", // Navbar.vue
  t_disconnected: "Disconnected",
  t_dayMode: "Day Mode",
  t_nightMode: "Night Mode",
  t_agentList: "Agent List",
  t_logOut: "Log Out", // AgentTargets
  t_collectionTargeting: "Targeting",
  t_targeting: "Targeting",
  t_dashboard: "Dashboard",
  t_search: "Search",
  t_add: "Add",
  t_targetedMonth: "Targeted Month",
  t_confirm: "Confirm",
  t_areYouSureYouWantToDeleteTheSelectedRecord: "Are you sure you want to delete the selected record?",
  t_areYouSureYouWantToUpdateTheSelectedRecords: "Are you sure you want to update the selected records?",
  t_areYouSureYouWantToClearAll: "Are you sure you want to clear all?",
  t_selectTheAgent: "Select the Agent",
  t_collectedDate: "Collected Date",
  t_collectedTime: "Collected Time",
  t_edit: "Edit",
  t_configuration: "CONFIGURATION",
  t_completed: "Completed",
  t_remaining: "Remaining",
  t_compilationRate: "Completion Rate",
  t_minute: "Minute",
  t_targetCompletion: "Target Completion",
  t_hour: "Hour",
  t_day: "Day",
  t_week: "Week",
  t_month: "Month",
  t_year: "Year",
  t_weekday: "Weekday",
  t_lastOneHour: "Last 1 Hour",
  t_lastThreeHours: "Last 3 Hours",
  t_twoDaysAgo: "2 Days Ago",
  t_threeDaysAgo: "3 Days Ago",
  t_fourDaysAgo: "4 Days Ago",
  t_fiveDaysAgo: "5 Days Ago",
  t_sixDaysAgo: "6 Days Ago",
  t_period: "Period",
  t_thisWeek: "This Week",
  t_lastWeek: "Last Week",
  t_get: "Get",
  t_saveFilters: "Save Filters",
  t_target: "Target",
  t_announcements: "Announcements", // Announcements
  t_announcementName: "Announcement Name",
  t_selectAudioFile: "Select Audio File",
  t_announcementList: "Announcement List",
  t_areYouSure: "Are You Sure?",
  t_ok: "OK",
  t_count: "Count",
  t_CompletedAmount: "Completed Amount",
  t_dailyTarget: "Daily Target",
  t_monthlyTarget: "Monthly Target",
  t_dailyTimeline: "Daily Timeline",
  t_blackList: "Blacklist", // BlackListManagement
  t_descriptions: "Descriptions",
  t_inbound: "Inbound",
  t_outbound: "Outbound",
  t_number: "Number",
  t_callHistory: "Call History", // CallHistory
  t_numberHistory: "Number History",
  t_notes: "Notes",
  t_oldNotes: "Old Notes",
  t_campaigns: "Campaigns", // Campaigns
  t_campaign: "Campaign",
  t_campaignName: "Campaign Name",
  t_campaignList: "Campaign List",
  t_campaignhasBeenDeletedSuccessfully: "Campaign successfully deleted.",
  t_campaignhasBeenUpdatedSuccessfully: "Campaign successfully updated.",
  t_campaignhasBeenCreatedSuccessfully: "Campaign successfully created.",
  t_customTabs: "Custom Tabs", // CustomTabs
  t_address: "Address",
  t_landlinePrefix: "Landline Prefix",
  t_fctPrefix: "FCT Prefix",
  t_customTabList: "Custom Tab List",
  t_dataManagement: "Data Management", // DataUpload
  t_productManagement: "Product Management",
  t_scriptManagement: "Script Management",
  t_exampleFile: "Sample Excel",
  t_uploadFile: "Upload File",
  t_SelectTheTitlesToUpdate: "Please select titles to update",
  t_setUnmatchedRecordsToPassive: "Set unmatched records to passive",
  t_setUnmatchedRecordsToUpdate: "Update phone numbers and products of matched records",
  t_setAllPreviousInstitutionDataToPassive: "Set all data for the institution to passive",
  t_updateMatchedRecords: "Update matched records",
  t_setAllRecordsOfProjectNameToPassive: "Set all data for the institution to passive",
  t_upload: "Upload",
  t_0customersHasBeenUpdatedSuccessfully: "0 customers successfully uploaded.",
  t_customersHasBeenUpdatedSuccessfully: "customers successfully uploaded.",
  t_customersCouldNotBeUploadedBecauseThereHasBeenAnError: "customers could not be uploaded due to an error.",
  t_pleaseSelectAProject: "Click to select a project",
  t_dialPlan: "Dial Plan", // DialPlan
  t_classifications: "Classifications",
  t_simulation: "Simulation",
  t_check: "Check",
  t_order: "Order", //DIAL PLAN PRIORITY1
  t_callingPatterns: "Calling Patterns",
  t_dialPattern: "Dial Pattern",
  t_simulatedCallCouldBePlacedFrom: "Simulated call could be placed from:",
  t_couldNotPlaceSimulationCall: "Could not place simulation call!",
  t_totalAgents: "Total", // Live Dashboard
  t_conference: "Conference",
  t_callsInQueue: "Calls in Queue",
  t_abandonedCount: "Abandoned Count",
  t_hourlyAbandonedCount: "Hourly Abandoned Count",
  t_statusLength: "Status Duration",
  t_massChangeStatuses: "Mass Change Statuses",
  t_textColour: "Text Color",
  t_audioFile: "Audio File",
  t_type: "Type",
  t_confetti: "Confetti",
  t_champagne: "Champagne",
  t_emergency: "Emergency",
  t_blink: "Blink",
  t_wallboardControl: "Wallboard Control",
  t_writtenSouffle: "Written Prompt",
  t_listen: "Listen",
  t_clap1: "Clap1",
  t_clap2: "Clap2",
  t_warning: "Warning",
  t_changeAllStatuses: "Mass Change Status", // Home.vue
  t_showWentHome: "Show Went Home", // Home.vue
  t_hideWentHome: "Hide Went Home", // Home.vue
  t_showCalledBack: "Show Called Back", // Home.vue
  t_reakTimeFraud: "Break Time Fraud", // Home.vue
  t_finishCodeCategory: "Category", // FinishCodeManagement
  t_categoryName: "Category Name",
  t_finishCodeCategorys: "Finish Code Categories",
  t_finishCodeName: "Display Name",
  t_finishCodes: "Finish Codes",
  t_askForActionDate: "Ask for Action Date",
  t_saveToCalendar: "Save to Calendar",
  t_contactCallableAfterwards: "Contact Callable Afterwards",
  t_contactNumberCallableAfterwards: "Phone Number Callable Afterwards",
  t_countAsCallAttempt: "Count as Call Attempt",
  t_lastFinishCode: "Last Finish Code",
  t_deleteContactNumber: "Delete Phone Number",
  t_assignToAgent: "Assign to Agent",
  t_inboundRoutes: "Inbound Routes", // InboundRoutes
  t_standardRoute: "Standard Route",
  t_lineGroup: "Line Group", // LineGroup
  t_lineGroupName: "Line Group Name",
  t_lineGroups: "Line Groups",
  t_addedLines: "Added Lines",
  t_lineList: "Line List", // Lines
  t_importLines: "Import Lines",
  t_lineName: "Line Name",
  t_outboundCID: "Outbound Caller ID",
  t_transferCID: "Transfer Caller ID",
  t_hide_ofline_users: "Hide Offline Users",
  t_ip: "IP",
  t_transportType: "Transport Type",
  t_type: "Type",
  t_prefix: "Prefix",
  t_inboundCallLimit: "Inbound Call Limit",
  t_outboundCallLimit: "Outbound Call Limit",
  t_port: "Port",
  t_codec: "Codec",
  t_fileUpload: "File Upload",
  t_file: "File",
  t_selectTheFileYouWantToUpload: "Select the file you want to upload",
  t_pleaseEnterTheLineGroupName: "Please enter the line group name.",
  t_youNeedToPickALineToBeAbleToCreateLineGroup: "You need to pick at least one line to create a line group.",
  t_lineGroupHasBeenCreatedSuccessfully: "Line group successfully created.",
  t_campaignManagement: "Campaign Management", // IvnDialer
  t_campaignControl: "Campaign Operations",
  t_stop: "Stop",
  t_recycle: "Recycle",
  t_calls: "Calls",
  t_basic: "Basic",
  t_scheduledCalls: "Scheduled Calls",
  t_contactList: "Contact List",
  t_programming: "Programming",
  t_priority: "Priority",
  t_maxCall: "Max Call",
  t_callsPerAgent: "Calls per Agent",
  t_statisticsPeriod: "Statistics Period (min)",
  t_callingMethod: "Dialing Method",
  t_noAnswerTimeout: "No Answer Timeout (sec)",
  t_maxAbandonRate: "Max Abandon Rate (%)",
  t_dontConnectMachines: "Don't Connect Machine Calls",
  t_sortCallList: "Sort Call List",
  t_maxCycles: "Max Cycles",
  t_recallDelayMins: "Recall Delay (min)",
  t_afterCall: "After Call",
  t_afterCallStatus: "After Call Status",
  t_maxAttempts: "Max Attempts",
  t_callstatisticsfortoday: "Today's Call Statistics",
  t_statusdurationfortoday: "Today's Status Duration",
  t_maximumRateOfCallsThatAreAbandonedFromTheQueue: "Max rate of calls abandoned from the queue",
  t_maximumAmountOfCallsThisCampaignCanStart: "Max number of calls this campaign can initiate at once",
  t_callsPerAvailableAgents: "Calls per Available Agent",
  t_refreshRateOfThisCampaignsStatisticsGraphs: "Refresh rate of this campaign's statistics graphs",
  t_whichLineGroupToMakeCallsFrom: "Line group to make calls from",
  t_maximumRingingTime: "Maximum ringing time for outgoing calls",
  t_amountOfRoundsToCallPeopleWhoHaventBeenContactedYet: "Number of rounds to call people who haven't been contacted yet after the list is completed",
  t_minimumAmountOfMinutesToRecallAPhoneNumber: "Minimum time to recall a phone number",
  t_maximumCallAmountPerPhoneNumberPerDay: "Maximum call attempts per phone number per day",
  t_maximumCallAmountPerContactPerDay: "Maximum call attempts per contact per day",
  t_maximumCallAmountPerPhoneNumber: "Total maximum call attempts per phone number",
  t_maximumCallAmountPerContact: "Total maximum call attempts per contact",
  t_doNotConnectMachineAnsweredCalls: "Don't connect if the call was answered by a machine",
  t_dailyMaxPhoneTouch: "Daily Max Phone Touch",
  t_dailyMaxContactTouch: "Daily Max Contact Touch",
  t_totalMaxPhoneTouch: "Total Max Phone Touch",
  t_totalMaxContactTouch: "Total Max Contact Touch",
  t_justCallMobilePhone: "Only call mobile phone",
  t_abandoned: "Abandoned",
  t_timeCondition: "Time Condition",
  t_transferIVR: "Transfer IVR",
  t_liveContacttIVR: "Live Contact IVR",
  t_liveContact: "Live Contact",
  t_noAvailableAgent: "No Available Agent", // Dialer.vue
  t_noAvailableAgentIVR: "No Available Agent IVR",
  t_rule: "Rule",
  t_campaignStatus: "Status",
  t_campaignPriority: "Priority",
  t_campaignFilterCriteria: "Filter Criteria",
  t_campaignSortingCriteria: "Sorting Criteria",
  t_campaignRemainingCycles: "Remaining Cycles",
  t_campaignCallPerAgent: "Calls per Agent",
  t_campaignTotalCustomersToCall: "Total Customers to Call",
  t_campaignTotalPhonesToCall: "Total Phones to Call",
  t_campaignRemainingPhonesInCycle: "Remaining Phones in Cycle",
  t_campaignTotalCalled: "Total Called",
  t_campaignTotalPhonesCalled: "Phones Currently Being Called",
  t_campaignConnectedCall: "Connected Call",
  t_campaignWaitingInQueue: "Calls Waiting in Queue",
  t_campaignAbandonRate: "Abandon Rate %",
  t_resultNoAnswer: "NoAnswer (Unanswered)",
  t_resultAbandon: "Abandon (Abandoned)",
  t_resultBusy: "Busy (Busy)",
  t_resultCallRejected: "CallRejected (Call Rejected by Customer)",
  t_resultFaultyCall: "FaultyCall (Faulty Call)",
  t_resultNetworkOutOfOrder: "NetworkOutOfOrder (Network Error)",
  t_resultFateUnknown: "FateUnknown (Unknown Fate)",
  t_resultConnectivityError: "ConnectivityError (Connection Error)",
  t_resultFaultyNumber: "FaultyNumber (Faulty Number)",
  t_finishCodeStatistics: "Finish Code Distribution",
  t_customersStatus: "Status",
  t_assigned_agent: "Agent",
  t_attempts: "Attempts",
  t_dial_plan: "Dial Plan",
  t_phone_book: "Phonebook",
  t_ivr: "IVR",
  t_finish_code: "Finish Code",
  t_insert_date: "Call Date",
  t_action_date: "Action Date",
  t_create_date: "Upload Date",
  t_ex_agent: "Ex-Agent",
  t_assign_date: "Assignment Date",
  t_pleaseMakeASelectionForSorting: "Please make a single selection for sorting.",
  t_started: "Started",
  t_stopped: "Stopped",
  t_recycling: "Recycling",
  t_isCompleted: "Completed",
  t_settingsHasBeenSavedSuccessfully: "Settings have been saved successfully.",
  t_columns: "Columns", // Main.vue
  t_row: "Row",
  t_sort: "Sort", // Main.vue
  t_algorithmicDialer: "Algorithmic Auto Dialer", // List.vue
  t_basicDialer: "Basic Auto Dialer", // List.vue
  t_filterManagement: "Filter Management", // IvnDialerFilter
  t_noFiltersForThisCampaign: "No filters found for this campaign.", // IvnDialerFilter > t_filterManagement > t_value
  t_condition: "Condition",
  t_equal: "Equals",
  t_notEqual: "Not Equals",
  t_lessThan: "Less Than",
  t_greaterThan: "Greater Than",
  t_lessThanOrEqual: "Less Than or Equal To",
  t_greaterThanOrEqual: "Greater Than or Equal To",
  t_columnName: "Column Name",
  t_columnNameStatus: "Status",
  t_columnNameAssignedAgent: "Agent",
  t_columnNameAttempts: "Attempts",
  t_columnNameCampaign: "Campaign",
  t_columnNameFinishCode: "Finish Code",
  t_columnNameInsertDate: "Call Date",
  t_columnNameActionDate: "Action Date",
  t_columnNameCreateDate: "Upload Date",
  t_columnNameExAgent: "Ex-Agent",
  t_filterHasBeenCreatedSuccessfully: "Filter has been created successfully.",
  t_IVR: "IVR", // IVR Management
  t_IVRName: "IVR Name",
  t_IVRList: "IVR List",
  t_DTMFdigit: "DTMF Digit",
  t_announcementFile: "Announcement File",
  t_DTMFAccept: "Accept DTMF Input",
  t_extension: "Extension",
  t_transfer: "Transfer",
  t_language: "Language",
  t_timeOutLengthSec: "Timeout Length (Seconds)",
  t_timeOutCount: "Timeout Count",
  t_maxInvalidInputCount: "Max Invalid Input Count",
  t_acceptDTMFExtension: "Accept Extension DTMF",
  t_turkish: "Turkish",
  t_english: "English",
  t_arabic: "Arabic",
  t_russian: "Russian",
  t_hangup: "Hang Up",
  t_hangupCall: "Reject Call",
  t_incomingCall: "Incoming Call",
  t_answer: "Answer",
  t_menu: "Menu",
  t_matched: "Matched:",
  t_unmatched: "Unmatched:",
  t_hasBeenDeletedSuccessfully: "Successfully deleted.",
  t_pleaseDoNotLeaveAnyBlankFields: "Please do not leave any blank fields.",
  t_hasBeenUpdatedSuccessfully: "Successfully updated.",
  t_hasBeenCreatedSuccessfully: "Successfully created.",
  t_pleaseSelectTheBlackListDirection: "Please select the direction to block.", // Blacklist
  t_phoneBook: "Phonebook", // PhoneBook
  t_description: "Description",
  t_profiles: "Profiles", // ProfileQueueManagement
  t_profileName: "Profile Name",
  t_affectedObjects: "Affected Objects",
  t_permissions: "Permissions",
  t_user: "User",
  t_reset: "Reset",
  t_noPermissionsSelected: "No permissions selected.",
  t_profileList: "Profile List",
  t_chat: "Chat",
  t_souffle: "Prompt",
  t_profileHasBeenDeletedSuccessfully: "Profile has been deleted successfully.",
  t_profileHasBeenUpdatedSuccessfully: "Profile has been updated successfully.",
  t_profileHasBeenCreatedSuccessfully: "Profile has been created successfully.",
  t_is_visible_in_live_dasboard: "Visible in Live Dashboard",
  t_projectName: "Project Name", // Projects
  t_afterCallWork: "After Call Work Time (seconds)",
  t_cameraRecord: "Record Camera",
  t_useableBreakTime: "Usable Break Time (minutes)",
  t_breakTime: "Break Time",
  t_surname: "Surname",
  t_nameSurname: "Full Name",
  t_projectList: "Project List",
  t_projectHasBeenDeletedSuccessfully: "Project has been deleted successfully.",
  t_projectHasBeenUpdatedSuccessfully: "Project has been updated successfully.",
  t_projectHasBeenCreatedSuccessfully: "Project has been created successfully.",
  t_queueNumber: "Queue Number", // Queues - List.vue
  t_queueAgentTimeout: "Agent Timeout (seconds)",
  t_queueAgentTimeoutPlaceholder: "Maximum time a call can wait for an agent",
  t_queueAnnounceFrequency: "Announcement Frequency (seconds)",
  t_timeBetweenAnnouncements: "Time Between Announcements",
  t_queueAnnouncePosition: "Position Announcement",
  t_queueMaxlen: "Maximum Length",
  t_queuePenaltyMembersLimit: "Penalty Member Limit",
  t_queueRetry: "Retry",
  t_queueMusicOnHold: "Music On Hold",
  t_queueName: "Queue Name",
  t_queueCallingMode: "Calling Mode",
  t_queueStrategy: "Queue Strategy",
  t_queueStrategyPlaceholder: "Strategy for distributing calls among agents",
  t_queueWrapupTime: "Wrap-Up Time (seconds)",
  t_queueWrapupTimePlaceholder: "Minimum time agents can be call-free after a call",
  t_queueAnnounceHoldTime: "Hold Time Announcement",
  t_queueQueueHoldTime: "Automatic Hold Time",
  t_queueMemberDelay: "Member Delay",
  t_queuePeriodicAnnounceFrequency: "Periodic Announcement Frequency",
  t_queueServiceLevel: "Service Level",
  t_queueWeight: "Weight",
  t_queueList: "Queue List",
  t_queueRrmemory: "Sequential (Recommended)",
  t_queueRingall: "Ring All",
  t_queueLeastrecent: "Least Recently Called First",
  t_queueAgentWeight: "Agent Priority",
  t_queueFewestcalls: "Fewest Calls First",
  t_queueRandom: "Random",
  t_queueManual: "Manual",
  t_queueDialer: "Dialer",
  t_serviceManagement: "Service Management", // Service Management
  t_dialerService: "Auto Dialer Service",
  t_restart: "Restart",
  t_callManager: "Call Management Service",
  t_chatService: "Chat Service",
  t_smsSending: "SMS Sending", // SMS
  t_smsTemplate: "Template",
  t_smsFilter: "Filter",
  t_filterName: "Filter Name", // SmsFilter
  t_noFilterHasBeenSelected: "No filter selected.",
  t_filterList: "Filter List",
  t_smsPageTitle: "Page Title", // SmsSender
  t_sendFromVoyceCRM: "Send from System",
  t_sendFromExternalCRM: "Send from External Source",
  t_sendFromCustomSms: "Custom Send",
  t_smsPattern: "Message Template",
  t_preview: "Preview",
  t_exampleText: "Sample Message Text",
  t_phoneNumbers: "Phone Numbers",
  t_enterPhoneNumbersSeparatedByCommas: "Enter phone numbers separated by commas.",
  t_enterEmailsSeparatedByCommas: "Enter email addresses separated by commas.",
  t_pleaseEnterAtLeastOnePhoneNumber: "Please enter at least one phone number.",
  t_pleaseEnterAMessage: "Please enter a message.",
  t_messagesHaveBeenDeliveredSuccessfully: "Messages delivered successfully.",
  t_thereWasAProblemDeliveringYourMessages: "There was a problem delivering your messages.",
  t_displayName: "Display Name", // SmsSettings
  t_senderNumber: "Sender Number",
  t_accountCode: "Account Code",
  t_apiKey: "API Key",
  t_smsSettingList: "SMS Settings List",
  t_pattern: "Pattern",
  t_patternName: "Pattern Name",
  t_smsText: "Message Text",
  t_patternList: "Pattern List",
  t_callCenter: "Call Center", // Statistics
  t_statistics: "Statistics",
  t_missedCall: "Missed",
  t_lunch: "Lunch",
  t_break: "Break",
  t_AVGInboundLengt: "Average Inbound Duration",
  t_AVGOutboundLengt: "Average Outbound Duration",
  t_totalInbound: "Total Inbound",
  t_totalOutbound: "Total Outbound",
  t_remainingBreakTime: "Remaining Break Time",
  t_statusTime: "Status Duration",
  t_totalActionCounts: "Total Action Counts",
  t_waitingInQueue: "Waiting in Queue",
  t_numberofwaitingininstantqueue: "Number of people currently waiting in queue",
  t_allcallrecordsfortoday: "All call records for today",
  t_collectionTarget: "Target",
  t_collectedMade: "Collected",
  t_workingDay: "Working Day",
  t_present: "Present",
  t_absent: "Absent",
  t_actionDate: "Action",
  t_inCallOutbound: "On Call (Outbound)",
  t_inCallInbound: "On Call (Inbound)",
  t_ringing: "Ringing",
  t_noAnswer: "No Answer",
  t_promiseToPay: "Promise to Pay",
  t_refuseToPay: "Refused to Pay",
  t_messageLeft: "Message Left",
  t_busy: "Busy",
  t_statusCategory: "Status Category", // StatusManagement
  t_statusCategorys: "Status Categories",
  t_statusName: "Status Name",
  t_categoryKey: "Category Key",
  t_categories: "Categories",
  t_changeable: "Changeable",
  t_onlySPVCanChange: "Only Supervisor Can Change",
  t_active: "Active",
  t_statusList: "Status List",
  t_online: "Online",
  t_offline: "Offline",
  t_other: "Other",
  t_important: "Important",
  t_apointment: "Appointment",
  t_reminder: "Reminder",
  t_personal: "Personal",
  t_personaly: "Personal",
  t_timeConditionName: "Time Condition Name", // TimeConditionManagement - List.vue
  t_startDay: "Start Day",
  t_startTime: "Start Time",
  t_startingDayOfMonth: "Starting Day of the Month",
  t_monthStart: "Month Start",
  t_monthStartDay: "Start Day of the Month",
  t_monthEndDay: "End Day of the Month",
  t_monthEnd: "Month End",
  t_endDay: "End Day",
  t_endTime: "End Time",
  t_endingDayOfMonth: "Ending Day of the Month",
  t_timeConditions: "Time Conditions",
  t_monday: "Monday",
  t_tuesday: "Tuesday",
  t_wednesday: "Wednesday",
  t_thursday: "Thursday",
  t_friday: "Friday",
  t_saturday: "Saturday",
  t_sunday: "Sunday",
  t_january: "January",
  t_february: "February",
  t_march: "March",
  t_april: "April",
  t_may: "May",
  t_june: "June",
  t_july: "July",
  t_august: "August",
  t_september: "September",
  t_october: "October",
  t_november: "November",
  t_december: "December",
  t_theBeginingConditionCanNotBeGraterThanEndingCondition: "The starting condition cannot be greater than the ending condition.",
  t_userInformations: "Personal Information",
  t_import: "Import", // UsersManagement //
  t_importUsers: "Import Users", // UsersManagement //
  t_importRecords: "Import Records", // UsersManagement //
  t_userList: "User List",
  t_tenantName: "Tenant Name",
  t_recordingExport: "Export Recordings", // VoiceExport.vue
  t_recordingRemove: "Delete Recordings", // VoiceExport.vue
  t_filters: "Filters",
  t_tags: "Tags",
  t_nameType: "Name Format", // VoiceExport.vue
  t_youCanAddCommaBetweenEmailAddressesToAddMore: "You can add multiple email addresses separated by commas.",
  t_youCanAddCommaBetweenPhoneToAddMore: "You can add multiple phone numbers separated by commas.",
  t_startDate: "Start Date",
  t_endDate: "End Date",
  t_namePattern: "Name Format",
  t_targetMail: "Target Email",
  t_whenTheFilesAreReadyYouWillbeSentAnEMail: "You will be automatically emailed when the files are ready.",
  t_fileType: "File Format",
  t_theProcessHasBeenStartedItWillBeSentByEmailWhenItIsCompleted: "The process has started. You will receive an email when it's completed.",
  t_thereWasAProblemStartingTheProcess: "There was a problem starting the process.",
  t_process: "Process", // VSPR //
  t_deactiveCustomer: "Deactivate Customer",
  t_activeCustomer: "Activate Customer",
  t_assigneManager: "Assign Manager",
  t_conclude: "Conclude",
  t_addingNote: "Add Note",
  t_deleteCallRecord: "Delete Call Recording",
  t_matchingColumn: "Matching Column", // components - VSPR //
  t_pleaseFillTheAllNecessaryAreas: "Please fill in all required fields.",
  t_thereWasAProblemWhileAssigning: "There was a problem during assignment.",
  t_doesNotMatchAnyCustomers: "No matching record.",
  t_matchingColumnMustBeTheSameInTheExcelColumn: "The column name to match must be the same as in the Excel column.",
  t_AProblemOccurredwhileProcessing: "A problem occurred while processing.",
  t_customersHaveBeenSuccessfullyUpdated: " records have been successfully updated.",
  t_customersHaveBeenSuccessfullyDeleted: " records have been successfully deleted.",
  t_processSuccess: "Process Successful",
  t_assigne: "Assign", // AssignManager //
  t_manager: "Manager",
  t_thereWasAProblemWhileAssigningManager: "There was a problem assigning the manager.",
  t_managerHaveSuccessfullyBeenAssigned: "Manager successfully assigned.",
  t_deactiveCustomersHaveSuccessfullyBeenDone: " customers have been successfully deactivated.", // CloseCustomer //
  t_VSPRCloseCustomer: "Close Customer",
  t_VSPROpenCustomer: "Open Data",
  t_aProblemOccurredWhileConcluding: "A problem occurred while concluding.",
  t_customersHaveSuccessfullyBeenConcluded: " customers have been successfully concluded.",
  t_thereWasAProblemActivatingCustomers: "There was a problem activating customers.",
  t_customersHaveBeenSuccessfullyActivated: " customers have been successfully activated.",
  t_deleteCallRecordings: "Delete Call Recordings",
  t_fileHasBeenImportedSuccessfully: "File imported successfully.",
  t_makeAnAnnouncement: "Make Announcement", // Chat //
  t_sendASouffle: "Send Prompt",
  t_startChat: "Start Chat",
  t_open: "Open",
  t_conversations: "Conversations",
  t_announcementHistory: "Announcement History",
  t_totalCallTime: "Total Call Time",
  t_avgCallTime: "Average Call Time",
  t_avgAnswerTime: "Average Answer Time",
  t_avgHoldTime: "Average Hold Time",
  t_avgAcwTime: "Average ACW Time",
  t_totalCallTimeDescrition: "KPI",
  t_avgCallTimeDescrition: "KPI",
  t_avgAnswerTimeDescrition: "KPI",
  t_avgHoldTimeDescrition: "KPI",
  t_avgAcwTimeDescrition: "KPI",
  t_noRecordOnQueue: "No calls waiting in queue.",
  t_callRecords: "Call Records",
  t_secondLine: "Second Line",
  t_changeMode: "Change View",
  t_showNotConnected: "Show Not Connected",
  t_fullScreen: "Full Screen",
  t_announcementPanel: "Announcement Panel",
  t_yourMessage: "Your Message",
  t_startAconversation: "Start a Conversation",
  t_youGotAPrivateMessage: " sent you a private message.",
  t_startANewConversation: "Start a New Conversation",
  t_updatePassword: "Update Password", // Navbar //
  t_PasswordCondition: "Your password must be at least 6 characters long, including 1 letter and 1 number.",
  t_lastAccountEvent: "Last Account Activity",
  t_events: "Events",
  t_changePassword: "Change Password",
  t_again: "Again",
  t_currentPassword: "Current Password",
  t_newPassword: "New Password",
  t_confirmPassword: "Confirm New Password",
  t_pleaseSelectfinishCode: "Please select a finish code.",
  t_survey: "Survey",
  t_passwordmustContainAtLeast1LetterAnd1Number: "Password must contain at least 1 letter and 1 number.",
  t_enteredpasswordsDoNotMatch: "Entered passwords do not match.",
  t_passwordMustBeAtLeast6CharactersLong: "Password must be at least 6 characters long.",
  t_YourPasswordCannotBeTheSameAsYourOldPassword: "Your password cannot be the same as your old password.",
  t_PasswordHasBeenchangedSuccessfully: "Password changed successfully.",
  t_youHaveReconnectedToTheService: "You have reconnected to the service.",
  t_yourServiceConnectionHasBeenLost: "Your service connection has been lost.",
  t_ACWNothingSelected: "No Action Selected",
  t_ACWTimeOut: "ACW Timeout",
  t_rdialerconnectedtoagent: "Auto Dialer Connected Call",
  t_rdialerabandon: "Auto Dialer Abandoned Call",
  t_color: "Color",
  t_view: "View",
  t_allDays: "All Days",
  t_addEvent: "Add Event",
  t_eventName: "Event Name",
  t_eventNote: "Event Note",
  t_eventDate: "Event Date",
  t_eventTime: "Event Time",
  t_displayOnCRM: "Display on CRM",
  t_monthly: "Monthly",
  t_weekly: "Weekly",
  t_daily: "Daily",
  t_hourly: "Hourly",
  t_mon: "Mon",
  t_tue: "Tue",
  t_wed: "Wed",
  t_thu: "Thu",
  t_fri: "Fri",
  t_sat: "Sat",
  t_sun: "Sun",
  t_monSat: "Mon - Sat",
  t_sunSat: "Sun - Sat",
  t_monFri: "Mon - Fri",
  t_monWedFri: "Mon, Wed, Fri",
  t_eventhasBeenCreatedSuccessfully: "Event created successfully.",
  t_avaragequalitypoint: "Average Quality Point",
  t_liveDashboard: "Live Monitoring", // Menu //
  t_mAlerts: "Triggers",
  t_systemManagement: "System Management",
  t_operationManagement: "Operation Management",
  t_integrations: "Integrations",
  t_eMail: "Email",
  t_qVoiceMail: "Voicemail",
  t_lastLogin: "Last Login",
  t_speechRule: "Speech Rule",
  t_addSpeechRule: "Add Speech Rule",
  t_lines: "Lines",
  t_routes: "Routes", // System Management -> Main
  t_vIVR: "Variable IVR",
  t_backup: "Backup",
  t_providers: "Providers",
  t_mVoice: "Voice",
  t_reachedCalls: "Reached Calls",
  t_unreachedCalls: "Unreached Calls",
  t_missedCalls: "Missed Calls",
  t_outcomingCall: "Outgoing Call",
  t_inboundCall: "Inbound Call",
  t_incomingCallAvg: "Average Incoming Call",
  t_outgoingCallAvg: "Average Outgoing Call",
  t_show: "Show",
  t_entries: "Entries",
  t_addRecord: "Add Record",
  t_showing: "Showing",
  t_title: "Title",
  t_clear: "Clear",
  t_perPage: "Per Page",
  t_uploadFileOnClick: "Click to upload a file.",
  t_delete: "Delete",
  t_noEditedRecord: "No record to edit.",
  t_noRecord: "No record to display.",
  t_errorFetchData: "An error occurred while fetching the records.",
  t_backToList: "Back to List",
  t_deleteRecord: "Delete Record",
  t_updateRecord: "Update Record",
  t_titles: "Titles",
  t_searchPlaceHolder: "Search by Name, Customer No., Product No., ID, or Phone",
  t_customerId: "Customer ID",
  t_breakStatus: "Break Status",
  t_targetStatus: "Target",
  t_mrAgentAssign: "Agent Shuffle",
  t_dataUpload: "Data Upload",
  t_customerList: "Customer List",
  t_customerListByPhone: "Customer List by Phone",
  t_customerListByProduct: "Customer List by Product",
  t_customerListByCSV: "Customer List by CSV",
  t_vehicle: "Vehicle",
  t_landRegistry: "Land Registry",
  t_SSK: "SSK",
  t_mernis: "Mernis",
  t_gsmQuery: "GSM Query",
  t_negotiation: "Negotiation",
  t_products: "Products",
  t_phones: "Phones",
  t_hourlyGraphic: "Hourly Graph",
  t_deleteFilter: "Delete Filter",
  t_addFilter: "Add Filter",
  t_clearFilter: "Clear Filter",
  t_addNewRecord: "Create New Record",
  t_selectableLines: "Selectable Lines",
  t_selectedLines: "Used Lines",
  t_restartService: "Are you sure you want to restart the service?",
  t_restartServiceSuccessfully: "Service restarted successfully.",
  t_id: "ID",
  t_goal: "Goal",
  t_goalManagementDescription: "Graph View",
  t_smscount: " messages will be sent. Do you confirm?",
  t_call: "Call",
  t_clearFilters: "Clear Filters",  // CRM - Main.vue //
  t_management: "Management",
  t_customerInformation: "Customer Information",
  t_customerUpdate: "Update Customer Information",
  t_extraInformation: "Extra Information",
  t_hide: "Hide",
  t_addAction: "Add Action",
  t_addPhone: "Add Phone",
  t_addNote: "Add Note",
  t_landLine: "Landline",
  t_addFinishCode: "Add Finish Code",
  t_addProductAction: "Add Product Action",
  t_actionDate2: "Action Date",
  t_installmentCount: "Installment Count",
  t_installmentAmount: "Installment Amount",
  t_relationTo: "Owner",
  t_isApproved: "Approved",
  t_addData: "Add Data",
  t_sendSMS: "Send SMS",
  t_sendEmail: "Send Email",
  t_filterTheResults: "Filter Results",
  t_filterBy: "Filter By",
  t_pleaseWriteSomethingToSeeYourOptions: "Write something to see your options.",
  t_columnVisibilityOptions: "Column Visibility Options",
  t_showHide: "(Show/Hide)",
  t_agentAssign: "Assign Agent",
  t_assigmnentType: "Assignment Type",
  t_contactPool: "Pool",
  t_sender: "Sender",
  t_shuffle: "Shuffle",
  t_assignForProductAgent: "Assign for Product Agents",
  t_progresingDate: "Progress Date",
  t_allUnassignedCasesWillBeAssignedToSelectedAgentsAreYouSure: "All unassigned cases will be assigned to the selected agents. Do you confirm?",
  t_amountOfCasesWillBeUpdatedAreYouSure: "The changes will affect people. Do you confirm?",
  t_casesCouldNotBeAssigned: " cases could not be assigned.",
  t_customersHaveSuccessfullyBeenAssigned: " customers have been successfully assigned.",
  t_error: "Error",
  t_failed: "Failed",
  t_reportDetails: "Report Details", // Reports.vue
  t_summary: "Summary",
  t_today: "Today", // Reports.vue
  t_yesterday: "Yesterday", // Reports.vue
  t_last7Days: "Last 7 Days", // Reports.vue
  t_last30Days: "Last 30 Days", // Reports.vue
  t_betweenDates: "Between Dates", // Reports.vue
  t_reports: 'Reports', // Main.vue
  t_reportType: 'Report Type',
  t_callDetails: "Call Details", // Report Names //
  t_mrInboundCallDetails: "Inbound Call Details",
  t_mrSL30Daily: "SL30 Daily",
  t_mrSL30Monthly: "SL30 Monthly",
  t_mrSL30Hourly: "SL30 Hourly",
  t_mrAbandonedCalls: "Abandoned Calls",
  t_mrAfterWorkInboundCalls: "After Work Inbound Calls",
  t_mrTotalCalls: "Total Calls",
  t_extraTime: "Extra Time",
  t_giveExtraTime: "Grant Extra Time",
  t_time: "Time (00:00:00)",
  t_mrCallDurationDetails: "Call Duration Details",
  t_mrCallDurationSummary: "Call Duration Summary",
  t_mrInboundCallDaily: "Daily Inbound Call Distribution",
  t_mrInboundCallHourly: "Hourly Inbound Call Distribution",
  t_surveyDetails: "Survey Details",
  t_mrAgent: "Agent",
  t_mrAgentActivity: "Agent Summary",
  t_mrAgentCallCountsDaily: "Daily Agent Call Counts",
  t_mrAgentCallCountsByDate: "Agent Call Counts by Date",
  t_mrAgentWork: "Agent Work",
  t_mrAgentCalls: "Agent Calls",
  t_mrAgentDaily: "Daily Call Distribution",
  t_mrAgentHourly: "Hourly Call Distribution",
  t_mrAgentCalendar: "Calendar Details",
  t_mrIvnDialerCallAnalyze: "Campaign Analysis",
  t_mrIvnDialerCallDetails: "Campaign Call Details",
  t_called: "Scanned", // Dashboard.vue
  t_closed: "Closed",
  t_backedupFiles: "Backed-up Files", // Backup.vue
  t_pleaseSelectTheFileToUpload: "Please select the file to upload to the database.",
  t_createBackup: "Create Backup",
  t_exportToDatabase: "Export to Database",
  t_filter: "Filter", // GLOBAL //
  t_settings: 'Settings',
  t_action: "Action",
  t_note: "Note",
  t_column: "Column",
  t_value: "Value",
  t_operator: "Operator",
  t_users: "Users",
  t_agents: "Agents",
  t_date: "Date",
  t_agent: "Agent", // Home.vue
  t_finishCode: "Finish Code",
  t_direction: "Direction",
  t_defaultRoute: "Default Route",
  t_editSelected: "Edit Selected",
  t_crm: 'CRM',
  t_calendar: 'Calendar',
  t_dialer: "Auto Dialer",
  t_goalManagement: 'Goal Management',
  t_line: 'Line',
  t_dialPlans: 'Dial Plans',
  t_queues: 'Queues',
  t_projects: 'Projects',
  t_statuses: 'Statuses',
  t_announce: 'Announcement',
  t_createScript: 'Create Script',
  t_queueTimeOut: 'Queue Timeout',
  t_detinationType: 'Destination Type',
  t_detinationTarget: 'Destination Target',
  t_showInDashboard: 'Show in Dashboard',
  t_reject: 'Reject',
  t_internalCall: 'Internal',
  t_isProductFinishCode: 'Product Finish Code',
  t_queueGroup: 'Queue Group', // Home.vue
  t_agentGroup: 'Agent Group', // Home.vue
  t_selectableQueues: "Selectable Queues",
  t_selectedQueues: "Used Queues",
  t_isHoldSound: 'Hold Music',
  t_waitTime: 'Wait Time',
  t_isReached: 'Call Answered',
  t_addCustomer: 'Add Customer',
  t_phonesUpdate: "Edit Phone",
  t_phonesinfoUpdate: "Update Phone Information",
  t_isAutoEmail: 'Send Email Automatically',
  t_emailTemplate: 'Email Template',
  t_account: 'Account',
  t_security: 'Security',
  t_performance: 'Performance',
  t_emailDelayAmount: 'Email Delay (Minutes)',
  t_isAutoSMS: 'Send SMS Automatically',
  t_smsDelayAmount: 'SMS Delay (Minutes)',
  t_autoAnswer: 'Auto Answer',
  t_isUnassign: 'Remove Assignment',
  t_isPassive: 'Set as Passive',
  t_setAttemptsByPhone: 'Set Attempts Based on Phone History',
  t_recordingFile: 'Recording',
  t_callStrategy: 'Call Strategy',
  t_sortCriteria: 'Sorting Criteria',
  t_internationalCall: 'International Call',
  t_dialerPrecall: 'Dialer Pre-call',
  t_variable: 'Variable',
  t_variableLength: 'Variable Length',
  t_reportFiles: 'Report Files', // Main.vue
  t_schedule: 'Schedule',
  t_scheduler: 'Scheduler',
  t_schedulerTasks: 'Scheduled Tasks',
  t_schedulerCategory: 'Scheduler Category',
  t_reportCategory: 'Report Category',
  t_lastXDays: 'Last X Days',
  t_communicationType: 'Communication Type',
  t_communicationSrc: 'Communication Source',
  t_communicationTitle: 'Title',
  t_to: 'Recipient (To)',
  t_cc: 'Carbon Copy (CC)',
  t_youCanAddCommaBetweenEmailAddressesToaddMore: "You can add multiple email addresses separated by commas.",
  t_email: 'Email',
  t_mobileNotification: 'Mobile Notification',
  t_callPlaced: 'Call Placed',
  t_callWentToAgent: 'Call Assigned to Agent',
  t_callJoinedQueue: 'Call Joined Queue',
  t_callPickedUp: 'Call Answered',
  t_callRejected: 'Call Rejected',
  t_schedulerTime: 'Scheduler Time',
  t_schedulerTimeString: 'Scheduler Time Description',
  t_creator: 'Creator',
  t_nextRunTime: 'Next Run Time',
  t_externalChat: 'External Chat',
  t_start: "Start", // IvnDialer //
  t_selectAll: "Select All", // CRM - Main.vue - Home.vue //
  t_clearAll: "Clear All", // Main.vue
  t_queue: "Queue", // Home.vue - Reports.Vue
  t_announcement: "Announcement", // ProfileQueueManagement - Home.vue //
  t_duration: "Duration", // Statistics - Home.vue
  t_productivity: "Productivity", // Statistics - Home.vue
  t_status: "Status", // Home.vue
  t_usedBreakTime: "Break Time", // Statistics - Home.vue
  t_answered: "Answered",  // Statistic.vue - Wallboard.vue - Home.vue
  t_successfullCall: "Reached", // Statistics - Home.vue - Dialer.vue
  t_unsuccessfullCall: "Unreachable", // Statistics - Home.vue - Dialer.vue
  t_export: "Export", // Home.vue - List.vue - Reports.vue
  t_total: "Total", // Dialer.vue
  t_rate: "Rate (%)", // Statistic.vue - Home.vue - Dialer.vue
  t_send: "Send", // SmsFilter
  t_next: 'Next',
  t_previous: 'Previous',
  t_classification: "Classification", // List.vue - ListAddNew.vue
  t_available: "Available", // Wallboard.vue - Home.vue / Dialer.vue
  t_inCall: "In Call", // Wallboard.vue - Home.vue
  t_paused: "On Break",  // Wallboard.vue - Home.vue
  t_profile: "Profile",
  t_no: "No",
  t_yes: "Yes",
  t_report: 'Report',
  t_actions: "Actions", // All
  t_cancel: "Cancel", // All
  t_save: "Save", // All
  t_category: "Category", // All
  t_project: "Project", // All
  t_close: "Close", // All
  t_name: "Name", // All
  t_sms: 'SMS', // All
  t_general: 'General',
  t_loginPageImages: 'Login Page Images',
  t_browse: 'Browse',
  t_thisMonth: 'This Month',
  t_lastMonth: 'Last Month',
  t_yourReportAddToReportQueue: 'Your report has been added to the report queue.',
  t_notification: 'Notification',
  t_notifications: 'Notifications',
  t_pleaseSelectReport: 'Please Select Report',
  t_host: 'Host',
  t_subject: 'Subject',
  t_isSendMailToCustomer: 'Send Email to Customer',
  t_surveyCancel: 'Cancel Survey',
  t_surveyApprove: 'Approve Survey',
  t_sendType: 'Send Type',
  t_info: 'Info',
  t_pleaseSelectTimeCondition: 'Please select time condition',
  t_crmWhiteColorInfo: 'Untouched',
  t_crmInfoColorInfo: 'Touched and Callable',
  t_crmWarningColorInfo: 'Touched and Not Callable',
  t_crmSecondaryColorInfo: 'Touched and Closed',
  t_crmDangerColorInfo: 'Untouched and Closed',
  t_nextCustomer: 'Next Customer',
  t_lineChangeInfo: 'Please re-save your campaigns using the corresponding line group.',
  t_deleteData: 'Delete Data',
  t_closedByDailyAttempts: 'Closed by Daily Attempts',
  t_closedByTotalAttempts: 'Closed by Total Attempts',
  t_resetScheduledCalls: 'Reset Unscheduled Calls',
  t_resetDailyAttempts: 'Reopen Daily Closed Calls',
  t_resetTotalAttempts: 'Reopen Totally Closed Calls',
  t_detail: "Detail",
  t_spokenCall: 'Spoken Call',
  t_spokenCallRate: 'Spoken Call Rate',
  t_nonSpokenCall: 'Non-Spoken Call',
  t_nAttemptsCall: 'No Attempts Call',
  t_attemptsRate: 'Attempts Rate',
  t_reachRate: 'Reach Rate',
  t_totalCallInfo: 'Total number of calls made',
  t_spokenCallInfo: 'Number of calls where the agent had a conversation',
  t_spokenRateInfo: 'The ratio of spoken calls to the total number of non-attempts calls. Formula: Spoken / Total - No Attempts',
  t_noSpokenCallInfo: 'Number of calls that were answered but no conversation occurred. Includes unanswered and busy calls.',
  t_noAttemptsCallInfo: 'Number of calls that were presented to the operator but ended without the operator contacting the number.',
  t_attemptsRateInfo: 'The rate of calls delivered to the operator where the number was contacted. Formula: Total - No Attempts / Total',
  t_reachRateInfo: 'The rate of numbers contacted that connected to the queue. Formula: Spoken + Abandoned / Total',
  t_username: 'Username',
  t_examples: 'Examples',
  t_cron: 'Scheduler',
  t_cron1: "Every minute",
  t_cron2: "At the beginning of every hour",
  t_cron3: "Every day at midnight",
  t_cron4: "Every day at 5:00 PM",
  t_cron5: "Every Sunday at midnight",
  t_cron6: "The first day of every month at midnight",
  t_cron7: "The first day of the year at midnight",
  t_cron8: "Every weekday at midnight",
  t_cron9: "The 15th of every month at midnight",
  t_cron10: "The first Monday of every year at midnight",
  t_cron11: "On the 15th of January, June, and December at midnight",
  t_cron12: "The first business day of every month at midnight",
  t_cron13: "Every weekend at midnight",
  t_alert: 'Alert',
  t_lessThan: 'Less Than',
  t_lessThanOrEqual: 'Less Than or Equal',
  t_moreThanOrEqual: 'Greater Than or Equal',
  // Report Fields
  t_rConferenceEvent: 'Conference',
  t_rConferenceParticipants: 'Conference Participants',
  t_rQualityFormNote: 'Form Note',
  t_rInboundFailSL3059: 'Missed Calls in 30/59 sec',
  t_rInboundFailSL60119: 'Missed Calls in 60/119 sec',
  t_rInboundFailSL120179: 'Missed Calls in 120/179 sec',
  t_rInboundFailSL180: 'Missed Calls over 180 sec',
  t_rIvrWaitLength: 'IVR Wait Time',
  t_rExtra: 'Extra Info',
  t_rSender: 'Sender',
  t_rMessage: 'Message',
  t_rEvaluateEvent: 'Evaluation',
  t_rEvaluateTime: 'Evaluation Time',
  t_rQualityFormName: 'Form Name',
  t_callAnalyze: 'Call Analysis',
  t_rObjectionEvent: 'Objection',
  t_rPunishEvent: 'Punishment',
  t_rQualityPoint: 'Quality Point',
  t_rMailSender: "Sender",
  t_rSubject: "Subject",
  t_rBody: "Description",
  t_rTo: 'Recipient (To)',
  t_rCC: 'Carbon Copy (CC)',
  t_rState: "State",
  t_rStartTime: "Start Time",
  t_rEndTime: "End Time",
  t_rProductivity: "Productivity",
  t_rRemoteNumber: "Phone Number",
  t_rAgent: "Agent",
  t_rDirection: "Direction",
  t_rQueue: "Queue",
  t_rVerdict: "Result Code",
  t_rActionDate: "Action Date",
  t_rNote: "Note",
  t_rConnectedLength: "Talk Time",
  t_rQueueWaitLength: "Queue Wait Time",
  t_rHoldLength: "Hold Time",
  t_rAcwLength: "ACW Time",
  t_rRecordingFile: "File Name",
  t_rTerminateSide: "Terminating Side",
  t_rRecordingFileName: "Recording",
  t_rLocalNumber: "Line",
  t_rLineName: "Line Name",
  t_rClassification: "Classification",
  t_rCampaign: "Campaign",
  t_rInteractionID: "Call ID",
  t_rMüşteriNo: "Customer No",
  t_rAdıSoyadı: "Full Name",
  t_rIsCalledBack: "Was Called Back?",
  t_rCalledBackedStartTime: "Callback Time",
  t_rCalledBackedQueue: "Callback Queue",
  t_rUsername: "Agent",
  t_rInboundSuccess: "Answered",
  t_rInboundTotal: "Total Inbound",
  t_rTotal: "Total",
  t_rLogin: "Login",
  t_rLogout: "Logout",
  t_rAvgCallTime: "Avg Call Time",
  t_rOnlineTime: "Login Time",
  t_rInCallTime: "Total Talk Time",
  t_rInboundSuccessSL: "SL Answering",
  t_rInboundFail: "Abandoned",
  t_rOutboundSuccess: "Outbound Successful",
  t_rOutboundFail: "Outbound Failed",
  t_rTotalWaitTime: "Total Wait Time",
  t_rAvgWaitTime: "Avg Wait Time",
  t_rMaxWaitTime: "Max Wait Time",
  t_rAgentMissed: "Agent Missed",
  t_rInitMoment: "Date",
  t_rCount: "Count",
  t_rDate: "Date",
  t_rCustomerID: "Customer ID",
  t_rProductID: "Product ID",
  t_rInboundSuccessRate: "Answer Rate",
  t_rDialerSuccess: "Auto Dial Success",
  t_rDialerFail: "Auto Dial Fail",
  t_rDialerTotal: "Auto Dial Total",
  t_rDialerTotalTalkTime: "Auto Dial Total Talk Time",
  t_rDialerTotalIVRTime: "Auto Dial Total IVR Time",
  t_rDialerAvgIVRTime: "Auto Dial Avg IVR Time",
  t_rDialerAvgTalkTime: "Auto Dial Avg Talk Time",
  t_rTotalAvgCallTime: "Avg Call Time",
  t_rTotalCallTime: "Total Call Time",
  t_rTotalSuccessCallCount: "Successful Call Count",
  t_rTotalCallCount: "Total Call Count",
  t_rDialerTotalCallTime: "Auto Dial Call Time",
  t_rDialerAvgCallTime: "Auto Dial Avg Call Time",
  t_rTotalSuccess: "Total Answered",
  t_rTotalTalkTime: "Total Talk Time",
  t_rTotalAvgTalkTime: "Avg Talk Time",
  t_rTotalCustomer: "Total Customer",
  t_rCallable: "Callable",
  t_rUnCallable: "Completed",
  t_rZeroAttempts: "Never Called",
  t_rStartDate: "Start Date",
  t_rAgentCount: "Agent Count",
  t_rIvrMissed: "IVR Abandoned Calls",
  t_rInboundAgentTotal: "Total Calls to Agent",
  t_rInboundSuccessSLA30: "Calls Answered after 30s",
  t_rInboundSuccessSL120: "Calls Answered within 120s",
  t_rInboundSuccessSLA120: "Calls Answered after 120s",
  t_rInboundFailSL30: "Calls Abandoned within 30s",
  t_rInboundFailSLA30: "Calls Abandoned after 30s",
  t_rInboundSuccessSLA30AvgWaitTime: "Avg Wait Time of Calls Answered after 30s",
  t_rInboundSuccessSLA120AvgWaitTime: "Avg Wait Time of Calls Answered after 120s",
  t_rAvgWaitTimeSLA30: "Avg Wait Time of Calls Abandoned after 30s",
  t_rSuccessRate: "Answer Rate",
  t_rServiceLevel: "Service Level",
  t_rTotalConnectedLength: "Total Talk Time",
  t_rAvgConnectedLength: "Avg Talk Time",
  t_rTotalSuccessCount: "Total Answered Calls",
  t_rBaseRemoteNumber: "Phone",
  t_rTotalCall: "Total Calls",
  t_rSuccessCall: "Reached",
  t_rFailCall: "Unreachable/ Unanswered",
  t_rAMD: "Answering Machine Detection",
  t_rTotalCallLength: "Total Talk Time",
  t_rFirstCallTime: "First Call Date",
  t_rLastCallTime: "Last Call Date",
  t_rAbandon: "Abandoned",
  t_rSaleCall: "Any Sale?",
  t_rEventTime: "Date",
  t_rEvent: "Event",
  t_rPrevStatus: "Previous Status",
  t_rPrevReason: "Result",
  t_rPrevStatusLength: "Duration (sec)",
  t_rInboundSL30: "SL30",
  t_rInboundAnswerRate: "Answer Rate",
  t_rInboundAvgCallTime: "Inbound Avg Call Time",
  t_rOutboundAvgCallTime: "Outbound Avg Call Time",
  t_rInterval: "Hour",
  t_rInboundTotalCallTime: "Inbound Total Call Time",
  t_rOutboundTotalCallTime: "Outbound Total Call Time",
  t_rDayName: "Day",
  t_rInboundSuccessSL30: "Answered within 30s",
  t_rInboundSuccessSL30Rate: "Answered within 30s Rate",
  t_rInboundFailSL10: "Abandoned within 10s",
  t_rInboundSuccessRateSL30: "SL30 Answer Rate",
  t_rInboundFailRate: "Abandon Rate",
  t_rInboundAvgTalkTime: "Inbound Avg Talk Time",
  t_rInboundTotalTalkTime: "Inbound Total Talk Time",
  t_rInboundAvgAnswerTime: "Avg Answer Time",
  t_rOutboundTotal: "Total Outbound Calls",
  t_rOutboundAvgTalkTime: "Outbound Avg Talk Time",
  t_rOutboundTotalTalkTime: "Outbound Total Talk Time",
  t_rCreateDate: "Upload Date",
  t_rAssignedAgent: "Assigned Agent",
  t_rLine: "Line",
  t_rSuccessCalls: "Reached Calls",
  t_rFailCalls: "Unreachable Calls",
  t_rHour: "Hour",
  t_rAgentSentimentPositiveSegmentCount: "Agent Positive Segment Count",
  t_rAgentSentimentPositiveScore: "Agent Positive Score",
  t_rAgentSentimentPositiveTokenCount: "Agent Positive Token Count",
  t_rAgentSentimentNegativeSegmentCount: "Agent Negative Segment Count",
  t_rAgentSentimentNegativeScore: "Agent Negative Score",
  t_rAgentSentimentNegativeTokenCount: "Agent Negative Token Count",
  t_rAgentSentimentTotalScore: "Agent Total Score",
  t_rAgentSentimentPositiveSegmentRate: "Agent Positive Segment Rate (%)",
  t_rAgentSentimentNegativeSegmentRate: "Agent Negative Segment Rate (%)",
  t_rAgentSentimentNotrSegmentRate: "Agent Neutral Segment Rate (%)",
  t_rCustomerSentimentPositiveSegmentCount: "Customer Positive Segment Count",
  t_rCustomerSentimentPositiveScore: "Customer Positive Score",
  t_rCustomerSentimentPositiveTokenCount: "Customer Positive Token Count",
  t_rCustomerSentimentNegativeSegmentCount: "Customer Negative Segment Count",
  t_rCustomerSentimentNegativeScore: "Customer Negative Score",
  t_rCustomerSentimentNegativeTokenCount: "Customer Negative Token Count",
  t_rCustomerSentimentTotalScore: "Customer Total Score",
  t_rCustomerSentimentPositiveSegmentRate: "Customer Positive Segment Rate (%)",
  t_rCustomerSentimentNegativeSegmentRate: "Customer Negative Segment Rate (%)",
  t_rCustomerSentimentNotrSegmentRate: "Customer Neutral Segment Rate (%)",
  t_rTotalDuration: "Total Talk Time",
  t_rOverlappedSpeechRatio: "Overlapping Speech Ratio",
  t_rOverlappedSpeechDuration: "Overlapping Speech Duration (sec)",
  t_rOverlappedSilenceRatio: "Silence Ratio",
  t_rOverlappedSilenceDuration: "Silence Duration (sec)",
  t_rAgentSpeechRatio: "Agent Speech Ratio",
  t_rAgentSpeechDuration: "Agent Speech Duration (sec)",
  t_rAgentSpeakingRate: "Agent Speaking Rate (Words per Minute)",
  t_rAgentInterruptCount: "Agent Interruption Count",
  t_rCustomerSpeechRatio: "Customer Speech Ratio",
  t_rCustomerSpeechDuration: "Customer Speech Duration (sec)",
  t_rCustomerSpeakingRate: "Customer Speaking Rate (Words per Minute)",
  t_rCustomerInterruptCount: "Customer Interruption Count",
  t_rListens: "Listened",
  t_rTotalEvaluations: "Total Evaluations",
  t_rAutoEvaluations: "Automatically Evaluated",
  t_rEvaluations: "Manually Evaluated",
  t_rAvgAutoEvaluationsPoint: "Avg Auto Evaluation Score",
  t_rAvgEvaluationsPoint: "Avg Manual Evaluation Score",
  t_rTotalCalls: "Total Calls",
  t_rOverlappedSilence: "Double-Sided Silence Count",
  t_rOverlappedSilenceRateByTotalCalls: "Double-Sided Silence / Total Calls (%)",
  t_rOverlappedSilenceRateBySuccessCalls: "Double-Sided Silence / Reached Calls (%)",
  t_rClosedByAgentCount: "Closed by Agent",
  t_rClosedByCustomerCount: "Closed by Customer",
  t_rClosedByAgentRateByTotalCalls: "Closed by Agent (%)",
  t_rClosedByCustomerRateByTotalCalls: "Closed by Customer (%)",
  t_rForbiddenWords: "Forbidden Word Usage",
  t_rForbiddenWordsRateByTotalCalls: "Forbidden Word Usage / Total Calls (%)",
  t_rForbiddenWordsRateBySuccessCalls: "Forbidden Word Usage / Reached Calls (%)",
  t_rAgentNoSpeechCount: "Calls with Customer Talking but Agent Not Talking",
  t_rAgentNoSpeechRateByTotalCalls: "Calls with Customer Talking but Agent Not Talking / Total Calls (%)",
  t_rAgentNoSpeechRateBySuccessCalls: "Calls with Customer Talking but Agent Not Talking / Reached Calls (%)",
  t_rAvgAHT: "Average AHT",
  t_rMaxAHT: "Max AHT",
  t_rMinAHT: "Min AHT",
  t_rDuration: "Call Duration",
  t_rListeningDuration: "Listening Duration",
  t_rPlayCount: "Play Count",
  t_rPauseCount: "Pause Count",
  t_rIsMuted: "Muted?",
  t_rPlayBackChangeCount: "Playback Speed Change Count",
  t_rPlayBackStartSpeed: "Playback Start Speed",
  t_rPlayBackEndSpeed: "Playback End Speed",
  t_rManualForm: "Manual Form",
  t_rManualFormPoint: "Manual Form Score",
  t_rAutoForm: "Auto Form",
  t_rAutoFormPoint: "Auto Form Score",
  t_rQualityNote: "Quality Note",
  t_abandonedCampaign: "Abandoned Call Campaign",
  t_autoRecycle: "Auto Recycle",
  t_goalListByAgents: "Agent Goal List",
  t_addGoal: "Add Goal",
  t_addCollection: "Add Collection",
  t_collections: "Collections",
  t_archivedMessages: "Archived Messages",
  t_archivetedMessages: "Archived Messages",
  // Report Names
  t_rQualityDetails: "Quality Details",
  t_rInboundCallDetails: "Inbound Call Summary",
  t_rCallTimeAndCounts: "Call Time and Counts",
  t_rCallDetails: "Call Details",
  t_rClassificationDetails: "Classification Details",
  t_rAgentPerformanceSummary: "Agent Performance Analysis",
  t_rAgentPerformanceSummaryByShift: "Agent Shift Performance",
  t_rVerdictDetails: "Result Code Details",
  t_rSmsHistory: "SMS History",
  t_rMailHistory: "Mail History",
  t_rSecondLineDetails: "Second Line/Internal Details",
  t_rAbandonCalls: "Abandoned Call Details",
  t_rMissedCalls: "Missed Call Details",
  t_rAgentSummary: "Agent Summary",
  t_rQueueSL: "SL / Queue",
  t_rOutOfWork: "After Work Inbound",
  t_rQueueDaily: "Inbound Calls Count / Day",
  t_rAgentDaily: "Agent Inbound Calls Count / Day",
  t_rQueueHourly: "Inbound Calls Count / Hour",
  t_rAgentHourly: "Agent Inbound Calls Count / Hour",
  t_rVerdictByDate: "Result Code Counts",
  t_rVerdictByDaily: "Result Code / Day",
  t_rVerdictByQueue: "Result Code / Queue",
  t_rVerdictByAgent: "Result Code / Agent",
  t_rClassificationByDate: "Classification Counts",
  t_rClassificationByDaily: "Classification / Day",
  t_rClassificationByQueue: "Classification / Queue",
  t_rClassificationByAgent: "Classification / Agent",
  t_rCallCountByDate: "Call Counts / Day",
  t_rDataSummaryByQueue: "Data Summary / Queue",
  t_rProductActionDetails: "Product Action Details",
  t_rLoginLogout: "Login / Logout",
  t_rLocalNumberSL: "SL / Number",
  t_rCallTimeAndCountByDirection: "Call Time and Counts / Direction",
  t_rPozitifCallDetails: "Call Details / Pool",
  t_rActivity: "Agent Activity",
  t_rDailyCallValues: "Call Values / Day",
  t_callrecords: "Call Records",
  t_rHourlyCallValues: "Call Values / Hour",
  t_rAgentPerformanceDaily: "Agent Performance / Day",
  t_rAgentPerformanceMonthly: "Agent Performance / Month",
  t_rAgentPerformanceHourly: "Agent Performance / Hour",
  t_rMonthlyCallValues: "Call Values / Month",
  t_rCallPerformanceByDate: "Call Performance / Day",
  t_rDataSummaryByCreateDate: "Data Summary / Upload Date",
  t_rDataSummaryByAssignedAgent: "Data Summary / Assigned Agent",
  t_rDataSummaryByDynamicFields: "Data Summary / Dynamic Fields",
  t_rLinePerformance: "Line Performance",
  t_rInboundCallValuesByHourly: "Inbound Call Values / Hour",
  t_rSurveyResult: "Survey Results",
  t_rEmotionAnalysis: 'Emotion Analysis / Day',
  t_rSpeechStatistics: 'Speech Statistics / Day',
  t_analysis: "Analysis",
  t_rQualityByDate: 'Quality / Day',
  t_rQualityByAgent: 'Quality / Agent',
  t_rCallStatisticsByDate: 'Call Statistics / Day',
  t_rListeningDetails: 'Listening Details',
  t_rProject: 'Project',
  t_rIVR: 'IVR',
  t_rInQueue: "In Queue",
  t_rInProgress: "In Progress",
  t_rShiftStart: 'Shift Start',
  t_rShiftEnd: 'Shift End',
  t_rShiftDuration: 'Shift Duration',
  t_rMissingTime: 'Missing Time',
  t_rCalculatedShiftDuration: 'Calculated Shift Duration',
  t_rCalculatedBreak: 'Calculated Break Duration',
  t_rTotalWorkDuration: 'Total Work Duration',
  t_rLeave: 'Leave',
  t_rBreakTime: 'Reference Break Time',
  t_rCalculatedWorkTime: 'Calculated Work Time',
  t_rWorkDuration: 'Work Duration',
  t_rTotalWorkDifference: 'Work Time Difference',
  t_reportName: "Report Name",
  t_dataCount: "Data Count",
  t_expand: "Expand",
  t_shrink: "Shrink",
  t_darkMode: "Dark Mode",
  t_lightMode: "Light Mode",
  t_alertCategory: "Alert Category",
  t_targetCategory: "Target Category",
  t_targetType: "Target Type",
  t_none: "None",
  t_seeExampleTimePatterns: "See Example Time Patterns",
  t_apply: "Apply",
  t_callList: 'Call List',
  t_pool: 'Pool',
  t_all: 'All',
  t_logs: 'Logs',
  t_totalAttempts: 'Total Attempts',
  t_insertDate: 'Last Call',
  t_actionTime: 'Action Time',
  t_assignDate: 'Assign Date',
  t_createDate: 'Create Date',
  t_createTime: 'Create Time',
  t_exAgent: 'Former Agent',
  t_addExtraCustomerInfo: 'Add Extra Customer Info',
  t_primaryKey: 'Primary Key',
  t_secondaryKey: 'Secondary Key',
  t_qVoice: 'Voice',
  t_qVideo: 'Video',
  t_qText: 'Text',
  t_interactionWeight: 'Channel Weight',
  t_records: 'Records',
  t_qualityForm: 'Quality Form',
  t_keyWords: 'Keywords',
  t_criterions: 'Criteria',
  t_required: "Required",
  t_isChatFinishCode: 'Chat Result Code',
  t_minWaitInQueue: 'Min. Wait Time in Queue (sec)',
  t_maxWaitInQueue: 'Max. Wait Time in Queue (sec)',
  t_minDuration: 'Min. Call Duration (sec)',
  t_maxDuration: 'Max. Call Duration (sec)',
  t_rIsEvaluated: 'Evaluated',
  t_rEvaluator: 'Evaluator',
  t_rEvaluatedStartTime: 'Evaluation Start Time',
  t_rEvaluatedEndTime: 'Evaluation End Time',
  t_rMinQualityPoint: 'Min. Quality Point',
  t_rMaxQualityPoint: 'Max. Quality Point',
  t_rQualityForm: 'Quality Form',
  t_rIsObjection: 'Objection',
  t_multiple: 'Multiple Choice',
  t_question: 'Question',
  t_minValue: 'Min. Value',
  t_maxValue: 'Max. Value',
  t_minTrueCount: 'Min. Correct Count',
  t_ordered: 'Ordered',
  t_minPoint: 'Min. Points',
  t_maxPoint: 'Max. Points',
  t_point: 'Points',
  t_selected: 'Selected',
  t_punish: 'Punish',
  t_pointRange: 'Point Range',
  t_answer2: 'Answer',
  t_openInNewWindow: 'Open in New Window',
  t_playbackRate: 'Playback Speed',
  t_isMailSendToAgent: 'Send Mail to Agent',
  t_httpRequest: 'HTTP Request',
  t_tts: 'TTS',
  t_stt: 'STT',
  t_beepTone: "Beep Tone",
  t_model: 'Model',
  t_whatsapp: 'WhatsApp',
  t_readQR: 'Read QR Code',
  t_url: 'URL',
  t_headers: 'Headers (JSON)',
  t_body: 'Body (JSON)',
  t_text: 'Text',
  t_useableVariables: 'Usable Variables',
  t_remoteNumber: 'Phone Number',
  t_hideVariableFromAgent: 'Hide Variable from Agent',
  t_provider: 'Provider',
  t_speaker: 'Speaker',
  t_isInList: 'In List',
  t_isNotInList: 'Not in List',
  t_isContains: 'Contains',
  t_isNotContains: 'Does Not Contain',
  t_IsConvergent: 'Has Convergence',
  t_IsNotConvergent: 'No Convergence',
  t_changeAllQueues: 'Change All Queues',
  t_remove: 'Remove',
  t_answerToObject: 'Reply to Objection',
  t_objectToForm: 'Submit Objection to Form',
  t_objections: 'Objections',
  t_accept: 'Accept',
  t_rLastObjectingUser: 'Last User to Object',
  t_evaluatedDaily: "Evaluated Today",
  t_avgPointDaily: "Avg. Points Today",
  t_evaluatedWeekly: "Evaluated This Week",
  t_avgPointWeekly: "Avg. Points This Week",
  t_evaluatedMonthly: "Evaluated This Month",
  t_avgPointMonthly: "Avg. Points This Month",
  t_standart: 'Standard',
  t_advanced: 'Advanced',
  t_quality: 'Quality',
  t_removeEvaluation: 'Remove Evaluation',
  t_openOnNewWindow: 'Open in New Tab',
  t_undefined: 'Undefined',
  t_callTime: 'Call Time',
  t_serviceLevel: 'Service Level',
  t_abandonRate: 'Abandon Rate',
  t_callAnswerTime: 'Call Answer Speed',
  t_noRouteToDestination: 'No Route to Destination',
  t_passwordSentToMailAddress: 'Your password has been sent to your email address.',
  t_noEmailAddress: 'Your account does not have an email address. Please contact your administrator.',
  t_forgotPassword: 'Forgot Password',
  t_saveRecord: 'Save Recording',
  t_isHttps: 'HTTPS',
  t_callStatistics: 'Call Statistics',
  t_addtCategory: 'Add New Category',
  t_create: 'Create',
  t_questionCount: 'Question Count',
  t_minPts: 'Min Points',
  t_maxPts: 'Max Points',
  t_questionsAndAnswers: 'Questions & Answers',
  t_extensionRoutes: 'Routes',
  t_evaluatedRecords: 'Evaluated Records',
  t_alerts: 'Alerts',
  t_audioEncoding: "Audio Encoding",
  t_gender: "Gender",
  t_token: "Token",
  t_showTCKN: "Show TCKN",
  t_webChat: "Web Chat",
  t_whatsapp: "WhatsApp",
  t_instagram: "Instagram",
  t_messenger: "Messenger",
  t_informAgent: 'Inform Agent',
  t_autoCustomerCard: 'Auto Open Customer Card',
  t_downloadCallRecordings: 'Download Call Recordings',
  t_outboundCallApproval: 'Outbound Call Approval',
  t_callHistoryDownloadRecording: 'Call History Recording Download',
  t_callHistoryListenRecording: 'Call History Listen Recording',
  t_dialerReopenTotalAttempts: 'Dialer Reopen Total Attempts',
  t_dialerReopenDailyAttempts: 'Dialer Reopen Daily Attempts',
  t_dialerClearCallSchedules: 'Dialer Clear Call Schedules',
  t_showAllFinishCodes: 'Show All Finish Codes',
  t_finishcodesofcallsfortoday: "Today's Call Finish Codes",
  t_updateLastFinishCode: 'Update Last Finish Code',
  t_showIVRInputs: 'Show IVR Inputs',
  t_hidePhoneNumbers: 'Hide Phone Numbers',
  t_addExtraData: 'Add Extra Data',
  t_script: 'Script',
  t_crmCall: 'CRM Call',
  t_crmAddCustomer: 'Add Customer',
  t_displayAllRecords: 'Display All Records',
  t_editCustomer: 'Edit Customer',
  t_crmlistenRecording: 'Listen to CRM Recording',
  t_showNoteDetails: 'Show Note Details',
  t_excelCustomer: 'Customer Excel',
  t_excelCustomerByPhone: 'Phone Excel',
  t_excelCustomerByProduct: 'Product Excel',
  t_showPhoneDetails: 'Show Phone Details',
  t_justShowOwnCustomers: 'Only Show Own Customers',
  t_dontSendCustomMessage: 'Cannot Change Message Text',
  t_keywordCategories: "Keyword Category",
  t_keyWord: "Keyword",
  t_minOccurrence: "Min Occurrence",
  t_maxOccurrence: "Max Occurrence",
  t_inFirstSeconds: "In First Seconds",
  t_inLastSeconds: "In Last Seconds",
  t_logicCondition: "Logic Condition",
  t_reverse: "Reverse",
  t_and: "And",
  t_or: "Or",
  t_customer: "Customer",
  t_oneOfBoth: "One of Both",
  t_bothOfThem: "Both",
  t_liveDashboards: 'LIVE DASHBOARDS',
  t_tools: 'TOOLS',
  t_admin: 'ADMIN',
  t_useDatesForEvaluateDate: 'Use Date Filter for Evaluation Date',
  t_menuLineGroups: 'Menu - Line Groups',
  t_menuDialPlans: 'Menu - Dial Plans',
  t_menuIVR: 'Menu - IVR',
  t_menuAnnouncements: 'Menu - Announcements',
  t_menuRoutes: 'Menu - Routes',
  t_menuTimeCondition: 'Menu - Time Condition',
  t_menuUsers: 'Menu - Users',
  t_menutExtensionRoutes: 'Menu - Routes',
  t_menuQueues: 'Menu - Queues',
  t_menuProjects: 'Menu - Projects',
  t_menutStatuses: 'Menu - Statuses',
  t_menutFinishCodes: 'Menu - Finish Codes',
  t_menuBlackList: 'Menu - Black List',
  t_menuShifts: 'Menu - Shifts',
  t_menuProfiles: 'Menu - Profiles',
  t_menuRecordingExport: 'Menu - Recording Export',
  t_menuSms: 'Menu - SMS',
  t_menuReports: 'Menu - Reports',
  t_menuGoalManagement: 'Menu - Goal Management',
  t_menuDialer: 'Menu - Auto Dialer',
  t_menuLiveDashboard: 'Menu - Live Monitoring',
  t_menuPhoneBook: 'Menu - Phone Book',
  t_menuCalendar: 'Menu - Calendar',
  t_menuCrm: 'Menu - CRM',
  t_menuDashboard: 'Menu - Dashboard',
  t_menuEvaluatedRecords: 'Menu - Evaluated Records',
  t_menuSystemManagement: 'Menu - System Management',
  t_menuOperationManagement: 'Menu - Operation Management',
  t_menuIntegrations: 'Menu - Integrations',
  t_menuSettings: 'Menu - Settings',
  t_menuWallboard: 'Menu - Wallboard',
  t_menuQueueGroup: 'Menu - Queue Group',
  t_menuvIVR: 'Menu - Variable IVR',
  t_menuScheduler: 'Menu - Scheduler',
  t_menuExternalChat: 'Menu - Text Channel',
  t_menuSurvey: 'Menu - Survey',
  t_menuEMail: 'Menu - E-Mail',
  t_menuAlerts: 'Menu - Alerts',
  t_menuQuality: 'Menu - Quality',
  t_otherAgentList: 'Other - Agent List',
  t_othertAutoCustomerCard: 'Other - Auto Open Card',
  t_otherDownloadCallRecordings: 'Other - Download Call Recordings',
  t_otherOutboundCallApproval: 'Other - Outbound Call Approval',
  t_otherCallHistoryDownloadRecording: 'Other - Call History Recording Download',
  t_otherCallHistoryListenRecording: 'Other - Call History Listen Recording',
  t_otherDialerReopenTotalAttempts: 'Other - Dialer Reopen Total Attempts',
  t_otherDialerReopenDailyAttempts: 'Other - Dialer Reopen Daily Attempts',
  t_otherDialerClearCallSchedules: 'Other - Dialer Clear Call Schedules',
  t_otherShowAllFinishCodes: 'Other - Show All Finish Codes',
  t_otherUpdateLastFinishCode: 'Other - Update Last Finish Code',
  t_otherShowIVRInputs: 'Other - Show IVR Inputs',
  t_otherHidePhoneNumbers: 'Other - Hide Phone Numbers',
  t_ChatSouffle: 'Chat - Souffle',
  t_chatChat: 'Chat - Messaging',
  t_chatAnnounce: 'Chat - Announcement',
  t_crmFilters: 'CRM - Filters',
  t_crmColumns: 'CRM - Columns',
  t_crmManagement: 'CRM - Management',
  t_crmAddExtraData: 'CRM - Add Extra Data',
  t_crmScript: 'CRM - Script',
  t_crmProducts: 'CRM - Products',
  t_crmPhones: 'CRM - Phones',
  t_crmNotes: 'CRM - Notes',
  t_crmSms: 'CRM - SMS',
  t_crmSmsSending: 'CRM - SMS Sending',
  t_crmCrmCall: 'CRM - Call',
  t_crmCrmAddCustomer: 'CRM - Add Customer',
  t_crmDisplayAllRecords: 'CRM - Display All Records',
  t_crmEditCustomer: 'CRM - Edit Customer',
  t_crmCrmlistenRecording: 'CRM - Listen to CRM Recording',
  t_crmShowNoteDetails: 'CRM - Show Note Details',
  t_crmExcelCustomer: 'CRM - Excel Based on Customer',
  t_crmExcelCustomerByPhone: 'CRM - Excel Based on Phone',
  t_crmShowPhoneDetails: 'CRM - Show Phone Details',
  t_rTransferType: "Transfer Type",
  t_rTransferTarget: "Transfer Target",
  t_acw: "ACW Time",
  t_rDialerConnectedToAgent: "Auto Dialer Connected",
  t_rDialerAbandon: "Auto Dialer Abandoned",
  t_rACWLength: "ACW Duration",
  t_callAnalize: "Call Analysis",
  t_callFinishCode: "Call",
  t_writtenChannelFinishCode: "Written Channel",
  t_productFinishCode: "Product",
  t_finishCodeType: "Finish Code Type",
  t_speechText: "Speech Text",
  t_reportGroup: "Grouping",
  t_pleaseSelect: "Please Select",
  t_awaitingInteractionsCount: "Awaiting",
  t_descawaitingInteractionsCount: "Total real-time awaiting calls in selected queues",
  t_answeredCount: "Answered",
  t_descansweredCount: "Total answered calls in selected queues",
  t_abandonedCount: "Abandoned",
  t_descabandonedCount: "Total abandoned calls in selected queues",
  t_activeInteractionsCount: "Active Call Count",
  t_descactiveInteractionsCount: "Total real-time active calls in selected queues",
  t_averageWaitTime: "Average Waiting Time",
  t_descaverageWaitTime: "Average wait time in queues for incoming calls in selected queues",
  t_callBackSuccess: "Successful Callback",
  t_desccallBackSuccess: "Number of callbacks made for abandoned calls",
  t_firstCallResolutionCount: "First Call Resolution",
  t_descfirstCallResolutionCount: "Calls resolved successfully on the first attempt with success category finish codes",
  t_serviceLevel: "Service Level",
  t_descserviceLevel: "Ratio of answered calls within the service levels set in queue settings to total incoming calls",
  t_sRLevel: "Success Rate",
  t_descsRLevel: "Ratio of calls completed with successful finish codes to total calls",
  t_averageConnectedTime: "Average Call Duration",
  t_descaverageConnectedTime: "Average duration of all connected calls",
  t_abandonRate: "Abandonment Rate",
  t_descabandonRate: "Ratio of unanswered calls in selected queues to total calls",
  t_transferQueue: "Transfer to Queue",
  t_rAvgQueueLength: "Avg. Queue Waiting Time",
  t_crmlistenRecordingJustOwn: "Listen to Only Own Calls",
  t_deleteQualityForm: "Delete Quality Form",
  t_currentPasswordDoesNotMatch: "Current Password is Incorrect",
  t_confirmPasswordDoesNotMatch: "Confirmed Password Does Not Match",
  t_rRinging: "Ringing",
  t_rEnterIVR: "Entered IVR",
  t_ranswer: "Answered by IVR",
  t_rStartIvr: "IVR Started",
  t_rTimeConditionFail: "Time Condition Failed",
  t_rTimeConditionSuccess: "Time Condition Met",
  t_rStartIvrMenu: "Menu",
  t_rMenuAttempt: "Attempt",
  t_rMenuAnnouncement: "Announcement",
  t_rDtmfResult: "DTMF Result",
  t_rTaskmenu: "Menu",
  t_rTasksurvey: "Survey Task",
  t_rStartPreSurveyTask: "Pre-Survey Start",
  t_rPreSurveyAttempt: "Attempt",
  t_rPreSurveyAnnouncement: "Survey Announcement",
  t_rPreSurveyResults: "Survey Results",
  t_rTaskcondition: "Condition",
  t_rConditionFail: "Condition Failed",
  t_rTaskvariable: "Variable",
  t_rStartVariableTask: " ",
  t_rTaskannounce: "Announcement",
  t_rVariableAttempt: "Attempt",
  t_rVariableAnnouncement: "Variable Announcement",
  t_rTaskhttp: "HTTP Request Made",
  t_rVariableFailed: "Variable Error",
  t_rFailedTaskvariable: "Failed Variable Task",
  t_rMenuFailed: "Menu Error",
  t_rFailedTaskmenu: "Failed Menu Task",
  t_rConditionError: "Condition Error",
  t_rFailedTaskcondition: "Failed Condition Task",
  t_rTimeConditionError: "Time Condition Error",
  t_rIvrFailed: "IVR Terminated",
  t_rFailedInboundCall: "Call Terminated",
  t_rFinalized: "Finalized",
  t_rJoinedQueue: "Joined Queue",
  t_rAssigned: "Assigned",
  t_rAccepted: "Accepted",
  t_rPickedUp: "Picked Up",
  t_rRemoteFailed: "Remote Number Error",
  t_rConditionSuccess: "Condition Met",
  t_rTaskannounce: "Announcement",
  t_rTaskqueue: "Queue",
  t_roriginate: "Originate",
  t_rcall_target: "Call Target",
  t_rnew_bridge: "New Channel",
  t_rbridge_created: "Channel Created",
  t_rcalls_bridged: "Channels Bridged",
  t_rcall_transferred: "Call Transferred",
  t_rUnassigned: "Unassigned",
  t_rAbandoned: "Abandoned",
  t_rRejected: "Rejected",
  t_rNoAnswer: "No Answer",
  t_rFailedTaskannounce: " ",
  t_rTransferAssigned: "Assigned for Transfer",
  t_rTransferAccepted: "Transfer Accepted",
  t_rnull: " ",
  t_rTaskivr: "IVR",
  t_rTasktransfer: "Transfer",
  t_rEndCall: "Call Ended",
  t_rFailedTaskhttp: "HTTP Task Error",
  t_rTotalLocal: "Total Local Terminations",
  t_rInboundLocal: "Inbound Local Terminations",
  t_rOutboundLocal: "Outbound Local Terminations",
  t_rAvgDialingTime: "Average Dialing Time",
  t_rTotalInCallTime: "Total In-Call Time",
  t_rTotalStatusTime: "Total Status Time",
  t_rTotalHoldCount: "Total Hold Count",
  t_rTotalHoldTime: "Total Hold Time",
  t_rAvgHandleTime: "Average Handle Time",
  t_rLogoutCount: "Logout Count",
  t_rProductivityTime: "Productivity Time",
  t_rBreakCategoryTime: "Used Break Time",
  t_rAvgAlertTime: "Average Ringing Time",
  t_addFile: "Add File",
  t_appointment: "Appointment",
  t_appointments: "Appointments",
  t_second: "Second",
  t_notAnsweringCallDesc2: "You have been in 'Not Answering' status for 1 minute, please switch to 'Available' to continue.",
  t_notAnsweringCallDesc: "You missed a call, please switch to 'Available' to continue.",
  t_notAnsweringCall: "Missed Call",
  t_timeFormat: "Time Format",
  t_callGroup: "Call Group",
  t_pickupGroup: "Pickup Group",
  t_fodlerType: "Foldering Format",
  t_conferenceCallAccepted: "Call Connected",
  t_conferenceCallHangup: "Call Ended",
  t_conferenceCallDeclined: "Call Failed",
  t_conferenceCalling: "Calling",
  t_agentListDetails: "Agent List Details",
  t_rAgentGroup: "Agent Group",
  t_new: "New",
  t_recordsActivityLog: "Call Listening Log",
  t_isAutoForm: "Auto Form",
  t_timestamp: 'Timestamp',
  t_verificationCode: "Verification Code",
  t_verificationCodeDesc: "Please enter the 6-digit verification code",
  t_verify: "Verify",
  t_phone: "Phone",
  t_downloadVoyceVoiceConverter: "Download Voyce Voice Converter",
  t_position: "Position",
  t_ivrBreakOut: "Queue Breakout",
  t_positionAndIvrBreakOut: "Position and Queue Breakout",
  t_ivrBreakOutTarget: "Queue Breakout Target",
  t_displayAllCalendar: "Display All Calendars",
  t_appointmentAssignmentDesc: "New appointments have been assigned to you. Please check your calendar.",
  t_appointmentAssignment: "Appointment Assignment",
  t_displayCalls: 'Call Monitoring',
  t_showUsedAreas: "Show Used Areas",
  t_ftp: "FTP",
  t_voiceExport: "Voice Export",
  t_putFtp: "Send to FTP",
  t_remotePath: "Remote File Path",
  t_passive: 'Passive',
  t_passives: 'Passives',
  t_state: 'Status',
  t_frequentlyAskedQuestions: 'Frequently Asked Questions',
  t_helpCenter: 'Help Center',
  t_contactUs: 'Contact Us',
  t_isSecure: 'Secure Connection',
  t_rejectUnauthorized: 'Reject Unauthorized',
  t_customMetaData: 'Custom Meta Data',
  t_metaDataEndpoint: 'Meta Data Endpoint',
  t_metaDataRemotePath: 'Meta Data Remote Path',
  t_putAsFolder: 'Send as Folder',
  t_isDefault: 'Default',
  t_rSLDuration: 'SL Duration',
  t_twoFactorAuthentication: "Two-Factor Authentication",
  t_sentToPhoneNumber: "sent to your phone number",
  t_sentToEmailAdress: "sent to your email address",
  t_verifyYourIdentity: "verify your identity by entering the verification code",
  t_remainingTime: "Remaining Time",
  t_sec: "sec",
  t_donthaveAccess: "Don't have access to this account?",
  t_verificationCode: "verification code",
  t_sendAsMail: "send via email.",
  t_sendAsSms: "send via SMS",
  t_verify: "Verify",
  t_closeProduct: "Close Product",
  t_setPassiveWhenNoProduct: 'Set records without a product to passive',
  t_sendHttpRequest: "Send HTTP Request",
  t_month2: "Month (No Zero)",
  t_day2: "Day (No Zero)",
  t_shifts: "Shifts",
  t_shift: "Shift",
  t_missingTime: 'Missing Time',
  t_isAnnualLeave: 'Annual Leave',
  t_paidLeave: 'Paid Leave',
  t_unpaidLeave: 'Unpaid Leave',
  t_isOwner: 'Self',
  t_owner: 'Owner',
  t_thirdParty: 'Third Party',
  t_breakTimeFraud: 'Break Time Fraud',
  t_dontControlLastTenDigit: "Don't Control Last 10 Digits",
  t_bank: 'Bank',
  t_dontChangeSurvey: "Don't Change Survey Status",
  t_finishCodeBreakdown: 'Finish Code Breakdown',
  t_displayPassiveRecords: 'Show Passive Records',
  t_newAssets: "New Asset",
  t_reached: "Reached",
  t_reachedDate: "Reached Date",
  t_dontGetNoQueueRecords: "Don't Fetch Records Without Queue",
  t_agentHaveStaticQueueButDontHaveProjectPermission: 'Agent has static queue but no project permission.',
  t_autoRecall: 'Auto Recall',
  t_autoRecallDay: 'Days Later',
  t_rDateName: "Day",
  t_rWeekOfMonth: "Week of the Month",
  t_rGivenBreakTime: "Break Granted",
  t_rOfflineTime: "Offline Time",
  t_rMonth: "Month",
  t_rBreakTimeDifference: "Break Time Difference",
  t_copyFromTheEditor: "Copy from Editor",
  t_pasteIntoTheEditor: "Paste into Editor",
  t_duplicate: "Duplicate",
  t_identifier: "TCKN",
  t_undeafen: 'Unmute',
  t_deafen: 'Mute',
  t_canTransfer: 'Transferable',
  t_wordDistance: 'Word Distance',
  t_segmentDistance: 'Segment Distance',
  t_maxWordGroupCount: 'Max Word Group',
  t_trendAnalyze: 'Trend Analysis',
  t_realTimeTranscript: 'Real-Time Transcript',
  t_snapshots: "Snapshots",
  t_snapshotDate: "Snapshot Date",
  t_firstCallDate: "First Call Date",
  t_lastCallDate: "Last Call Date",
  t_callCount: "Call Count",
  t_density: "Density",
  t_avarageDensity: "Average Density",
  t_displayDensityStatistics: "Display Density Statistics",
  t_hideFromAgent: "Hide From Agent",
  t_customerNo: "Customer No",
  t_customerName: "Name Surname",
  t_customerIdentifier: "TCKN",
  t_reached: "Reached",
  t_reachedDate: "Reached Date",
  t_productNo: "Product No",
  t_artificialIntelligence: "Artificial Intelligence",
  t_speakers: "Speakers",
  t_oneTime: "One-Time",
  t_scheduled: "Scheduled",
  t_automatic: "Automatic",
  t_periodTime: "Period Time",
  t_prompt: "Prompt",
  t_aiCallResult: "AI Call Result",
  t_exceptions: "Exceptions",
  t_activities: "Activities",
  t_outputFileName: "Output File Name",
  t_viewSingleCustomerCard: 'View Single Customer Card'

}
